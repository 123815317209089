import React, { useState, useEffect } from "react";
import LeftSidePanel from "../Components/LeftSidePanel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles/CreateMarket.css";
import Select from "react-select";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { useAuth } from "../hooks/useAuth";

const options = [
  { value: "science", label: "Science" },
  { value: "politics", label: "Politics" },
  { value: "economics", label: "Economics" },
];

const CreateMarket = () => {
  const { user, fetchUserdata } = useAuth();

  // const [startDate, setStartDate] = useState(new Date());
  // const [tomorrow, setTomorrow] = useState(moment().add(1, 'days'));
  // const { localTheme, setLocalTheme } = useContext(ModeContext);
  // const [themeClass, setThemeClass] = useState('');
  // const [themeInput, setThemeInput] = useState('');
  const [selectedOption, setSelectedOption] = useState("");
  const [characterLimit, setCharacterLimit] = useState(500);
  const [selectedMarketClosesIn, setSelectedMarketClosesIn] = useState("day");
  const [answerType, setAnswerType] = useState(["YES/NO"]);
  const [answerTypeActive, setAnswerTypeActive] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [active, setActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const { themeClass, themeInput } = useContext(ThemeContext);
  const [question, setQuestion] = useState("");
  const [value, setValue] = useState("");
  const [receivedOptions, setReceivedOptions] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const localToken = localStorage.getItem("Oddplus_Token");
  const [selectedImage, setSelectedImage] = useState(null);
  const [marketImage, setMarketImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorImage, setImageError] = useState("");
  const [errorquestion, setQuestionError] = useState("");
  const [errorgroup, setGroupError] = useState(""); //
  const [showModal, setShowModal] = useState(false);

  // State Variable for Create Loading Button
  const [createMarketLoading, setCreateMarketLoading] = useState(false);

  // States for multi choice
  const [multiOption1, setMultiOption1] = useState("");
  const [multiOption2, setMultiOption2] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const walletAddress = localStorage.getItem("walletAddress");
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  // Create market API^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreateMarketLoading(true);

    const formData = new FormData();
    if (!value.trim() && !marketImage && !question && !selectedOption) {
      setError("Description is required");
      setImageError("Image is required");
      setQuestionError("Question is required");
      setGroupError("Group is required");
      setCreateMarketLoading(false);
    } else if (!value.trim()) {
      setError("Description is required");
      setImageError("");
      setCreateMarketLoading(false);
    } else if (!marketImage) {
      setImageError("Image is required");
      setImageError("");
      setCreateMarketLoading(false);
    } else if (!question) {
      setQuestionError("Question is required");
      setQuestionError("");
      setCreateMarketLoading(false);
    } else if (!selectedOption) {
      setGroupError("Group is required");
      setGroupError("");
      setCreateMarketLoading(false);
    } else {
      setError("");
      setImageError("");
      setQuestionError("");
      let combinedChoices =
        answerTypeActive === "YES/NO"
          ? null
          : [multiOption1, multiOption2, ...inputValues];
      formData.append("marketImage", marketImage);
      formData.append("question", question);
      formData.append("description", value);
      formData.append("answerType", answerTypeActive);
      formData.append("choices", combinedChoices);
      formData.append("addToGroup", selectedOption);
      formData.append("marketClosesIn", selectedMarketClosesIn);
      formData.append("date", selectedDate.toISOString());
      formData.append("senderAddress", walletAddress);
      formData.append("bet_result", "pending");
      formData.append("bet_status", "open");
      try {
        const response = await axiosInstance.post(
          ApiEndpoints.market.CREATE_MARKET,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setIsLoading(false);
        setCreateMarketLoading(false);
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();

        setTimeout(() => {
          navigate("/dashboard-market");
        }, 1500);
      } catch (error) {
        setCreateMarketLoading(false);
        const notify = () => {
          toast.error(error.response.data.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      }
    }
    // const combinedChoices = [multiOption1, multiOption2, ...inputValues];

    // const data = {
    //     question: question,
    //     description: value,
    //     answerType: answerTypeActive,
    //     choices: combinedChoices,
    //     addToGroup: selectedOption,
    //     marketClosesIn: selectedMarketClosesIn,
    //     date: selectedDate.toISOString(),
    //     // marketImage: marketImage,
    // };
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // Fetch group data^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  useEffect(() => {
    async function makeRequest() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.group.GROUP_DETAIL
        );

        // Assuming the API response contains an array of objects with a 'group_name' property
        const groupNames = response.data.map((item) => ({
          value: item.group_name,
          label: item.group_name,
        }));

        setOptions(groupNames);
      } catch (error) {
        return error;
      }
    }

    makeRequest();
    fetchUserdata();
  }, []);
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const handleReplicate = (e) => {
    e.preventDefault();
    setCounter(counter + 1);
  };

  const handleRemove = (indexToRemove) => {
    // e.preventDefault();
    const newInputValues = inputValues.filter(
      (value, index) => index !== indexToRemove
    );
    setInputValues(newInputValues);
    setCounter(counter - 1);
  };

  // const handleClick = () => {
  //     setActive(!active);
  // };

  const setTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setSelectedDate(tomorrow);
  };
  const setWeek = () => {
    const week = new Date();
    week.setDate(week.getDate() + 7);
    setSelectedDate(week);
  };
  const setMonth = () => {
    const month = new Date();
    month.setDate(month.getDate() + 30);
    setSelectedDate(month);
  };
  const setYear = () => {
    const currentYear = new Date().getFullYear();

    const lastDay = new Date(currentYear, 11, 31);
    setSelectedDate(lastDay);
  };

  const modules = {
    toolbar: [
      [{ size: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }],
      // ["image"],
      ["code"],
      ["link"],
    ],
  };

  const formats = [
    "list",
    // "image",
    "align",
    "size",
    "code",
    "link",
  ];

  const handleMarketClosesInChange = (e, newValue, func) => {
    e?.preventDefault();
    setSelectedMarketClosesIn(newValue);
    func();
  };
  const handleAnswerType = (e, newValue) => {
    e?.preventDefault();

    // Emptying setInput Values state when click on this function and set dynamically generated text field zero
    setInputValues([]);
    setCounter(0);

    setAnswerTypeActive(newValue);
  };

  const handleKeyDown = (event) => {
    // Check if the character limit has been reached
    if (value.length >= characterLimit && event.key !== "Backspace") {
      event.preventDefault(); // Prevent the character from being inserted
    }
  };
  const getDescriptionData = (content) => {
    setValue(content);
  };
  // -------------------Custom style for Select-------------------------
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectColor, setSelectColor] = useState("");

  useEffect(() => {
    if (themeClass === "dark") {
      setSelectColor("black");
    } else {
      setSelectColor("white");
    }
  }, [themeClass]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectColor,
      width: "100%",
      // Add other styles as needed
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: selectColor,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: themeClass === "dark" ? "black" : "white",
      color: themeClass === "dark" ? "white" : "black",
      // backgroundColor: state.isSelected ? '#069514' : state.isFocused ? 'lightblue' : 'white',
      // color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'black',
      // Add other styles as needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "white" : "black", // Change the color of the selected item here
      // Add other styles as needed
    }),
  };

  // Rotate arrow function
  function rotateArrow() {
    var element = document.getElementById("rotate");
    element.classList.toggle("toggle-down");
  }

  // Getting the dynamically generated input field data
  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  // For default select the Answer type tab
  useEffect(() => {
    // Function to simulate a click event on the "Notifications" tab link
    const openNotificationsTab = () => {
      const defaultOpenLinks = document.getElementsByClassName("default-open");
      if (defaultOpenLinks.length > 0) {
        for (let i = 0; i < defaultOpenLinks.length; i++) {
          defaultOpenLinks[i].click();
        }
      }
    };

    openNotificationsTab();

    // const openNotificationsTab2 = () => {
    //     const defaultOpenLink = document.getElementById("market-close");
    //     if (defaultOpenLink) {
    //         defaultOpenLink.click();
    //     }
    // };

    // // Call the function to open the "Notifications" tab when the component mounts
    // openNotificationsTab2();
  }, []);

  // Image upload and preview
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setMarketImage(event.target.files[0]);
    }
  };

  const FirstMarketApi = () => {
    axiosInstance.patch(ApiEndpoints.auth.First_Market);
    setTimeout(() => {
      fetchUserdata();
    }, 500);
  };

  useEffect(() => {
    if (user?.firstMarket === true) {
      setShowModal(true);
    }
  }, [user?.firstMarket]);
  const handleClose = () => {
    FirstMarketApi();
    setShowModal(false);
  };

  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="container">
              <nav className="navbar navbar-expand-sm ">
                <div
                  className={`${themeClass} create-market-text navbar-brand d-flex align-items-center justify-content-start`}
                >
                  Create a Market
                </div>
              </nav>
              <p className="pb-5">
                Set up your own play-money prediction market on any question.{" "}
                <Link to="/how-to-create-market">Learn more...</Link>
              </p>

              <section className="my-4">
                <div>
                  {/* ... (other JSX content) */}
                  {isLoading ? (
                    <div className="loader-container">
                      <Circles
                        height={80}
                        width={80}
                        radius={9}
                        color="green"
                        ariaLabel="loading"
                        wrapperClassName="loader-wrapper"
                      />
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="mb-2" htmlFor="exampleInputEmail1">
                          Question<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`${themeInput} form-control `}
                          placeholder="e.g. Will the Democrats win the 2024 US presidential election?"
                          value={question}
                          maxLength={150}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                        {errorquestion && !question.trim() && (
                          <p style={{ color: "red" }}>{errorquestion}</p>
                        )}
                        <label className="mt-2" htmlFor="exampleInputEmail1">
                          Description<span style={{ color: "red" }}>*</span>
                        </label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={value}
                          onChange={getDescriptionData}
                          onKeyDown={handleKeyDown}
                          placeholder="Optional. Provide background info and market resolution criteria here."
                          className="rte-placeholder"
                        />
                        {error && !value.trim() && (
                          <p style={{ color: "red" }}>{error}</p>
                        )}
                        {/* Image upload */}
                        <div className="image-selector">
                          <input
                            type="file"
                            accept="image/.jpg .jpeg .png"
                            onChange={(e) => handleImageChange(e)}
                          />
                          {selectedImage && (
                            <div className="selected-image">
                              <img src={selectedImage} alt="Selected" />
                            </div>
                          )}
                        </div>
                        {errorImage && !selectedImage && (
                          <p style={{ color: "red" }}>{errorImage}</p>
                        )}
                        {/* {errorImage && <p style={{ color: 'red' }}>{errorImage}</p>} */}
                        {/* <div className='d-flex align-items-center justify-content-end mt-4'>
                                                    <img src='./images/Vector(7).svg'
                                                        alt=''
                                                        className='mr-2'
                                                        style={{ width: '15px', cursor: "pointer" }} />
                                                    <button type="button" className="accordion-btn btn shadow-none" data-toggle="collapse" data-target="#accordion" onClick={rotateArrow}><img id="rotate" className='mr-2' src='./images/Vector(8).svg' alt='' /> More Options</button>
                                                </div> */}

                        <div className="my-4 ">
                          <div className="">
                            {/* <div id="accordion" className="collapse "> */}
                            <div className="mb-4">
                              <label
                                className="mb-2"
                                htmlFor="exampleInputEmail1"
                              >
                                Answer Type
                              </label>
                              <div className="container">
                                <ul className="nav nav-pills d-flex gap-3">
                                  <li
                                    onClick={(e) =>
                                      handleAnswerType(e, "YES/NO")
                                    }
                                    className={
                                      answerTypeActive === "YES/NO"
                                        ? "custom-tab"
                                        : "InActive"
                                    }
                                  >
                                    <a
                                      className="m-2 default-open"
                                      data-toggle="pill"
                                      href="#menu1"
                                      id=""
                                    >
                                      YES/NO
                                    </a>
                                  </li>
                                  {/* <li onClick={(e) => handleAnswerType(e, "Multi Choice")}
                                                                        className={answerTypeActive === "Multi Choice" ? 'custom-tab' : 'InActive'}>
                                                                        <a className='m-2' data-toggle="pill" href="#menu3">Multi Choice</a>
                                                                    </li> */}
                                </ul>
                                <hr />
                                <div className="tab-content">
                                  <div id="menu1" className="tab-pane fade">
                                    {/* <h3>Menu 1</h3>
                                                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
                                  </div>
                                  <div id="menu3" className="tab-pane fade">
                                    <div className="App">
                                      <input
                                        type="text"
                                        className={`${themeInput} form-control col-md-6 mb-3`}
                                        placeholder="Type your answer..."
                                        onChange={(e) => {
                                          setMultiOption1(e.target.value);
                                        }}
                                      />

                                      <input
                                        type="text"
                                        className={`${themeInput} form-control col-md-6 mb-3`}
                                        placeholder="Type your answer..."
                                        onChange={(e) => {
                                          setMultiOption2(e.target.value);
                                        }}
                                      />

                                      {Array.from(Array(counter)).map(
                                        (c, index) => {
                                          return (
                                            <>
                                              <div className="d-flex align-items-center justify-content-start gap-2">
                                                <input
                                                  key={c}
                                                  type="text"
                                                  className={`${themeInput} form-control col-md-6 mb-3`}
                                                  placeholder="Type your answer..."
                                                  onChange={(e) => {
                                                    handleInputChange(
                                                      index,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <button
                                                  className="btn btn-danger mb-2"
                                                  onClick={() => {
                                                    handleRemove(index);
                                                  }}
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                      <div className="col-md-6 mb-1 d-flex align-items-center justify-content-end ">
                                        <button
                                          className="btn btn-success"
                                          style={{ height: "35px" }}
                                          onClick={handleReplicate}
                                        >
                                          Add answer
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-start ">
                              <div className="flex-column">
                                <div className="d-flex align-items-center">
                                  <p className="mb-0 me-2">
                                    Add to Group
                                    <span style={{ color: "red" }}>*</span>
                                  </p>
                                  <OverlayTrigger
                                    delay={{ hide: 250, show: 200 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        Question will be displayed alongside the
                                        other questions in the group.
                                      </Tooltip>
                                    )}
                                    placement="top"
                                  >
                                    <img
                                      src="./images/Vector(7).svg"
                                      alt=""
                                      style={{
                                        width: "15px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>

                                <div className="mt-3" style={{}}>
                                  <Select
                                    defaultValue={selectedOption}
                                    menuIsOpen={isMenuOpen}
                                    onMenuOpen={handleMenuOpen}
                                    onMenuClose={handleMenuClose}
                                    onChange={(selectedOption) =>
                                      setSelectedOption(selectedOption.value)
                                    }
                                    options={options}
                                    placeholder="eg. Science, Politics"
                                    styles={customStyles}
                                  />
                                </div>
                                {errorgroup && !selectedOption && (
                                  <p style={{ color: "red" }}>{errorgroup}</p>
                                )}
                              </div>
                            </div>

                            <div
                              className="my-3"
                              style={{ display: "inline-block" }}
                            >
                              <div className="d-flex align-items-center my-3">
                                <p className="mb-0 me-2">Market Closes In</p>
                                <OverlayTrigger
                                  delay={{ hide: 250, show: 200 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      Trading will be halted after this time
                                      (local timezone).
                                    </Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <img
                                    src="./images/Vector(7).svg"
                                    alt=""
                                    style={{ width: "15px", cursor: "pointer" }}
                                  />
                                </OverlayTrigger>
                              </div>

                              <div
                                className=""
                                style={{
                                  border: "1px solid #ced4da",
                                  borderRadius: "12px",
                                }}
                              >
                                {/* <ul className="nav nav-pills p-2">
                                                                <li onClick={(e) => [setTomorrow(), handleClick()]}
                                                                    className={active ? "Active" : "InActive"}><a className='m-2' href="#menu1">A Day</a></li>
                                                                <li onClick={(e) => [setWeek(), handleClick()]}
                                                                    className={active ? "Active" : "InActive"} ><a className='m-2' href="#menu2">A Week</a></li>
                                                                <li onClick={(e) => [setMonth(), handleClick()]}
                                                                    className={active ? "Active" : "InActive"}><a className='m-2' href="#menu3">30 Days</a></li>
                                                                <li onClick={(e) => [setYear(), handleClick()]}
                                                                    className={active ? "Active" : "InActive"}><a className='m-2' href="#menu3">This Year</a></li>
                                                            </ul> */}
                                <ul className="nav nav-pills p-2 gap-3">
                                  <li
                                    onClick={(e) =>
                                      handleMarketClosesInChange(
                                        e,
                                        "A Day",
                                        setTomorrow
                                      )
                                    }
                                    className={
                                      selectedMarketClosesIn === "A Day"
                                        ? "custom-tab"
                                        : "InActive"
                                    }
                                  >
                                    <a className="m-2" href="#menu1" id="">
                                      A Day
                                    </a>
                                  </li>
                                  <li
                                    onClick={(e) =>
                                      handleMarketClosesInChange(
                                        e,
                                        "A Week",
                                        setWeek
                                      )
                                    }
                                    className={
                                      selectedMarketClosesIn === "A Week"
                                        ? "custom-tab"
                                        : "InActive"
                                    }
                                  >
                                    <a
                                      className="m-2 default-open"
                                      href="#menu2"
                                    >
                                      A Week
                                    </a>
                                  </li>
                                  <li
                                    onClick={(e) =>
                                      handleMarketClosesInChange(
                                        e,
                                        "30 Days",
                                        setMonth
                                      )
                                    }
                                    className={
                                      selectedMarketClosesIn === "30 Days"
                                        ? "custom-tab"
                                        : "InActive"
                                    }
                                  >
                                    <a className="m-2" href="#menu3">
                                      30 Days
                                    </a>
                                  </li>
                                  <li
                                    onClick={(e) =>
                                      handleMarketClosesInChange(
                                        e,
                                        "This Year",
                                        setYear
                                      )
                                    }
                                    className={
                                      selectedMarketClosesIn === "This Year"
                                        ? "custom-tab"
                                        : "InActive"
                                    }
                                  >
                                    <a className="m-2" href="#menu3">
                                      This Year
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="my-3 pb-2">
                                <DatePicker
                                  // selected={startDate}
                                  // onChange={(date) => setStartDate(date)}
                                  showTimeSelect
                                  dateFormat="Pp"
                                  showIcon
                                  selected={selectedDate}
                                  onChange={(date) => setSelectedDate(date)}
                                  minDate={tomorrow}
                                  className={
                                    themeClass === "dark"
                                      ? "bg-dark text-white custom-input"
                                      : "custom-input"
                                  }
                                />
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-1">
                          <p className="mb-0 text-color">Cost</p>
                          <OverlayTrigger
                            delay={{ hide: 250, show: 200 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                Cost to create your question. This amount is
                                used to subsidize predictions.
                              </Tooltip>
                            )}
                            placement="top"
                          >
                            <img
                              src="./images/Vector(7).svg"
                              alt=""
                              style={{ width: "15px", cursor: "pointer" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div className="d-flex justify-content-start align-items-center gap-2 mt-3">
                          <div className="text-color">
                            {" "}
                            <img
                              src="./images/tether-usdt-icon.svg"
                              style={{ height: 25 }}
                              alt=""
                            />{" "}
                            1 or <span style={{ color: "#069514" }}>FREE</span>{" "}
                            if you get 10+ participants{" "}
                            <img
                              src="./images/Vector(7).svg"
                              alt=""
                              style={{ width: "15px" }}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="create-market"
                            disabled={createMarketLoading}
                          >
                            {createMarketLoading ? (
                              <div class="spinner-border" role="status" />
                            ) : (
                              "Create Market"
                            )}
                          </button>
                        </div>
                        <section style={{ paddingBlock: "30px" }}></section>
                      </div>
                      <ToastContainer />
                    </form>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          className="modal fade bd-example-modal-lg show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content" style={{ width: "auto" }}>
              <div className="modal-header">
                <h5>How to Create Market</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "20px", textAlign: "center" }}>
                  <p>Follow the steps in this video to create market easily.</p>
                </div>
                <div style={{ display: "flex", height: "60vh" }}>
                  <iframe
                    src="../videos/How_to_create_a_market.mp4"
                    style={{ flex: 1, width: "100%", height: "100%" }}
                    // frameBorder="0"
                    allowFullScreen
                    title="How to Connect a Wallet"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateMarket;
