import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import './styles/Newsletter.css'
import { axiosInstance } from '../network/config/adapter'
import { ApiEndpoints } from '../network/config/endpoints'
import { ToastContainer, toast } from 'react-toastify'
import { Log } from 'ethers'
import { ThemeContext } from '../Context/ThemeContext'
const Newsletter = () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const { themeClass } = useContext(ThemeContext);
  const handleChange = (e) => {
    setEmail(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: email
    }
    setLoading(true);
    axiosInstance
      .post(ApiEndpoints.auth.Email, payload)
      .then((response) => {


        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();


      })
      .catch((error) => {
        console.log("error", error)
        const notify = () => {
          toast.error(error.response.data.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setLoading(false);
        setEmail("");
      })
  }
  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mt-5 mb-5">
                    <nav className="navbar navbar-expand-md mt-3 mb-5 flex justify-content-start">
                      <Link to='/dashboard-sitemap'>
                        <button className='mr-4' style={{ border: 'none', width: 35, height: 35, borderRadius: 30 }}>
                          <img src='./images/icon_back.svg' alt="back" />
                        </button>
                      </Link>
                      <h3>Newsletter</h3>
                    </nav>
                    <form onSubmit={handleSubmit} loading={loading}>
                      <div className="newsletter-container flex flex-column py-5 px-3 ">
                        <img className="newsletter-img" src="./images/image_newsletter.svg" alt="newsletter" style={{ height: "232px" }} />
                        <div className="newsletter-label my-5">The best of what’s new at markets</div>
                        <div className="subscribe-container flex justify-content-center">
                          <input
                            className="subscribe-input"
                            type="email"
                            id="email"
                            name="email"
                            aria-label="Email"
                            placeholder="Type your email..."
                            value={email}
                            required
                            onChange={handleChange}
                          />
                          <button
                            className="subscribe-btn"
                            type="submit"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  )
}

export default Newsletter

