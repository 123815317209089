// Layout.js
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";
import Sidebar from "./LeftSidePanleVersion2";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh", backgroundColor: "#EEEFF2" }}>
      <AppBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="d-flex" style={{ marginTop: "65px", flexGrow: 1 }}>
        {/* Sidebar */}
        <div 
          style={{ 
            width: isSidebarOpen ? "271px" : "0px", 
            transition: "width 0.3s ease", 
            overflow: "hidden",
            position: "relative",
            backgroundColor: "#FFF"
          }}
        >
          <Sidebar />
        </div>

        {/* Main Content */}
        <div 
          className="flex-grow-1 p-3"
          style={{ 
            minHeight: "calc(100vh - 85px)", 
            transition: "margin-left 0.3s ease",
            backgroundColor: "#EEEFF2",
            width: isSidebarOpen ? "calc(100% - 271px)" : "100%"
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
