import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import './styles/Notifications.css'
import { axiosInstance } from '../network/config/adapter'
import { ApiEndpoints } from '../network/config/endpoints'
import { ToastContainer, toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns'


const Notifications = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const [active, setActive] = useState("");

    const [replayToCommentsWeb, setReplayToCommentsWeb] = useState(false);
    const [replayToCommentsEmail, setReplayToCommentsEmail] = useState(false);
    const [replayToAnswersWeb, setReplayToAnswersWeb] = useState(false);
    const [replayToAnswersEmail, setReplayToAnswersEmail] = useState(false);
    const [questionTradedWeb, setQuestionTradedWeb] = useState(false);
    const [questionTradedEmail, setQuestionTradedEmail] = useState(false);

    const [allCreatorUpdateWeb, setAllCreatorUpdateWeb] = useState(false);
    const [creatorUpdateQuestionWeb, setCreatorUpdateQuestionWeb] = useState(false);


    const [allAnswersWeb, setAllAnswersWeb] = useState(false);
    const [allQuestionsTradedWeb, setAllQuestionsTradedWeb] = useState(false);
    const [allQuestionsTradedEmail, setAllQuestionsTradedEmail] = useState(false);

    const [commentOnQuestionWeb, setCommentOnQuestionWeb] = useState(false);
    const [commentOnQuestionEmail, setCommentOnQuestionEmail] = useState(false);

    const [answerOnQuestionWeb, setAnswerOnQuestionWeb] = useState(false);
    const [answerOnQuestionEmail, setAnswerOnQuestionEmail] = useState(false);

    const [userFollowWeb, setUserFollowWeb] = useState(false);
    const [newGroupAddWeb, setNewGroupAddWeb] = useState(false);

    const [streakBonusWeb, setStreakBonusWeb] = useState(false);
    const [newUserWeb, setNewUserWeb] = useState(false);
    const [languageChangeWeb, setLanguageChangeWeb] = useState(false);

    const [userTaggedWeb, setUserTaggedWeb] = useState(false);
    const [userTaggedEmail, setUserTaggedEmail] = useState(false);
    const [userLikedWeb, setUserLikedWeb] = useState(false);

    const [allNotifications, setAllNotifications] = useState([]);



    let tempArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 15, 20, 25];

    const handleSetActive = (e, newValue) => {
        e?.preventDefault();
        setActive(newValue);
    }



    useEffect(() => {
        // Function to simulate a click event on the "Notifications" tab link
        const openNotificationsTab = () => {
            const defaultOpenLink = document.getElementById("default-open");
            if (defaultOpenLink) {
                defaultOpenLink.click();
            }
        };

        // Call the function to open the "Notifications" tab when the component mounts
        openNotificationsTab();
        getNotificationSettings();
        getAllNotification();
    }, []);




    async function getNotificationSettings() {
        try {
            const response = await axiosInstance.get(ApiEndpoints.notification.notificationStatus);
            let settings = response.data.userNotificationSettings[0];

            setReplayToCommentsWeb(settings?.replies_to_your_comments_web);
            setReplayToCommentsEmail(settings?.replies_to_your_comments_email);
            setReplayToAnswersWeb(settings?.replies_to_your_answers_web);
            setReplayToAnswersEmail(settings?.replies_to_your_answers_email);
            setQuestionTradedWeb(settings?.traded_questions_web);
            setQuestionTradedEmail(settings?.traded_questions_email);

            setAllCreatorUpdateWeb(settings?.betCreationNotification);
            setCreatorUpdateQuestionWeb(settings?.bet_creator_updates_Notification)

            setAllAnswersWeb(settings?.all_new_answer);
            setAllQuestionsTradedWeb(settings?.only_traded_answer_web);
            setAllQuestionsTradedEmail(settings?.traded_questions_email);


            setCommentOnQuestionWeb(settings?.comment_question_email);
            setCommentOnQuestionEmail(settings?.comment_question_web);
            setAnswerOnQuestionWeb(settings?.answer_question_web);
            setAnswerOnQuestionEmail(settings?.answer_question_email);

            setUserFollowWeb(settings?.user_followed);

            setNewGroupAddWeb(settings?.groupCreationNotification);
            setNewUserWeb(settings?.bet_bonuses)
        } catch (error) {
            return error;
        }
    }


    const handleSaveSettings = async () => {
        const newSettings = {
            replies_to_your_comments_web: replayToCommentsWeb,
            replies_to_your_comments_email: replayToCommentsEmail,
            replies_to_your_answers_web: replayToAnswersWeb,
            replies_to_your_answers_email: replayToAnswersEmail,
            traded_questions_web: questionTradedWeb,
            traded_questions_email: questionTradedEmail,
            betCreationNotification: allCreatorUpdateWeb,
            bet_creator_updates_Notification: creatorUpdateQuestionWeb,
            all_new_answer: allAnswersWeb,
            only_traded_answer_web: allQuestionsTradedWeb,
            only_traded_answer_email: allQuestionsTradedEmail,
            comment_question_web: commentOnQuestionWeb,
            comment_question_email: commentOnQuestionEmail,
            answer_question_web: answerOnQuestionWeb,
            answer_question_email: answerOnQuestionEmail,
            user_followed: userFollowWeb,
            groupCreationNotification: newGroupAddWeb,
            bet_bonuses: newUserWeb
        };

        try {
            const response = await axiosInstance.put(ApiEndpoints.notification.updateSetting, newSettings);
            if (response && response.data && response.data.message) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            // Optionally return response.data or whatever you need from the successful request
            // return response.data;
        } catch (error) {
            // Handle error case
            toast.error("An error occurred while saving settings", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error("Error saving settings:", error);
            // Optionally rethrow the error if needed
            // throw error;
        }
    }


    async function getAllNotification() {
        try {
            const response = await axiosInstance.get(ApiEndpoints.notification.getAllNotifications);
            setAllNotifications(response?.data?.userNotificationDetail);
        }
        catch (error) {
            return error;
        }
    }

    return (
        <>
            <div className={` ${themeClass} `}>
                <div className="row d-flex">
                    <div className="col-12">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="mt-5">
                                            <h3>Notifications</h3>
                                            <div className='container mb-4 col-md-12 d-flex justify-content-start align-items-center gap-3' style={{ padding: '0px' }}>
                                                <div className='col-md-12' style={{ padding: '0px' }}> */}
                                                    {/* <div className="search-bar form-outline d-flex align-items-center" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }}>
                                    <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" />
                                    <img className="px-2" src='./images/icon_search.svg' alt="search" />
                                </div> */}
                                                {/* </div>
                                            </div>
                                        </div> */}
                                        <div>
                                            <div className="">
                                                <ul className="toggle-menu-items nav nav-pills d-flex justify-content-start gap-2">
                                                    <li onClick={(e) => handleSetActive(e, "notifications")}
                                                        className={active === "notifications" ? 'custom-tab' : 'InActive'}>
                                                        <a className='m-2' data-toggle="pill" href="#notifications" id="default-open">Notifications</a>
                                                    </li>
                                                    {/* <li onClick={(e) => handleSetActive(e, "balance")}
                                    className={active === "balance" ? 'custom-tab' : 'InActive'}>
                                    <a className='m-2' data-toggle="pill" href="#balance">Balance Changes</a>
                                </li> */}
                                                    <li onClick={(e) => handleSetActive(e, "settings")}
                                                        className={active === "settings" ? 'custom-tab' : 'InActive'}>
                                                        <a className='m-2' data-toggle="pill" href="#settings">Settings</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content" style={{ paddingBottom: "140px" }}>
                                                <div id="notifications" className="tab-pane fade gap-2 pb-2">
                                                    {allNotifications.map((notification, i) => (
                                                        <div className={`${themeClass === "dark"
                                                            ? "bg-dark text-white notification-card d-flex flex-column  py-3 px-3 mt-2"
                                                            : "notification-card d-flex flex-column py-3 px-3 mt-2 "
                                                            }`} key={i}>
                                                            <div>
                                                                <div className="d-flex align-items-center  gap-3" style={{ color: "#828282" }}>
                                                                    <img src="./images/Group(8).svg" alt="" />
                                                                    {/* <h5 style={{ color: "#069514" }} className="m-0">₮{i + 10}</h5> */}
                                                                    <div className={`${themeClass === "dark"
                                                                        ? "text-white"
                                                                        : "text-dark"}`} style={{ wordBreak: "break-word" }}>
                                                                        {notification?.message}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ color: "#828282", display: "flex", justifyContent: "flex-end" }}>
                                                                {formatDistanceToNow(new Date(notification?.createdAt), { addSuffix: true, includeSeconds: true }).replace('about ', '')}
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>

                                                <div id="balance" className="tab-pane fade gap-2">
                                                    <div className="pl-2 mt-3" >
                                                        You don't have any notifications, yet. Try changing your settings to see more.
                                                    </div>
                                                </div>

                                                <div id="settings" className="tab-pane fade gap-2 mb-5">
                                                    <div className="pl-2 mt-3">
                                                        <div className="container mt-1">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/chat.svg" alt="chat"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion1">
                                                                        New Comments
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion1" className="collapse pl-5">
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Only replies to your comments</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle1"
                                                                                checked={replayToCommentsWeb}
                                                                                onChange={(e) => {
                                                                                    setReplayToCommentsWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle1">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle2"
                                                                                checked={replayToCommentsEmail}
                                                                                onChange={(e) => {
                                                                                    setReplayToCommentsEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle2">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Only replies to your answers</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle3"
                                                                                checked={replayToAnswersWeb}
                                                                                onChange={(e) => {
                                                                                    setReplayToAnswersWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle3">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle4"
                                                                                checked={replayToAnswersEmail}
                                                                                onChange={(e) => {
                                                                                    setReplayToAnswersEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle4">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Only on questions you've traded</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle5"
                                                                                checked={questionTradedWeb}
                                                                                onChange={(e) => {
                                                                                    setQuestionTradedWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle5">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle6"
                                                                                checked={questionTradedEmail}
                                                                                onChange={(e) => {
                                                                                    setQuestionTradedEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle6">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/update.svg" alt="update"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion2">
                                                                        Updates & Resolutions
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion2" className="collapse pl-5">
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">All creator updates</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle7"
                                                                                checked={allCreatorUpdateWeb}
                                                                                onChange={(e) => {
                                                                                    setAllCreatorUpdateWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle7">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Only creator updates on questions that you've traded</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle9"
                                                                                checked={creatorUpdateQuestionWeb}
                                                                                onChange={(e) => {
                                                                                    setCreatorUpdateQuestionWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle9">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="">
                                                <div className="mt-3 mb-2">All question resolutions</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle11" />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle11">Web</label>
                                                    </div>
                                                </div>
                                            </div> */}

                                                                {/* <div className="">
                                                <div className="mt-3 mb-2">Only question resolutions that you've traded</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle12" />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle12">Web</label>
                                                    </div>
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle13" />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle13">Email</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                            </div>
                                                        </div>

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/bulb.svg" alt="new answers" />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion3">
                                                                        New Answers
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion3" className="collapse pl-5">
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">All new answers</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle14"
                                                                                checked={allAnswersWeb}
                                                                                onChange={(e) => {
                                                                                    setAllAnswersWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle14">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Only on questions you've traded</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle15"
                                                                                checked={allQuestionsTradedWeb}
                                                                                onChange={(e) => {
                                                                                    setAllQuestionsTradedWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle15">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle18"
                                                                                checked={allQuestionsTradedEmail}
                                                                                onChange={(e) => {
                                                                                    setAllQuestionsTradedEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle18">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/user.svg" alt="new answers"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion4">
                                                                        Questions You Created
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion4" className="collapse pl-5">
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Comments on your questions</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle19"
                                                                                checked={commentOnQuestionWeb}
                                                                                onChange={(e) => {
                                                                                    setCommentOnQuestionWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle19">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle20"
                                                                                checked={commentOnQuestionEmail}
                                                                                onChange={(e) => {
                                                                                    setCommentOnQuestionEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle20">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Answers on your questions</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle21"
                                                                                checked={answerOnQuestionWeb}
                                                                                onChange={(e) => {
                                                                                    setAnswerOnQuestionWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle21">Web</label>
                                                                        </div>
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle22"
                                                                                checked={answerOnQuestionEmail}
                                                                                onChange={(e) => {
                                                                                    setAnswerOnQuestionEmail(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle22">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ---------------------Balance Changes--------------------------- */}
                                                        <div className="balanceChangesLabel">Balance Changes</div>

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/dollar.svg" alt="new answers"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion5">
                                                                        Bonuses
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion5" className="collapse pl-5">
                                                                {/* <div className="">
                                                <div className="mt-3 mb-2">Prediction streak bonuses & expirations</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle23"
                                                            checked={streakBonusWeb}
                                                            onChange={(e) => {
                                                                setStreakBonusWeb(e.target.checked)
                                                            }}
                                                        />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle23">Web</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Get Balance Updates</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle24"
                                                                                checked={newUserWeb}
                                                                                onChange={(e) => {
                                                                                    setNewUserWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle24">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ---------------------General--------------------------- */}
                                                        {/* <div className="general">General</div>

                                    <div className="container mt-2">
                                        <div className='d-flex align-items-center justify-content-start mt-4'>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <img className={`${themeClass === "dark"
                                                    ? "iconColor chatImg"
                                                    : "chatImg"
                                                    }`}
                                                    src="./images/trophy.svg" alt="new answers"
                                                />
                                                <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion6">
                                                    Leagues
                                                    <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                </h5>
                                            </div>
                                        </div>
                                        <div id="accordion6" className="collapse pl-5">
                                            <div className="">
                                                <div className="mt-3 mb-2">Your league changed</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle25"
                                                            checked={languageChangeWeb}
                                                            onChange={(e) => {
                                                                setLanguageChangeWeb(e.target.checked)
                                                            }}
                                                        />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle25">Web</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/user.svg" alt="new answers"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion7">
                                                                        Users
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion7" className="collapse pl-5">
                                                                {/* <div className="">
                                                <div className="mt-3 mb-2">A user tagged you</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle26"
                                                            checked={userTaggedWeb}
                                                            onChange={(e) => {
                                                                setUserTaggedWeb(e.target.checked)
                                                            }}
                                                        />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle26">Web</label>
                                                    </div>
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle27"
                                                            checked={userTaggedEmail}
                                                            onChange={(e) => {
                                                                setUserTaggedEmail(e.target.checked)
                                                            }}

                                                        />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle27">Email</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">A user followed you</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle28"
                                                                                checked={userFollowWeb}
                                                                                onChange={(e) => {
                                                                                    setUserFollowWeb(e.target.checked)
                                                                                }}
                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle28">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="">
                                                <div className="mt-3 mb-2">A user liked your content</div>
                                                <div className="d-flex gap-3">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="iphoneToggle29"
                                                            checked={userLikedWeb}
                                                            onChange={(e) => {
                                                                setUserLikedWeb(e.target.checked);
                                                            }}
                                                        />
                                                        <label className="custom-control-label" htmlFor="iphoneToggle29">Web</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                            </div>
                                                        </div>

                                                        <div className="container mt-2">
                                                            <div className='d-flex align-items-center justify-content-start mt-4'>
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <img className={`${themeClass === "dark"
                                                                        ? "iconColor chatImg"
                                                                        : "chatImg"
                                                                        }`} src="./images/group.svg" alt="new answers"
                                                                    />
                                                                    <h5 className="newCommens shadow-none m-0" data-toggle="collapse" data-target="#accordion8">
                                                                        Groups
                                                                        <img className='mr-2' src='./images/Vector(8).svg' alt='' />
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div id="accordion8" className="collapse pl-5">
                                                                <div className="">
                                                                    <div className="mt-3 mb-2">Getting added to new groups</div>
                                                                    <div className="d-flex gap-3">
                                                                        <div className="custom-control custom-switch">
                                                                            <input type="checkbox" className="custom-control-input" id="iphoneToggle30"
                                                                                checked={newGroupAddWeb}
                                                                                onChange={(e) => {
                                                                                    setNewGroupAddWeb(e.target.checked);
                                                                                }}

                                                                            />
                                                                            <label className="custom-control-label" htmlFor="iphoneToggle30">Web</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='container mt-4 d-flex justify-content-end'>
                                                            <button
                                                                style={{
                                                                    border: 'none',
                                                                    color: 'white',
                                                                    backgroundColor: '#069514',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '50px',
                                                                }}
                                                                onClick={handleSaveSettings}
                                                            >Save Settings</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>


    )
}

export default Notifications