import { Link } from "react-router-dom";
import { useState } from "react";

const ResponsiveNavBar = ({onMarketsClick}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{
        padding: "15px 0",
        borderBottom: "1px solid #ddd",
        backgroundColor: "#fff",
      }}
    >
      <div
        className="container"
        style={{
          maxWidth: "1200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Toggle Button (Left in Mobile View) */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Logo for Mobile View */}
        <Link to="/" className="navbar-brand d-lg-none">
          <img
            src="/images/logo.svg"
            alt="SmarterBet Logo"
            style={{ width: "179px", height: "29px" }}
          />
        </Link>

        {/* Logo for Large and Medium Screens */}
        <Link to="/" className="navbar-brand d-none d-lg-block">
          <img
            src="/images/logo.svg"
            alt="SmarterBet Logo"
            style={{ width: "179px", height: "29px" }}
          />
        </Link>

        {/* Navbar Content (Hidden on Mobile, Shown in Toggle) */}
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul
            className="navbar-nav mx-auto"
            style={{
              display: "flex",
              flexDirection: isOpen ? "row" : "row",
              alignItems: isOpen ? "start" : "center",
              gap: "20px",
              listStyle: "none",
              margin: "0",
              padding: "0",
            }}
          >
            {/* Show "Home" and Sign In/Sign Up in Mobile View when toggled */}
            {isOpen ? (
              <div className="d-flex justify-content-between w-100 align-items-center gap-3">
                <li
                  className="nav-item"
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <Link
                    to="/"
                    className="nav-link"
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "#8F8F8F",
                      textDecoration: "none",
                      padding: "10px 0",
                      borderBottom: "2px solid transparent",
                      transition: "0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#069514";
                      e.target.style.textDecoration = "underline";
                      e.target.style.fontWeight = "900";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#8F8F8F";
                      e.target.style.textDecoration = "none";
                      e.target.style.fontWeight = "500";
                    }}
                  >
                    Home
                  </Link>

                  <Link
                    to="/about-us"
                    className="nav-link"
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "#8F8F8F",
                      textDecoration: "none",
                      padding: "10px 0",
                      borderBottom: "2px solid transparent",
                      transition: "0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#069514";
                      e.target.style.textDecoration = "underline";
                      e.target.style.fontWeight = "900";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#8F8F8F";
                      e.target.style.textDecoration = "none";
                      e.target.style.fontWeight = "500";
                    }}
                  >
                    About Us
                  </Link>

                  <Link
                    to="/signin"
                    className="nav-link"
                    style={{
                      color: "#28a745",
                      fontWeight: "500",
                      textDecoration: "none",
                      transition: "0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/signup"
                    className="btn btn-outline-success"
                    style={{
                      padding: "6px 14px",
                      borderRadius: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Sign Up
                  </Link>
                </li>
              </div>
            ) : (
              // Show all menu items in larger screens
              [
                { to: "/", label: "Home" },
                { to: "/about-us", label: "About Us" },
                { to: "", label: "Markets" },
                { to: "/faqs", label: "FAQs" },
                { to: "/contactus", label: "Contact" },
              ].map((item, index) => (
                <li key={index} className="nav-item">
                  {item.label === "Markets" ? (
                    // If item is "Market", use <a> with href="#marketSection"
                    <a
                      href="#marketSection"
                      className="nav-link"
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "17px",
                        lineHeight: "20px",
                        color: "#8F8F8F",
                        textDecoration: "none",
                        padding: "10px 0",
                        borderBottom: "2px solid transparent",
                        transition: "0.3s ease-in-out",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#069514";
                        e.target.style.textDecoration = "underline";
                        e.target.style.fontWeight = "900";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "#8F8F8F";
                        e.target.style.textDecoration = "none";
                        e.target.style.fontWeight = "500";
                      }}
                      onClick={onMarketsClick}
                    >
                      {item.label}
                    </a>
                  ) : (
                    // For other items, use <Link> with to={item.to}
                    <Link
                      to={item.to}
                      className="nav-link"
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "17px",
                        lineHeight: "20px",
                        color: "#8F8F8F",
                        textDecoration: "none",
                        padding: "10px 0",
                        borderBottom: "2px solid transparent",
                        transition: "0.3s ease-in-out",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#069514";
                        e.target.style.textDecoration = "underline";
                        e.target.style.fontWeight = "900";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "#8F8F8F";
                        e.target.style.textDecoration = "none";
                        e.target.style.fontWeight = "500";
                      }}
                    >
                      {item.label}
                    </Link>
                  )}
                </li>
              ))
            )}
          </ul>

          {/* Right-Side Buttons (Visible Only on Large Screens) */}
          <div className="d-none d-lg-flex align-items-center gap-3">
            <Link
              to="/signin"
              className="nav-link"
              style={{
                color: "#28a745",
                fontWeight: "500",
                textDecoration: "none",
                transition: "0.3s ease-in-out",
                fontSize: "15px",
              }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              Sign In
            </Link>
            <Link
              to="/signup"
              className="btn btn-outline-success"
              style={{
                padding: "6px 14px",
                borderRadius: "20px",
                fontWeight: "600",
                fontSize: "15px",
              }}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ResponsiveNavBar;
