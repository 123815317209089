import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles/HomeVersion2.css";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "./network/config/adapter";
import { ApiEndpoints } from "./network/config/endpoints";
import moment from "moment";
import { ThemeContext } from "./Context/ThemeContext";
import { Container } from "react-bootstrap";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import ResponsiveNavBar from "./Components/ResponsiveNavBar";
import FallbackSpinner from "./Components/spinner";

const Home = () => {
  // **********************************************************
  // Remove email from localStorage
  localStorage.removeItem("email");
  // **********************************************************
  const [allMarketData, setAllMarketData] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const { themeClass } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const marketsRef = useRef(null);

  const fetchMarketData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        ApiEndpoints.market.GET_ALL_MARKET
      );
      setAllMarketData(
        response.data.markets.filter(
          (market) =>
            market.bet_status !== "close" &&
            market.bet_status !== "distribution started" &&
            market.bet_status !== "distributed" &&
            market.bet_status !== "resolved"
        )
      );
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMarketData();
  }, [fetchMarketData]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "Light");
  const toggleThemes = () => {
    if (theme === "Light") {
      setTheme("Dark");
    } else {
      setTheme("Light");
    }
  };
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/signup");
  };

  const PollCard = ({ themeClass, poll }) => {
    const isDarkMode = themeClass === "dark";
    console.log("DEBUGPOLL", poll);
    const marketImage = poll?.marketImage
      ? `${poll?.marketImage}`
      : "images/cardimage.png";
    const marketCreateUserImage = poll?.createdBy?.profile_picture
      ? `${poll?.createdBy?.profile_picture}`
      : "images/phase2avatar.png";
    const userName =
      poll?.createdBy?.firstName + " " + poll?.createdBy?.lastName;
    return (
      <div className="container-fluid p-0" style={{ maxWidth: "100%" }}>
        <div
          className={`row mx-auto shadow-sm ${isDarkMode ? "bg-dark text-white" : "bg-white"
            }`}
          style={{
            borderRadius: "12px",
            margin: "20px auto",
            width: "100%",
            maxWidth: "900px",
            boxShadow: isDarkMode
              ? "0 1px 3px rgba(255,255,255,0.1)"
              : "0 1px 3px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "stretch",
          }}
        >
          {/* Image Section */}
          <div className="col-12 col-md-6 col-lg-5 p-0">
            <div style={{ height: "100%", minHeight: "300px", width: "100%" }}>
              <img
                src={marketImage}
                alt="Manchester United Team"
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "12px 0 0 12px",
                }}
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-12 col-md-6 col-lg-7 p-4 d-flex flex-column justify-content-between">
            {/* User Info and Post Details */}
            <div className="d-flex align-items-center mb-3">
              <img
                src={marketCreateUserImage}
                alt="User"
                className="rounded-circle me-2"
                style={{ width: "32px", height: "32px" }}
              />
              <div>
                <strong style={{ fontSize: "14px" }}>{userName}</strong>
              </div>
            </div>

            {/* Poll Question */}
            <h2
              className="mb-3 text-truncate"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                maxWidth: "100%",
              }}
            >
              {poll?.question || "Default Title"}
            </h2>

            <p
              className="d-flex align-items-center mb-3"
              style={{ fontSize: "12px" }}
            >
              Posted at{" "}
              {moment(poll?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>

            {/* Voting Buttons */}
            <div className="d-flex flex-wrap gap-3 mb-5">
              <button
                className="btn"
                style={{
                  borderRadius: "50px",
                  padding: "8px 24px",
                  fontSize: "13px",
                  border: "1px solid #4CAF50",
                  backgroundColor: isDarkMode ? "#2E7D32" : "#E8F5E9",
                  color: isDarkMode ? "#fff" : "#2E7D32",
                  minWidth: "120px",
                  fontWeight: "500",
                }}
              >
                YES {poll?.yesAnswerProbability}
              </button>
              <button
                className="btn"
                style={{
                  borderRadius: "50px",
                  padding: "8px 24px",
                  fontSize: "13px",
                  border: "1px solid #F44336",
                  backgroundColor: isDarkMode ? "#C62828" : "#FFEBEE",
                  color: isDarkMode ? "#fff" : "#C62828",
                  minWidth: "120px",
                  fontWeight: "500",
                }}
              >
                NO {poll?.noAnswerProbability}
              </button>
            </div>

            {/* Engagement Icons */}
            <div className="d-flex align-items-center flex-wrap gap-3 mt-3">
              <div className="d-flex align-items-center gap-2">
                <AiOutlineLike className="icon-style" />
                <span className="count-style">
                  {poll?.likes?.length || "0"}
                </span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <FaRegComment className="icon-style" />
                <span className="count-style">{poll?.commentCount ?? "0"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PollList = ({ themeClass, allMarketData }) => {
    return (
      <div style={{ width: "100%", margin: "0 auto" }}>
        <div className="column g-4" style={{ marginTop: "60px" }}>
          {allMarketData.length > 0 ? (
            allMarketData.map((poll, index) => (
              <div key={index}>
                <PollCard themeClass={themeClass} poll={poll} />
              </div>
            ))
          ) : (
            <p style={{ textAlign: "center", fontSize: "16px" }}>
              No polls available
            </p>
          )}
        </div>
      </div>
    );
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    "/images/CTA-1.png",
    "/images/CTA-2.png",
    "/images/CTA-3.png",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  const scrollToMarkets = () => {
    marketsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [marginTop, setMarginTop] = useState("80px");

  useEffect(() => {
    const updateMargin = () => {
      setMarginTop(
        window.matchMedia("(max-width: 768px)").matches ? "40px" : "80px"
      );
    };

    updateMargin(); // Set initial value
    window.addEventListener("resize", updateMargin); // Listen for resize

    return () => window.removeEventListener("resize", updateMargin); // Cleanup
  }, []);

  const [styles, setStyles] = useState(getResponsiveStyles(window.innerWidth));

  useEffect(() => {
    const handleResize = () =>
      setStyles(getResponsiveStyles(window.innerWidth));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getResponsiveStyles(width) {
    return {
      container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "10px",
        marginTop: width < 640 ? "40px" : width < 1024 ? "64px" : "80px",
      },
      dottedLine: {
        width: width < 640 ? "60px" : width < 1024 ? "80px" : "100px",
        borderBottom: "1px dotted green",
      },
      title: {
        fontSize: width < 640 ? "1rem" : width < 1024 ? "1.3rem" : "1.5rem",
        fontWeight: "bold",
        color: "#222",
        whiteSpace: "nowrap",
      },
      diamond: {
        width: "6px",
        height: "6px",
        backgroundColor: "green",
        transform: "rotate(45deg)",
      },
    };
  }

  return (
    <>
      {/* Navigation section */}

      {/* ------------------------------------------------- */}
      <Container style={{ zoom: 1.0 }}>
        <ResponsiveNavBar onMarketsClick={scrollToMarkets} />

        <section
          className="hero-section"
          style={{
            padding: "80px 0",
            textAlign: "center",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <div className="container">
            <div className="hero-content">
              {/* Main Heading */}
              <h1
                style={{
                  fontSize: "clamp(2.5rem, 6vw, 5rem)", // Responsive font size
                  fontWeight: "900",
                  lineHeight: "1.1",
                  marginBottom: "30px",
                }}
              >
                <div>
                  PUT YOUR{" "}
                  <span style={{ color: "#069514", fontStyle: "italic" }}>
                    MONEY
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "clamp(2rem, 5vw, 4.5rem)",
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  WHERE YOUR{" "}
                  <span style={{ position: "relative" }}>
                    MOUTH IS!
                    <img
                      src="/images/yellow-underline.svg"
                      alt=""
                      style={{
                        position: "absolute",
                        bottom: "-10px",
                        left: 0,
                        width: "100%",
                        height: "8px",
                      }}
                    />
                  </span>
                </div>
              </h1>

              {/* Subtitle */}
              <div
                style={{
                  color: "#666",
                  fontSize: "clamp(1rem, 2vw, 1.5rem)",
                  marginBottom: "40px",
                  maxWidth: "800px",
                  margin: "0 auto 40px",
                }}
              >
                <div>UNLOCK THE POWER OF PREDICTIONS:</div>
                <div>TRADE ON THE OUTCOME OF FUTURE GAMES AND EVENTS</div>
              </div>

              {/* Buttons */}
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Link to="/signup">
                  <button
                    className="btn-primary"
                    style={{
                      backgroundColor: "#069514",
                      color: "white",
                      padding: "15px 30px",
                      borderRadius: "30px",
                      border: "none",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                  >
                    Register Now
                  </button>
                </Link>
                {/* <Link to="/markets"> */}
                <button
                  className="btn-secondary"
                  style={{
                    backgroundColor: "#222",
                    color: "white",
                    padding: "15px 30px",
                    borderRadius: "30px",
                    border: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  onClick={scrollToMarkets}
                >
                  See Markets <i className="fas fa-arrow-trend-up"></i>
                </button>
                {/* </Link> */}
              </div>
            </div>

            {/* Features Section */}
            <div
              className="features-section"
              style={{
                marginTop: "80px",
                padding: "40px 0",
              }}
            >
              <div
                className="row"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                {[
                  {
                    title: "Explore Interests",
                    description: "Discover events nearby",
                  },
                  {
                    title: "Anticipate Results",
                    description: "Forecast the outcome",
                  },
                  {
                    title: "Gain Rewards",
                    description: "Earn from predictions",
                  },
                ].map((feature, index) => (
                  <div
                    key={index}
                    className="feature-card"
                    style={{
                      flex: "1",
                      minWidth: "290px",
                      maxWidth: "350px",
                      padding: "30px",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                      borderRadius: "15px",
                      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.05)",
                      marginBottom: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <img
                        src="/images/home.png"
                        alt=""
                        style={{ width: "75px", height: "75px", flexShrink: 0 }}
                      />
                      <div style={{ flex: 1 }}>
                        <h3
                          style={{
                            color: "#666",
                            marginBottom: "5px",
                            fontSize: "1.45rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {feature.title}
                        </h3>
                        <p
                          style={{
                            margin: 0,
                            fontSize: "15px",
                            lineHeight: "1.2",
                          }}
                        >
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/*Latest Market Section */}
            <div ref={marketsRef} style={styles.container}>
              {/* Left Diamond */}
              <span style={styles.diamond}></span>

              {/* Left Dotted Line */}
              <span style={styles.dottedLine}></span>

              {/* Title */}
              <h2 style={styles.title}>Latest Markets</h2>

              {/* Right Dotted Line */}
              <span style={styles.dottedLine}></span>

              {/* Right Diamond */}
              <span style={styles.diamond}></span>
            </div>
            <div style={{ marginTop: "40px" }}>
              {loading ? (
                <FallbackSpinner />
              ) : (
                <PollList
                  themeClass={themeClass}
                  allMarketData={allMarketData}
                />
              )}
            </div>

            <div onClick={handleRedirect} style={{ cursor: "pointer" }}>
              <img
                src={images[currentIndex]}
                alt="Slideshow"
                style={{
                  width: "100%",
                  marginTop: marginTop,
                  height: "auto",
                  borderRadius: "12px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                  transition: "opacity 0.5s ease-in-out",
                }}
              />
            </div>

            <footer className="bg-white py-3" style={{ marginTop: marginTop }}>
              {/* Divider */}
              <hr className="m-0" />

              <div className="container py-3">
                <div className="row align-items-center justify-content-center text-center text-md-start">

                  {/* Left Section: Privacy Policy */}
                  <div className="col-12 col-md-4 mb-2 mb-md-0 text-center text-md-start">
                    <a href="/privacy-policy" className="text-muted text-decoration-none">
                      Privacy Policy
                    </a>
                  </div>

                  {/* Center Section: Copyright - Now Responsive */}
                  <div className="col-12 col-md-4 d-flex justify-content-center">
                    <p className="text-muted m-0 text-wrap w-100" style={{ fontSize: "12px" }}>
                      ©2024 Smarter Bet - Copyrights. All Rights Reserved.
                    </p>
                  </div>

                  {/* Right Section: Social Icons */}
                  <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end gap-3 mt-2 mt-md-0">
                    <a
                      href="https://www.facebook.com/profile.php?id=61553301357888"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/fb.png" alt="Facebook" className="footer-icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/smarter__bet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/Group 21.png" alt="Instagram" className="footer-icon" />
                    </a>
                    <a
                      href="https://twitter.com/smarter__bet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/Group 113.png" alt="Twitter" className="footer-icon" />
                    </a>
                  </div>

                </div>
              </div>
            </footer>
          </div>
        </section>
      </Container>
    </>
  );
};

export default Home;
