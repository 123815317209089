import React, { useContext, useState, useEffect } from "react";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { ThemeContext } from "../Context/ThemeContext";
import { useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";

const Market = () => {
  const { themeClass } = useContext(ThemeContext);
  const [allMarketData, setAllMarketData] = useState([]);
  const [checkView, setCheckView] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedGrpOption, setSelectedGrpOption] = useState("");
  const [grpOption, setGrpOption] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    async function getMarket() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.market.GET_ALL_MARKET,
          {
            params: {
              search: searchValue,
              bet_status: selectedOption,
              group_name: selectedGrpOption,
            },
          }
        );
        setAllMarketData(response.data.markets);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    }
    getMarket();
    fetchGroupDetails();
  }, [searchValue, selectedOption, selectedGrpOption]);

  useEffect(() => {
    setCheckView(searchParams.get("view"));
  }, [searchParams]);

  async function fetchGroupDetails() {
    try {
      const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
      const groupNames = response.data.map((item) => ({
        value: item.group_name,
        label: item.group_name,
      }));
      setGrpOption(groupNames);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  }

  function changeView() {
    setSearchParams(checkView === "grid" ? {} : { view: "grid" });
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      minWidth: "120px",
      maxWidth: "100%",
      height: "50px",
      borderRadius: "50px",
      backgroundColor: themeClass === "dark" ? "#222" : "#fff",
      borderColor: themeClass === "dark" ? "#555" : "#ced4da",
      color: themeClass === "dark" ? "#fff" : "#000",
      boxShadow: state.isFocused ? "0 0 5px rgba(0,0,0,0.2)" : "none",
      padding: "5px",
      "&:hover": {
        borderColor: themeClass === "dark" ? "#777" : "#aaa",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "12px",
      backgroundColor: themeClass === "dark" ? "#333" : "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      backgroundColor: state.isFocused
        ? themeClass === "dark"
          ? "#444"
          : "#e0e0e0"
        : "transparent",
      color: themeClass === "dark" ? "#fff" : "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "#fff" : "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "#bbb" : "#666",
    }),
  };

  const CardComponent = () => {
    return (
      <div className="col-md-4 col-sm-6">
        <div
          className={`card ${themeClass}`}
          style={{
            borderRadius: "15px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            src="images/cardimage.png"
            className="card-img-top"
            alt="Market"
          />
          <div className="card-body">
            <h5 className="card-title">
              Will Manchester United finish 2024 with a positive goal
              difference?
            </h5>
            <p className="card-text">
              <small className="text-muted">
                Posted at 08:30 PM on Dec 30, 2024
              </small>
            </p>
            <span
              className="badge bg-secondary"
              style={{ borderRadius: "100px" }}
            >
              Sports
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="mt-4">
              <div
                className={`card p-4 border-0 ${themeClass}`}
                style={{
                  borderRadius: "15px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* Search Bar and Filters */}
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-12 col-12 mb-2 mb-lg-0">
                    <div
                      className="input-group w-100"
                      style={{
                        borderRadius: "100px",
                        overflow: "hidden",
                        border: "1px solid #ced4da",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <span
                        className={`input-group-text border-0 ${
                          themeClass === "dark"
                            ? "bg-dark text-white"
                            : "bg-white text-dark"
                        }`}
                      >
                        <FaSearch
                          style={{
                            color: themeClass === "dark" ? "#fff" : "#bbb",
                          }}
                        />
                      </span>

                      <input
                        type="text"
                        className={`form-control border-0 ${
                          themeClass === "dark" ? "bg-dark text-white" : ""
                        }`}
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{
                          height: "50px",
                          borderRadius: "100px",
                          outline: "none",
                          boxShadow: "none",
                          border:
                            themeClass === "dark"
                              ? "1px solid #555"
                              : "1px solid #ced4da",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12">
                    <Select
                      options={[
                        { value: "all", label: "All" },
                        { value: "1", label: "Category 1" },
                        { value: "2", label: "Category 2" },
                      ]}
                      value={selectedOption}
                      onChange={(selected) => setSelectedOption(selected)}
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-12">
                    <Select
                      options={grpOption}
                      value={selectedGrpOption}
                      onChange={(selected) => setSelectedGrpOption(selected)}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="row g-4">
                  {allMarketData.length > 0 ? (
                    allMarketData.map((_, index) => (
                      <CardComponent key={index} />
                    ))
                  ) : (
                    <p className="text-center">No market data available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Market;
