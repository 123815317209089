import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ThemeContext } from "../Context/ThemeContext";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { FaSearch } from "react-icons/fa";
import "./styles/MarketVersion2.css";
import Select from "react-select";
import FallbackSpinner from "../Components/spinner";
import moment from "moment";

const Market = () => {
  const { themeClass } = useContext(ThemeContext);
  const [allMarketData, setAllMarketData] = useState([]);
  const [checkView, setCheckView] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedGrpOption, setSelectedGrpOption] = useState("");
  const [grpOption, setGrpOption] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectColor, setSelectColor] = useState("");
  const [loading, setLoading] = useState(true);
  console.log("DEBUGselectedOption",selectedOption);
  
  useEffect(() => {
    setSelectColor(themeClass === "dark" ? "#1e1e1e" : "#fff");
  }, [themeClass]);
  useEffect(() => {
    async function getMarket() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.market.GET_ALL_MARKET,
          {
            params: {
              search: searchValue,
              bet_status: selectedOption?.label,
              group_name: selectedGrpOption?.label,
            },
          }
        );
        setAllMarketData(response.data.markets);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    }
    getMarket();
  }, [searchValue, selectedOption, selectedGrpOption]);

  useEffect(() => {
    setCheckView(searchParams.get("view"));
  }, [searchParams]);

  async function fetchGroupDetails() {
    try {
      const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
      const groupNames = response.data.map((item) => ({
        value: item.group_name,
        label: item.group_name,
      }));
      setGrpOption(groupNames);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  }

  useEffect(() => {
    fetchGroupDetails();
  }, []);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      minWidth: "120px",
      maxWidth: "100%",
      height: "50px",
      borderRadius: "50px",
      backgroundColor: themeClass === "dark" ? "#222" : "#fff",
      borderColor: themeClass === "dark" ? "#555" : "#ced4da",
      color: themeClass === "dark" ? "#fff" : "#000",
      boxShadow: state.isFocused ? "0 0 5px rgba(0,0,0,0.2)" : "none",
      padding: "5px",
      "&:hover": {
        borderColor: themeClass === "dark" ? "#777" : "#aaa",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "12px",
      backgroundColor: themeClass === "dark" ? "#333" : "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      backgroundColor: state.isFocused
        ? themeClass === "dark"
          ? "#444"
          : "#e0e0e0"
        : "transparent",
      color: themeClass === "dark" ? "#fff" : "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "#fff" : "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "#bbb" : "#666",
    }),
  };

  console.log("allMarketData", allMarketData);

  const CardComponent = ({ data }) => {
    const isDarkMode = themeClass === "dark";
    const navigate = useNavigate();

    const marketImage = data?.marketImage
      ? `${data?.marketImage}`
      : "images/cardimage.png";
    const marketCreateUserImage = data?.createdBy?.profile_picture
      ? `${data?.createdBy?.profile_picture}`
      : "images/phase2avatar.png";
    const userName =
      data?.createdBy?.firstName + " " + data?.createdBy?.lastName;

    const handleCardClick = () => {
      if (data?._id) {
        navigate(`/dashboard-market-detail/${data._id}`);
      }
    };

    return (
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
        <div
          className="card w-100"
          onClick={handleCardClick}
          style={{
            borderRadius: "15px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            cursor: "pointer",
            maxWidth: "100%",
          }}
        >
          {/* Image */}
          <img
            src={marketImage}
            className="card-img-top img-fluid"
            alt={data?.title}
            style={{
              objectFit: "cover",
              width: "100%",
              //   height: "auto",
              height: "250px",
              maxHeight: "250px",
            }}
          />

          {/* Card Body */}
          <div className="card-body p-3">
            {/* Profile Section */}
            <div className="d-flex align-items-center mb-3">
              <img
                src={marketCreateUserImage}
                alt={data?.username}
                className="rounded-circle me-2"
                style={{ width: "40px", height: "40px", objectFit: "cover" }}
              />
              <span className="fw-medium text-secondary">
                {userName || "Quincy Oliver"}
              </span>
            </div>

            {/* Title */}
            <h5
              className="card-title fw-bold text-dark"
              style={{ fontSize: "14px" }}
            >
              {data?.question || "Default Title"}
            </h5>

            {/* Timestamp */}
            <p className="card-text text-muted small">
              Posted at{" "}
              {moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss A")}

            </p>

            {/* Badge */}
            {/* <span className="badge bg-light text-dark fw-semibold px-3 py-2 rounded-pill text-uppercase">
              {data?.addToGroup || "Sports"}
            </span> */}
            {/* <span
              style={{
                backgroundColor: "#069514",
                border: "1px solid #069514",
                color: "#ffffff", // White text for better contrast
                marginRight: "16px",
              }}
              className="badge fw-semibold px-3 py-2 rounded-pill text-uppercase"
            >
              {data?.addToGroup || "Sports"}
            </span> */}
            {/* <span
              style={{
                border: "1px solid #069514", // Green border
                color: "#069514", // Green text
                marginRight: "16px",
                backgroundColor: "transparent", // No background color
              }}
              className="badge fw-semibold px-3 py-2 rounded-pill text-uppercase"
            >
              {data?.addToGroup || "Sports"}
            </span> */}

            <div className="d-flex gap-2 justify-content-start mt-3">
              <button
                className="btn"
                style={{
                  borderRadius: "40px",
                  padding: "6px 20px",
                  fontSize: "12px",
                  border: "1px solid #4CAF50",
                  backgroundColor: isDarkMode ? "#2E7D32" : "#E8F5E9",
                  color: isDarkMode ? "#fff" : "#2E7D32",
                  minWidth: "100px",
                  fontWeight: "500",
                }}

              >
                YES {data?.yesAnswerProbability}
              </button>
              <button
                className="btn"
                style={{
                  borderRadius: "40px",
                  padding: "6px 20px",
                  fontSize: "12px",
                  border: "1px solid #F44336",
                  backgroundColor: isDarkMode ? "#C62828" : "#FFEBEE",
                  color: isDarkMode ? "#fff" : "#C62828",
                  minWidth: "100px",
                  fontWeight: "500",
                }}

              >
                NO {data?.noAnswerProbability}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <FallbackSpinner />
      ) : (
        <div className={` ${themeClass} `}>
          <div className="row d-flex">
            <div className="col-12">
              <div className="mt-4">
                <div
                  className={`card p-4 border-0 ${themeClass}`}
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {/* Search Bar and Filters */}
                  <div className="row mb-3 g-2">
                    {/* Search Bar */}
                    <div className="col-lg-6 col-md-6 col-12">
                      <div
                        className="input-group w-100"
                        style={{
                          borderRadius: "100px",
                          overflow: "hidden",
                          border: "1px solid #ced4da",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <span
                          className={`input-group-text border-0 ${themeClass === "dark"
                            ? "bg-dark text-white"
                            : "bg-white text-dark"
                            }`}
                        >
                          <FaSearch
                            style={{
                              color: themeClass === "dark" ? "#fff" : "#bbb",
                            }}
                          />
                        </span>

                        <input
                          type="text"
                          className={`form-control border-0 ${themeClass === "dark" ? "bg-dark text-white" : ""
                            }`}
                          placeholder="Search..."
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          style={{
                            height: "50px",
                            borderRadius: "100px",
                            outline: "none",
                            boxShadow: "none",
                            border:
                              themeClass === "dark"
                                ? "1px solid #555"
                                : "1px solid #ced4da",
                          }}
                        />
                      </div>
                    </div>

                    {/* Category Filter */}
                    <div className="col-lg-3 col-md-6 col-12">
                      <Select
                        options={[
                          { value: "", label: "All" },
                          { value: "open", label: "Open" },
                          { value: "closed", label: "Closed" },
                          { value: "resolved", label: "Resolved" },
                        ]}
                        value={selectedOption || { value: "", label: "All" }}
                        onChange={(selected) => setSelectedOption(selected)}
                        styles={customStyles}
                      />
                    </div>

                    {/* Group Filter */}
                    <div className="col-lg-3 col-md-6 col-12">
                      <Select
                        options={grpOption}
                        value={selectedGrpOption || grpOption[0]}
                        onChange={(selected) => setSelectedGrpOption(selected)}
                        styles={customStyles}
                      />
                    </div>
                  </div>

                  <div className="row g-4">
                    {allMarketData.length > 0 &&
                      allMarketData.map((market, index) => (
                        <CardComponent key={index} data={market} />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Market;

// const QuestionTitle = ({ data }) => {
//     const [isHovered, setIsHovered] = useState(false);

//     return (
//         <div
//             style={{
//                 position: "relative",
//                 display: "inline-block",
//                 maxWidth: "100%",
//                 cursor: "pointer",
//             }}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             {/* Truncated Question */}
//             <h5
//                 className="card-title"
//                 style={{
//                     fontFamily: "Inter, sans-serif",
//                     fontWeight: 700,
//                     fontSize: "13px",
//                     lineHeight: "17px",
//                     color: "#333",
//                     marginBottom: "10px",
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                     maxWidth: "250px", // Adjust width as needed
//                 }}
//             >
//                 {data?.question || "Default Title"}
//             </h5>

//             {/* Tooltip for Full Question */}
//             {isHovered && data?.question?.length > 50 && (
//                 <div
//                     style={{
//                         position: "absolute",
//                         background: "rgba(0, 0, 0, 0.85)",
//                         color: "#fff",
//                         padding: "8px 12px",
//                         borderRadius: "8px",
//                         whiteSpace: "normal",
//                         fontSize: "12px",
//                         maxWidth: "250px",
//                         zIndex: 100,
//                         bottom: "100%",
//                         left: "50%",
//                         transform: "translateX(-50%)",
//                         boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
//                     }}
//                 >
//                     {data?.question}
//                 </div>
//             )}
//         </div>
//     );
// };
