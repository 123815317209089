import React from "react";
import { Navbar, Image } from "react-bootstrap";
import { MdOutlineMail } from "react-icons/md"; // Mail Icon
import { IoMdNotificationsOutline } from "react-icons/io"; // Notification Icon
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";

const AppBar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  console.log("DEBUG USER", user);

  // Mail Icon Component with Hover Effect
  const MailIcon = ({ hasNewMail }) => {
    const handleRedirect = () => {
      navigate("/contact-us");
    };

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={handleRedirect}
      >
        {/* Mail Icon with Hover Effect */}
        <MdOutlineMail
          className="me-3 text-dark"
          style={{
            width: "20px",
            height: "20px",
            transition: "transform 0.2s ease-in-out, color 0.2s ease-in-out",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        />

        {/* Red Badge (Visible only if hasNewMail is true) */}
        {hasNewMail && (
          <span
            style={{
              position: "absolute",
              top: "2px",
              right: "15px",
              width: "8px",
              height: "8px",
              backgroundColor: "red",
              borderRadius: "50%",
              border: "1px solid white",
            }}
          ></span>
        )}
      </div>
    );
  };

  // Notification Icon Component with Hover Effect
  const NotificationIcon = ({ hasNewNotification }) => {
    const handleRedirect = () => {
      navigate("/dashboard-notifications");
    };

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={handleRedirect}
      >
        {/* Notification Bell Icon with Hover Effect */}
        <IoMdNotificationsOutline
          className="me-4 text-dark"
          style={{
            width: "24px",
            height: "24px",
            transition: "transform 0.2s ease-in-out, color 0.2s ease-in-out",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        />

        {/* Red Badge (Visible only if hasNewNotification is true) */}
        {hasNewNotification && (
          <span
            style={{
              position: "absolute",
              top: "2px",
              right: "28px",
              width: "8px",
              height: "8px",
              backgroundColor: "red",
              borderRadius: "50%",
              border: "1px solid white",
            }}
          ></span>
        )}
      </div>
    );
  };

  // Profile Click Event
  const handleClick = () => {
    navigate("/dashboard-profile");
  };

  return (
    <Navbar
      expand="lg"
      className="bg-white p-3 position-fixed top-0"
      style={{
        height: "85px",
        width: isSidebarOpen ? "calc(100% - 271px)" : "100%",
        transition: "width 0.3s ease",
        zIndex: 1000,
        right: 0, // Ensures the navbar remains aligned to the right
      }}
    >
      <div className="container-fluid d-flex align-items-center justify-content-end">
        <div className="d-flex align-items-center">
          {/* Mail Icon */}
          <MailIcon hasNewMail={true} />

          {/* Notification Icon */}
          <NotificationIcon hasNewNotification={true} />

          {/* User Profile */}
          {/* User Profile */}
          <div className="d-flex align-items-center" onClick={handleClick}>
            <Image
              src={user?.profile_picture || "./images/dummy-avatar-png.png"}
              roundedCircle
              style={{
                width: "48px",
                height: "48px",
                cursor: "pointer",
                transition: "transform 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            />

            {/* Hide name and email on small screens */}
            <div className="ms-2 d-none d-lg-block">
              <span className="fw-bold d-block">{user?.full_name}</span>
              <span className="text-muted small">{user?.email}</span>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default AppBar;
