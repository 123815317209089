import React, { useContext, useState } from "react";
import { Row, Col, Button, Nav, Tab } from "react-bootstrap";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import { ThemeContext } from "../Context/ThemeContext";
import { useNavigate, useParams } from "react-router";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { useEffect } from "react";
import moment from "moment";
import FallbackSpinner from "../Components/spinner";
import BettingModal from "../Components/PollModal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { formatDistanceToNow } from "date-fns";
import { Avatar, Chip } from "@mui/material";
const PollComponent = () => {
  const { _id } = useParams();

  const { themeClass } = useContext(ThemeContext);
  const [activeKey, setActiveKey] = useState("comments");
  const isDarkMode = themeClass === "dark";
  const [marketData, setMarketData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [betData, setBetData] = useState({});
  const [data, setChartData] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState("");
  const [commentLoading, setCommetLoading] = useState(false);
  const [tradeData, setTradeData] = useState([]);
  const [loadingTrades, setLoadingTrades] = useState(false);
  const [tabmarketData, setTabMarketData] = useState({});
  const [userTradeData, setUserTradeData] = useState([]);
  const [loadingUserTrades, setLoadingUserTrades] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [tableTheme, setTableTheme] = useState("table-light");
  console.log("marketData", marketData);
  const navigate = useNavigate();

  async function getMarketDetails() {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.market.getMarketDetails(_id),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMarketData(response?.data);
      setLoading(false);
      chartData(response?.data?._id);
      // setMarketData(response.data);
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    getMarketDetails();
  }, [_id]);

  const removeHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  };

  const handleOpenModal = (data) => {
    setBetData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleContentChange = (event) => {
    setIsValid(true);
    setValue(event.target.value);
  };

  const handleEditorChange = (html) => {
    if (!value.trim()) {
      setIsValid(false);
    } else {
      setIsValid(true);
      createQuestion();
    }
  };
  async function chartData(id) {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.market.marketDetailChart(id)
      );
      setChartData(response.data.chart);
    } catch (error) { }
  }

  const createQuestion = () => {
    const payload = {
      question: marketData?._id,
      text: value,
    };
    setCommetLoading(true);
    axiosInstance
      .post(ApiEndpoints.market.question, payload)
      .then((response) => {
        setCommetLoading(false);
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
        setValue("");
        // getAllComment(marketQuestion)
      })

      .catch((error) => {
        setCommetLoading(false);
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setCommetLoading(false);
      });
  };

  async function fetchTradeList(id) {
    try {
      setLoadingTrades(true);
      const response = await axiosInstance.get(
        ApiEndpoints.betting.TradeList(id)
      );
      setTradeData(response.data.bets || []);
    } catch (error) {
      console.error("Error fetching trades:", error);
    } finally {
      setLoadingTrades(false);
    }
  }

  async function bettingUser(id) {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.betting.userBet(id)
      );
      setTabMarketData(response.data.user || {}); // Ensure it's an object
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  }
  async function userTradeList(id) {
    setLoadingUserTrades(true);
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.betting.userTrade(id)
      );
      setUserTradeData(response.data.bets);
    } catch (error) {
      console.error("Error fetching user trades:", error);
    } finally {
      setLoadingUserTrades(false);
    }
  }
  useEffect(() => {
    if (activeKey === "positions") {
      bettingUser(_id);
    } else if (activeKey === "trades") {
      fetchTradeList(_id);
    } else if (activeKey === "your-trades") {
      userTradeList(_id);
    }
    getAllComment(_id);
  }, [activeKey, _id]);
  useEffect(() => {
    themeClass === "dark"
      ? setTableTheme("table-dark")
      : setTableTheme("table-light");
  }, [themeClass]);

  async function getAllComment(id) {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.comment.getQuestionById(id)
      );
      setAllComments(response.data.question);
    } catch (error) { }
  }
  return (
    <>
      {loading ? (
        <FallbackSpinner />
      ) : (
        <div className={` ${themeClass} `}>
          <div className="row d-flex">
            <div className="col-12">
              <div className="mt-4">
                <div
                  className={`card p-4 border-0 ${themeClass}`}
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {/* Header Image */}
                  {/* Header Image */}
                  <div className="mb-3">
                    <img
                      src={
                        marketData?.marketImage
                          ? marketData.marketImage
                          : "images/phase2avatar.png"
                      }
                      alt="Manchester United Team"
                      className="img-fluid w-100"
                      style={{
                        borderRadius: "25px",
                        objectFit: "cover",
                        height: "400px",
                      }}
                    />
                  </div>

                  {/* Main Content Section */}
                  <Row>
                    <Col lg={8}>
                      {/* Profile Section */}
                      <div
                        className="d-flex align-items-center mb-2"
                        onClick={() =>
                          navigate(
                            `/dashboard-other-profile/${marketData?.createdBy?._id}`
                          )
                        }
                      >
                        <img
                          src={
                            marketData?.createdBy?.profile_picture
                              ? marketData?.createdBy?.profile_picture
                              : "images/phase2avatar.png"
                          }
                          alt="User Avatar"
                          className="rounded-circle me-2"
                          width="40"
                          height="40"
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.2)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                        <strong
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.color = "blue")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = "black")
                          }
                        >
                          {(marketData?.createdBy?.firstName || "") +
                            " " +
                            (marketData?.createdBy?.lastName || "")}
                        </strong>
                      </div>

                      {/* Poll Question */}
                      <h5 className="mt-2 fw-bold">{marketData?.question}</h5>
                      <p className="text-muted">
                        Posted at{" "}
                        {moment(marketData?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss A"
                        )}
                      </p>

                      {/* Voting Buttons */}
                      <div className="d-flex flex-wrap gap-3 mb-5">
                        <button
                          className="btn"
                          style={{
                            borderRadius: "50px",
                            padding: "8px 24px",
                            fontSize: "13px",
                            border: "1px solid #4CAF50",
                            backgroundColor: isDarkMode ? "#2E7D32" : "#E8F5E9",
                            color: isDarkMode ? "#fff" : "#2E7D32",
                            minWidth: "120px",
                            fontWeight: "500",
                          }}
                          onClick={() =>
                            handleOpenModal({
                              pollId: marketData?._id,
                              question: marketData?.question,
                              yesAnswerProbability:
                                marketData?.updatedMarket?.yesAnswerProbability,
                              noAnswerProbability:
                                marketData?.updatedMarket?.noAnswerProbability,
                            })
                          }
                        >
                          YES {marketData?.updatedMarket?.yesAnswerProbability}
                        </button>
                        <button
                          className="btn"
                          style={{
                            borderRadius: "50px",
                            padding: "8px 24px",
                            fontSize: "13px",
                            border: "1px solid #F44336",
                            backgroundColor: isDarkMode ? "#C62828" : "#FFEBEE",
                            color: isDarkMode ? "#fff" : "#C62828",
                            minWidth: "120px",
                            fontWeight: "500",
                          }}
                          onClick={() =>
                            handleOpenModal({
                              pollId: marketData?._id,
                              question: marketData?.question,
                              yesAnswerProbability:
                                marketData?.updatedMarket?.yesAnswerProbability,
                              noAnswerProbability:
                                marketData?.updatedMarket?.noAnswerProbability,
                            })
                          }
                        >
                          NO {marketData?.updatedMarket?.noAnswerProbability}
                        </button>
                      </div>

                      {/* Poll Description */}
                      <p className="mt-2 text-muted">
                        {removeHTMLTags(marketData?.description)}
                      </p>

                      {/* Tabs Section */}
                    </Col>

                    {/* Chart Section */}
                    {/* Chart Section */}
                    <Col
                      md={4}
                      sm={12}
                      className="mt-3 d-flex justify-content-center"
                    >
                      <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                          data={data}
                          margin={{ top: 10, right: 20, left: 0, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="#ddd" />
                          <XAxis dataKey="name" interval={"preserveStartEnd"} />
                          <YAxis
                            domain={[0, 100]}
                            tickFormatter={(tick) => `${tick}%`}
                          />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey="Yes"
                            stroke="#4CAF50"
                            strokeWidth={3}
                            dot={{ r: 6 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Col>
                    <Tab.Container
                      activeKey={activeKey}
                      onSelect={(k) => setActiveKey(k)}
                    >
                      <Nav
                        variant="tabs"
                        className="w-100 mt-3 d-flex flex-wrap"
                      >
                        {[
                          { key: "comments", label: "Comments" },
                          { key: "positions", label: "Positions" },
                          { key: "trades", label: "Trades" },
                          { key: "your-trades", label: "Your Trades" },
                        ].map((tab) => (
                          <Nav.Item
                            key={tab.key}
                            className="flex-grow-1 text-center"
                          >
                            <Nav.Link
                              eventKey={tab.key}
                              className={`fw-bold ${activeKey === tab.key
                                  ? "text-success"
                                  : "text-muted"
                                }`}
                              style={
                                activeKey === tab.key
                                  ? {
                                    backgroundColor: "#E6F4EA",
                                    borderRadius: "10px",
                                  }
                                  : {}
                              }
                            >
                              {tab.label}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>

                      <Tab.Content className="mt-3 w-100">
                        {/* Comments Section */}
                        <Tab.Pane eventKey="comments">
                          <textarea
                            className="form-control mt-2 rounded"
                            placeholder="Provide background information and market resolution..."
                            style={{
                              width: "100%",
                              height: "146px",
                              borderWidth: "1px",
                              backgroundColor: "#EEEFF2",
                            }}
                            onChange={handleContentChange}
                            value={value}
                          ></textarea>
                          {!isValid && (
                            <div style={{ color: "red", marginTop: "10px" }}>
                              Content is required
                            </div>
                          )}
                        </Tab.Pane>

                        {/* Trades Section */}
                        <Tab.Pane eventKey="trades">
                          {loadingTrades ? (
                            <p>Loading trades...</p>
                          ) : tradeData.length > 0 ? (
                            tradeData.map((item, index) => (
                              <div
                                key={index}
                                className="d-flex justify-content-between mb-4"
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <Avatar
                                    src={
                                      item.user.profile_picture ||
                                      "/images/photo.png"
                                    }
                                    alt=""
                                  />
                                  <p>
                                    {item.user.firstName} ${item.amount}
                                    <span
                                      style={{
                                        color: "#EB5757",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item.answer.toUpperCase()}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                  <span>
                                    {formatDistanceToNow(
                                      new Date(item.createdAt),
                                      { addSuffix: true, includeSeconds: true }
                                    ).replace("about ", "")}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No trades available</p>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="positions">
                          <div
                            className="flex justify-content-center mb-4"
                            style={{ fontSize: "18px" }}
                          >
                            <div className="d-flex col-md-6 align-items-end justify-content-end p-0">
                              <div>
                                {tabmarketData?.yesAnswer?.totalQuantity}{" "}
                                <span style={{ color: "#069514" }}> YES </span>{" "}
                                Payouts
                              </div>
                            </div>
                            <div className="d-flex col-md-6 align-items-end justify-content-end p-0">
                              <div>
                                {tabmarketData?.noAnswer?.totalQuantity}{" "}
                                <span style={{ color: "#EB5757" }}> NO </span>{" "}
                                Payouts
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-3 py-2">
                            {/* Column 1: YES Bets */}
                            <div className="flex-grow-1">
                              {tabmarketData?.yesAnswer?.users?.map(
                                (user, index) => (
                                  <div
                                    key={index}
                                    className="market-bet-position flex flex-grow-1 justify-content-between py-2 px-2 mb-2"
                                  >
                                    <div className="flex justify-content-between gap-2">
                                      <div className="d-flex align-items-start">
                                        <Avatar
                                          src={
                                            user.profile_picture ||
                                            "/images/profile_image.svg"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div>{user.name}</div>
                                    </div>
                                    <span style={{ color: "#069514" }}>
                                      ${user.amount}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>

                            {/* Column 2: NO Bets */}
                            <div className="flex-grow-1">
                              {tabmarketData?.noAnswer?.users?.map(
                                (user, index) => (
                                  <div
                                    key={index}
                                    className="market-bet-position flex flex-grow-1 justify-content-between py-2 px-2 mb-2"
                                  >
                                    <div className="flex justify-content-between gap-2">
                                      <Avatar
                                        src={user.profile_picture}
                                        alt=""
                                      />
                                      <div>{user.name}</div>
                                    </div>
                                    <span style={{ color: "#069514" }}>
                                      ${user.amount}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content className="mt-3 w-100">
                        <Tab.Pane eventKey="your-trades">
                          {loadingUserTrades ? (
                            <p>Loading your trades...</p>
                          ) : userTradeData.length > 0 ? (
                            <div className="table-responsive">
                              <table className={`${tableTheme} table`}>
                                <thead>
                                  <tr>
                                    <th>Bet</th>
                                    <th>Amount</th>
                                    <th>Quantity</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userTradeData.map((trade) => (
                                    <tr key={trade._id}>
                                      <td>
                                        <span
                                          style={{
                                            color:
                                              trade.answer === "yes"
                                                ? "#069514"
                                                : "#EB5757",
                                          }}
                                        >
                                          {trade.answer.toUpperCase()}
                                        </span>
                                      </td>
                                      <td>${trade.amount}</td>
                                      <td>{trade.quantity}</td>
                                      <td>
                                        {moment(trade.createdAt).fromNow()}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>No trades available</p>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                      {/* Show Submit Button Only for Comments Tab */}
                      {activeKey === "comments" && (
                        <div className="d-flex justify-content-end mt-3 mb-3">
                          <Button
                            variant="outline-success"
                            className="rounded-pill"
                            onClick={handleEditorChange}
                            disabled={commentLoading}
                          >
                            Submit
                          </Button>
                        </div>
                      )}

                      {activeKey === "comments" && (<div style={{ marginTop: "50px" }}>
                        {allComments.map((item, index) => (
                          <>
                            <div
                              className="d-flex align-items-start mb-2 row"
                              key={index}
                            >
                              <div className="d-flex gap-2 col-12">
                                <div className="d-flex align-items-start">
                                  <Avatar
                                    src={
                                      item.profile_picture ||
                                      "/images/profile_image.svg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex align-items-start flex-column">
                                  {/* <div style={{ color: '#808080' }}>
                                                                    {item.question && <span>{item.question}</span>}
                                                                </div> */}
                                  <div style={{ wordBreak: "break-word" }}>
                                    <span className="comment-username">
                                      @{item.name}
                                    </span>
                                    &nbsp;
                                    <span>{removeHTMLTags(item.text)}</span>
                                  </div>
                                  <div>
                                    <span style={{ color: "#000000" }}>
                                      Reply :
                                    </span>
                                    {item.answer && (
                                      <span
                                        style={{
                                          display: "block",
                                          color: "#808080",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {item.answer}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-12"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Chip
                                  label={formatDistanceToNow(
                                    new Date(item.createdAt),
                                    { addSuffix: true, includeSeconds: true }
                                  ).replace("about ", "")}
                                ></Chip>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>)}

                    </Tab.Container>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
      {isModalOpen && (
        <BettingModal
          market={betData}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          fetchMarketData={getMarketDetails}
          image={"detail"}
        />
      )}
    </>
  );
};

export default PollComponent;
