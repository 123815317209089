import React, { useEffect, useState } from "react";
import { ApiEndpoints } from "../network/config/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../network/config/adapter";
import { useAuth } from "../hooks/useAuth";

const BettingModal = ({ isOpen, onClose, market, fetchMarketData, image }) => {
  const { availableBalance, fetchUserdata } = useAuth();

  const [modalTheme, setModalTheme] = useState("green-theme");
  const [modalBtnColor, setModalBtnColor] = useState("modalBtnGreen");
  const [answer, setAnswer] = useState("yes");
  const [modalPrice, setModalPrice] = useState(1);
  const [rangePrice, setRangePrice] = useState(1);
  const [betLoading, setBetLoading] = useState(false);
  const [maxPayout, setMaxPayout] = useState("");
  const handleKeyPress = (event) => {
    const isValidInput = /^[0-9]+$/.test(event.key);
    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const handleChangeModal = (event) => {
    setModalPrice(Number(event.target.value));
  };

  const handleClick = (type) => {
    if (type === "increase") {
      setModalPrice(modalPrice + 1);
      setRangePrice(modalPrice + 1);
    } else if (type === "decrease" && modalPrice > 1) {
      setModalPrice(modalPrice - 1);
      setRangePrice(modalPrice - 1);
    }
  };

  // Get background color based on theme
  const getBackgroundColor = () => {
    return modalTheme === "green-theme"
      ? "#E8F5E9"
      : modalTheme === "red-theme"
      ? "#FFEBEE"
      : "#F5F5F5";
  };

  const fetchWinnings = async () => {
    const payload = {
      marketId: market?.pollId,
      answer: answer,
      amount: modalPrice,
    };
    axiosInstance
      .post(ApiEndpoints.betting.winningAmount, payload)
      .then((response) => {
        console.log("res", response.data);
        setMaxPayout(response?.data?.returnAmount);
      });
  };
  const betPlaced = async () => {
    const payload = {
      question: market?.pollId,
      amount: rangePrice,
      quantity: rangePrice,
      unitPrice: rangePrice,
      answer: answer,
    };
    setBetLoading(true);
    axiosInstance
      .post(ApiEndpoints.betting.BET, payload)
      .then((response) => {
        fetchMarketData();
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
        onClose();
        fetchUserdata();
      })

      .catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setBetLoading(false);
      });
  };
  useEffect(() => {
    if (isOpen) {
      fetchWinnings();
    }
  }, [isOpen, modalPrice, answer]);

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: "white",
          padding: "30px",
          borderRadius: "10px",
          maxWidth: "800px",
          width: "90%",
          position: "relative",
          margin: "20px",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            cursor: "pointer",
            padding: "5px",
          }}
          onClick={onClose}
        >
          {image === "detail" ? (
            <img
              src="../images/cross.png"
              alt="close"
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <img
              src="./images/cross.png"
              alt="close"
              style={{ width: "30px", height: "30px" }}
            />
          )}
        </span>

        <h4
          style={{
            width: "90%",
            fontSize: "20px",
            marginBottom: "25px",
            fontWeight: "500",
          }}
        >
          {market?.question}
        </h4>

        {/* Bet Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            margin: "30px 0",
          }}
        >
          <button
            style={{
              backgroundColor:
                modalTheme === "green-theme" ? "#069514" : "#fff",
              color: modalTheme === "green-theme" ? "#fff" : "#069514",
              padding: "12px 30px",
              borderRadius: "25px",
              cursor: "pointer",
              border: "1px solid #069514",
              fontSize: "16px",
              fontWeight: "500",
              transition: "all 0.3s ease",
              minWidth: "140px",
            }}
            onClick={() => {
              setModalBtnColor("modalBtnGreen");
              setModalTheme("green-theme");
              setAnswer("yes");
            }}
          >
            Bet YES ↑
          </button>

          <button
            style={{
              backgroundColor: modalTheme === "red-theme" ? "#FF0000" : "#fff",
              color: modalTheme === "red-theme" ? "#fff" : "#FF0000",
              padding: "12px 30px",
              borderRadius: "25px",
              cursor: "pointer",
              border: "1px solid #FF0000",
              fontSize: "16px",
              fontWeight: "500",
              transition: "all 0.3s ease",
              minWidth: "140px",
            }}
            onClick={() => {
              setModalBtnColor("modalBtnRed");
              setModalTheme("red-theme");
              setAnswer("no");
            }}
          >
            Bet NO ↓
          </button>
        </div>

        {/* Amount Section */}
        <div
          style={{
            backgroundColor: getBackgroundColor(),
            padding: "25px",
            borderRadius: "10px",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div
            style={{
              flex: "1",
              minWidth: "250px",
              maxWidth: "300px",
            }}
          >
            <div style={{ fontSize: "16px", marginBottom: "10px" }}>Amount</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #d2d2d2",
                borderRadius: "6px",
                padding: "8px 12px",
                marginTop: "10px",
                backgroundColor: "#fff",
                width: "100%",
                maxWidth: "200px",
              }}
            >
              <span style={{ marginRight: "5px" }}>$</span>
              <input
                type="text"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  padding: "0 5px",
                  fontSize: "16px",
                  backgroundColor: "transparent",
                }}
                onChange={handleChangeModal}
                onKeyDown={handleKeyPress}
                placeholder="Enter value"
                value={modalPrice}
                readOnly={true}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <div
                  onClick={() => handleClick("increase")}
                  style={{
                    cursor: "pointer",
                    color: "#069514",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  ▲
                </div>
                <div
                  style={{
                    color: "#069514",
                    fontSize: "12px",
                    padding: "2px 0",
                  }}
                >
                  +{rangePrice}
                </div>
                <div
                  onClick={() => handleClick("decrease")}
                  style={{
                    cursor: "pointer",
                    color: "#069514",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  ▼
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
                paddingRight: "20px",
              }}
            >
              <div>
                <div style={{ fontSize: "16px" }}>YES</div>
                <div style={{ fontSize: "16px", color: "#666" }}>20.00%</div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div style={{ fontSize: "16px" }}>NO</div>
                <div style={{ fontSize: "16px", color: "#666" }}>80.00%</div>
              </div>
            </div>
          </div>

          <div
            style={{
              flex: "2",
              minWidth: "270px",
              paddingLeft: "20px",
              borderLeft: "1px solid #eee",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <span style={{ color: "#828282" }}>
                Balance ${availableBalance}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <input
                type="range"
                min="1"
                max="100"
                value={rangePrice}
                readOnly
                onChange={(e) => {
                  setModalPrice(Number(e.target.value));
                  setRangePrice(Number(e.target.value));
                }}
                style={{
                  width: "100%",
                  height: "4px",
                  borderRadius: "10px",
                  accentColor:
                    modalTheme === "green-theme" ? "#069514" : "#FF0000",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div style={{ fontSize: "16px" }}>${rangePrice}</div>
              </div>
            </div>

            {/* ✅ Max Payout Section */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // gap: "3px",
                marginTop: "35px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span>Max Payout</span>
              <span style={{ fontSize: "18px", color: "#069514" }}>
                ${maxPayout}
              </span>
            </div>
          </div>
        </div>

        {/* Bet Button */}
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <button
            onClick={betPlaced}
            disabled={betLoading}
            style={{
              backgroundColor:
                modalTheme === "green-theme" ? "#069514" : "#FF0000",
              color: "white",
              padding: "15px 40px",
              borderRadius: "25px",
              border: "none",
              cursor: "pointer",
              width: "100%",
              fontSize: "16px",
              fontWeight: "500",
              transition: "all 0.3s ease",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {betLoading ? (
              <div class="spinner-border" role="status" />
            ) : (
              `Bet ${modalPrice}`
            )}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BettingModal;
