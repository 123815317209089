import React from "react";
import { Link } from "react-router-dom";
import LeftSidePanel from "../Components/LeftSidePanel";
import "./styles/Faq.css";
import { useContext } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import { useAuth } from "../hooks/useAuth";

const Faq = () => {
  const { themeClass, themeInput, toggleTheme } = useContext(ThemeContext);
  const storedToken = localStorage.getItem("Oddplus_Token");
  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">

              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-5 mb-4 text-justify">
                      <div className="">
                        <nav className="navbar navbar-expand-md mt-3 flex justify-content-start">
                          {storedToken === null && (
                            <Link to="/signin">
                              <button
                                className="mr-4"
                                style={{
                                  border: "none",
                                  width: 35,
                                  height: 35,
                                  borderRadius: 30,
                                }}
                              >
                                <img src="./images/icon_back.svg" alt="back" />
                              </button>
                            </Link>
                          )}
                          <h3 className="font-weight-bold m-0">
                            Frequently Asked Questions{" "}
                          </h3>
                        </nav>
                      </div>
                      <div
                        className={`accordion mt-4 accordionPanelsStayOpenExample accordion-flush`}
                        id="accordionPanelsStayOpenExample"
                        style={{ marginBottom: "10rem" }}
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              What is a prediction market?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Prediction market allows individuals to bet on the
                              outcome of future events by buying and selling
                              contracts that represent predictions about future
                              events.
                              <br />
                              <br />
                              These markets rely on the collective knowledge and
                              wisdom of participants to generate forecasts. The
                              prices of these contracts fluctuate based on the
                              perceived likelihood of the events occurring. As
                              events unfold, the contracts are redeemed for a
                              fixed amount if the predicted outcome happens or
                              become worthless if it does not.
                              <br />
                              <br />
                              Example:
                              <br />
                              <br />
                              Will Company X release a groundbreaking product in
                              the next year?"
                              <br />
                              <br />
                              If the current market probability suggests a 30%
                              chance of this happening, but you are highly
                              confident that the true likelihood is greater, you
                              can acquire shares at $0.30/share. In the event
                              Company X does launch a groundbreaking product,
                              these shares will be redeemed at $1. If your
                              assessment is accurate, you stand to gain $0.70
                              per share.
                              <br />
                              <br />
                              However, your judgment might be off, either
                              underestimating or overestimating the actual
                              probability. This creates a financial incentive
                              for others to enter the market, correct the
                              perceived misjudgment, and profit in the process.
                              As more participants join and trade, the implied
                              probability will gradually align with the market's
                              most accurate estimate over time.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              Why Smarter Bet?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              On Smarter Bet, the thrill of prediction markets
                              is taken to the next level. Here, anyone can craft
                              their own prediction market on any possible
                              question. We see prediction markets not only as a
                              powerful tool for tackling challenging queries but
                              also as an enjoyable avenue for connecting with
                              friends, cultivating communities around shared
                              interests, facilitating the exchange of
                              information, and the opportunity of making a
                              profit in your trades.
                              <br />
                              <br />
                              Smarter Bet is the best prediction markets
                              platform because it offers the widest variety of
                              question topics contributed by a large community
                              while giving you the opportunity to make a profit.
                              This diversity allows you to leverage your
                              experience across multiple domains to showcase
                              your knowledge and earn money from successful
                              trades or unique and engaging questions.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree"
                            >
                              What type of questions can I use Smarter Bet to
                              answer?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThree"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Our markets offer a multiple of ways to address
                              your queries. Many of our users commonly engage
                              with a diverse range of markets.
                              <br />
                              <br />
                              Here are some of the top use cases for our
                              markets, with corresponding examples.
                              <br />
                              <br />
                              - News & current events, Natural Disasters
                              <br />
                              <br />
                              - Politics
                              <br />
                              <br />- Sports
                              <br></br>
                              - Economics, stocks, crypto
                              <br />
                              <br />
                              - Public figures
                              <br />
                              <br />
                              - Social issues, e.g. Climate Change, AI, Changes
                              of Policies
                              <br />
                              <br />
                              - Impactful causes / Research
                              <br />
                              <br />
                              - Science markets, predicting the usefulness of
                              potential trials and research papers
                              <br />
                              <br />
                              - Predicting if research is legitimate
                              <br />
                              <br />
                              - Effectiveness of current policies
                              <br />
                              <br />
                              - Artificial intelligence
                              <br />
                              <br />
                              - Charities
                              <br />
                              <br />- Debate topics
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFour"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFour"
                            >
                              How can one establish effective practices when
                              crafting markets?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFour"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Implementing effective strategies for creating
                              high-quality and impactful markets involves the
                              following steps:
                              <br />
                              <br />
                              1. Establish Clear Resolution Criteria: Define
                              explicit criteria for resolution to ensure users
                              understand how correct answers will be determined.
                              While some questions may have obvious resolution
                              conditions, others might be more ambiguous, making
                              it essential to set clear boundaries for each
                              possible answer.
                              <br />
                              <br />
                              2. Specify a Resolution Date: While markets have a
                              set closing date, it's crucial to clearly
                              communicate the intended resolution date. Include
                              an estimated time of resolution in the market
                              description to provide users with a timeframe for
                              outcomes.
                              <br />
                              <br />
                              3. Utilize the Rich Text Editor: While simplicity
                              can be effective, there are instances were using
                              the rich text editor to create a structured
                              description with context, background information,
                              and supporting images enhances market value.
                              Explore the formatting options provided by the
                              rich text editor for added flexibility.
                              <br />
                              <br />
                              4. Categorize Your Market: Place your market in
                              relevant categories (Groups) to facilitate user
                              filtering. Be selective and add it to specific
                              groups rather than overly broad ones.
                              Additionally, consider creating your own groups
                              and inviting friends or interested communities to
                              join them from other platforms.
                              <br />
                              <br />
                              5. Make Your Own Prediction: Kickstart engagement
                              by adding a comment with your own prediction,
                              supported by links and sources. This not only
                              initiates interaction but also encourages others
                              to share their views.
                              <br />
                              <br />
                              6. Share Your Market: Extend the reach of your
                              market by sharing it on other social networks that
                              host communities interested in your question.
                              <br />
                              <br />
                              7. Subsidize Your Market: Increase the
                              attractiveness of your market by adding mana to
                              the liquidity pool or setting up limit orders,
                              providing traders with greater profit potential.
                              <br />
                              <br />
                              8. Consider Unlisting Markets: Opt for unlisting
                              your market if you prefer it not to appear on
                              public feeds or if it violates guidelines. Even
                              unlisted markets can be discovered through groups
                              and your profile page.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFive"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFive"
                            >
                              How can I excel as a trader on Smarter Bet?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFive"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Profitable trades are readily available for those
                              who seize on the opportunity! A foundational step
                              is to grasp the meanings of various numbers and
                              comprehend how to monitor your portfolio.
                              <br />
                              <br />
                              Consider these additional tips to kickstart your
                              trading journey:
                              <br />
                              <br />
                              1. Identify Inaccurate Probabilities: Leverage
                              your expertise to pinpoint markets with
                              inaccuracies in probabilities. Bet strategically
                              to shift the odds in your favor, capitalizing on
                              your superior knowledge.
                              <br />
                              <br />
                              2. React to News: Smarter Bet markets are dynamic,
                              and ongoing events can significantly impact
                              probability. Seize opportunities by being among
                              the first to act as new information emerges,
                              potentially earning substantial profit.
                              <br />
                              <br />
                              3. Buy Low, Sell High: Similar to stock markets,
                              probabilities can be undervalued or overvalued.
                              Purchase shares at one end of the spectrum, and if
                              other users follow suit, the value of your shares
                              increases. Decide whether to wait for market
                              resolution or seize immediate profits to free up
                              money for better opportunities.
                              <br />
                              <br />
                              4. Create Innovative Answers: In free response
                              markets, creativity matters. The compelling nature
                              of answers can influence outcomes. Being an early
                              bettor on a creative answer often provides the
                              best odds.
                              <br />
                              <br />
                              5. Sort by Close Date: Prioritize markets closing
                              soon. While many may have converged, some present
                              opportunities for profit. Betting on markets with
                              imminent resolution reduces waiting time for
                              potential returns.
                              <br />
                              <br />
                              6. Sort by Newest: Explore markets with fewer
                              bets, offering the chance to capitalize on
                              inaccurate probabilities before convergence,
                              acquiring shares at a lower cost.
                              <br />
                              <br />
                              7. Follow Successful Traders: Boost your exposure
                              to winning strategies by observing top traders on
                              Smarter Bet. Learn from their actions, inquire
                              about successful predictions, and understand how
                              they strategically use mana for profitable
                              outcomes.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseSix"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseSix"
                            >
                              What is the process involved in determining market
                              outcomes?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseSix"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              The responsibility for determining a market
                              outcome lies with its creator! Smarter Bet trusts
                              users to handle the resolution process promptly
                              and accurately, and we typically refrain from
                              intervening unless absolutely necessary. It's
                              highly encouraged for creators to establish clear
                              resolution criteria beforehand.
                              <br />
                              <br />
                              Reputable market creators stand to gain followers,
                              positive reviews, and increased engagement with
                              their questions. For more details, refer to the
                              resolution section in the community guidelines.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseSeven"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseSeven"
                            >
                              What determines the pricing and probabilities
                              within the market?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseSeven"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Traders influence prices by buying or selling
                              shares tied to event outcomes. The more shares
                              purchased, the higher the price climbs.
                              <br />
                              <br />
                              The probability is derived from value implied by
                              the current market price. For instance, a 75%
                              market chance indicates traders believe there's a
                              75% likelihood of a YES outcome, and they're
                              willing to invest up to $75 for a potential $100
                              return if correct.
                              <br />
                              <br />
                              Before placing a bet, always double-check:
                              <br />
                              <br />
                              1. Bet Amount: The stake, representing the
                              potential loss on a position.
                              <br />
                              <br />
                              2. Payout if YES/NO: The potential winnings from a
                              position.
                              <br />
                              <br />
                              3. New probability: How your bet impacts the
                              market chance. Smarter Bet utilizes an automated
                              market maker to dynamically adjust prices based on
                              traders' buying and selling activities.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseEight"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseEight"
                            >
                              What are shares?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseEight"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              When you make a bet, you use money to acquire
                              shares of a specific outcome.
                              <br />
                              <br />
                              The quantity of shares you possess determines your
                              payout once the market is resolved. Each correct
                              share holds a value of $1 at resolution.
                              <br />
                              <br />
                              For example, in a YES/NO market, owning 300 YES
                              shares will result in an $300 payout if the market
                              resolves YES and M0 if it resolves NO.
                              <br />
                              <br />
                              Behind the scenes, we conduct a calculation to
                              establish the price of each share. The higher the
                              probability of the outcome, the more costly that
                              type of share becomes. For instance, if the market
                              probability is 3%, NO shares will be relatively
                              expensive (resulting in a less favorable payout),
                              while YES shares will be more affordable.
                              <br />
                              <br />
                              As the share prices fluctuate with the
                              probability, there's potential for a "buy low and
                              sell high" strategy, similar to trading on a stock
                              market. Selling shares typically yields less value
                              than waiting for a correct resolution but may
                              involve less risk.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseNine"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseNine"
                            >
                              How do payouts work?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseNine"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Smarter Bet leverages a fixed-pay out for its
                              markets. The potential profits for users are set
                              when they initiate their trade, determined by the
                              number of shares they acquire. This ensures that
                              subsequent bets by other users on the same market
                              do not impact the profit of an earlier trader upon
                              resolution.
                              <br />
                              <br />
                              Example: Owning 300 YES shares results in an $300
                              payout if the market resolves YES.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTen"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTen"
                            >
                              What is the Liquidity Pool?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTen"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Market liquidity refers to the available capital
                              for traders to engage in transactions. Markets
                              with higher liquidity are generally considered
                              more reliable.
                              <br />
                              <br />
                              A higher level of liquidity makes it challenging
                              to change the probability significantly. This
                              enables users to wager more money, influencing the
                              probability according to their preferences. The
                              increased liquidity not only expands profit
                              potential but also enhances the motivation to
                              participate, while simultaneously making it
                              tougher for an individual to temporarily skew the
                              probability with a large bet.
                              <br />
                              <br />
                              Every market initiates with a baseline liquidity
                              derived from the creation cost, with the entire
                              initial cost contributing to liquidity. Certain
                              market types incur higher costs due to the
                              necessity for increased liquidity for optimal
                              functionality.
                              <br />
                              <br />
                              The House automatically supplements liquidity to
                              markets as user engagement and trading activities
                              increase.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseEleven"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseEleven"
                            >
                              What is MetaMask?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseEleven"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              MetaMask is a digital wallet and browser extension
                              that allows you to store, send, and receive
                              cryptocurrencies, as well as interact with
                              decentralized applications (DApps) on the Ethereum
                              blockchain directly from your web browser.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwelve"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwelve"
                            >
                              Why do we use MetaMask?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwelve"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              We’ve partnered with MetaMask to operate on
                              blockchain technology for several reasons:
                              <br />
                              <br />
                              1. Secure Transactions: MetaMask provides a secure
                              way to make transactions using cryptocurrencies,
                              ensuring that payments and withdrawals are
                              conducted safely.
                              <br />
                              <br />
                              2. Blockchain Transparency: Blockchain technology
                              ensures transparency in transactions, allowing
                              users to verify the fairness of games and the
                              integrity of the platform.
                              <br />
                              <br />
                              3. Global Access: MetaMask allows users from
                              around the world to access and participate in
                              Smarter Bet’s markets without restrictions based
                              on geographical location or traditional banking
                              systems.
                              <br />
                              <br />
                              4. Ease of Use: MetaMask offers a user-friendly
                              interface that makes it easy for individuals to
                              store, send, and receive cryptocurrencies directly
                              from their web browser.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThirteen"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThirteen"
                            >
                              How to install MetaMask?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThirteen"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Visit MetaMask.io and select the "Download"
                              button. This will direct you to the appropriate
                              store to download the extension or app based on
                              your device and browser.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFourteen"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFourteen"
                            >
                              How do I get funds (digital currency) into
                              MetaMask?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFourteen"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              Within MetaMask, purchasing tokens is
                              straightforward: simply click on the "Buy" option.
                              Various networks and payment methods are
                              accessible, albeit availability may be contingent
                              upon your location. Additionally, you can initiate
                              a fund request from a friend through several
                              means: sending a payment request, presenting your
                              QR code in person, or sharing your public address.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFifteen"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFifteen"
                            >
                              How do I transfer my existing ETH and tokens to
                              MetaMask?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFifteen"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              To transfer ETH and tokens to your new MetaMask
                              account, copy your MetaMask public address and
                              proceed to your current wallet or exchange. From
                              there, send the funds to your new wallet address.
                              You can locate your MetaMask address under your
                              account name, displayed in the format
                              0x12r45...6HJ9.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseSixteen"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseSixteen"
                            >
                              How to deposit / receive / send?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseSixteen"
                            className="accordion-collapse collapse"
                          >
                            <div className={`${themeInput} accordion-body`}>
                              For mobile users, select the "Send" button on the
                              homepage or tap "Receive" to display your QR code,
                              copy your address, or request payment.
                              <br />
                              <br />
                              For desktop extension users, sending is also
                              possible by clicking the "Send" button. To receive
                              funds, copy your public address and paste it into
                              the sending platform, or share it with the sender.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
