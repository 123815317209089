import React, { useCallback, useEffect } from "react";
import LeftSidePanel from "../Components/LeftSidePanel";
import "./styles/EditProfile.css";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import EditProfileIcon from "../Components/EditProfileIcon";
import { useContext } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import {
  countryData,
  documentOptions,
  documentClassification,
} from "./SelectCountry";
import moment from "moment";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";

const EditProfile = () => {
  const [count, setCount] = useState(0);
  const [profilePicture, setProfilePicture] = useState(
    "./images/dummy-avatar-png.png"
  );
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [bio, setBio] = useState("");
  const [website, setWebsite] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [contact, setContact] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [bet, setBet] = useState(false);
  const [kycDocument, setKycDocument] = useState("");
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(false);
  const [approved, setApproved] = useState(false);
  const [pending, setPending] = useState(false);
  const [rejected, setRejected] = useState(false);
  const { themeClass, themeInput, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [type, setType] = useState("person");
  const [complyCubeMobile, setComplyCubeMobile] = useState("");
  const [complyCubePhone, setComplyCubePhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [clientId, setClientId] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [classification, setClassification] = useState("");
  const [issuingCountry, setIssuingCountry] = useState("");
  const [checkClientCreated, setCheckClientCreated] = useState("");
  const [checkDocumentCreated, setCheckDocumentCreated] = useState("");
  const [checkDocumentUploaded, setCheckDocumentUploaded] = useState("");
  const [documentId, setDocumentId] = useState("");
  // const [documentSideFront, setDocumentSideFront] = useState("front");
  // const [documentSideBack, setDocumentSideBack] = useState("back");
  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [base64DocumentForFront, setBase64DocumentForFront] = useState("");
  const [base64DocumentForBack, setBase64DocumentForBack] = useState("");
  const [kycFirstName, setKycFirstName] = useState("");
  const [kycLastName, setKycLastName] = useState("");
  const [kycStatus, setKnowKycStatus] = useState("");
  const currentDate = new Date();
  // const { localTheme, setLocalTheme } = useContext(ModeContext);
  // const [themeClass, setThemeClass] = useState('');
  // const [themeInput, setThemeInput] = useState('');

  const baseURL = "https://api.smarter-bet.com";
  const loginEmail = localStorage.getItem("email");
  const [localToken, setLocalToken] = useState(
    localStorage.getItem("Oddplus_Token")
  );

  const { key } = useParams();

  useEffect(() => {
    if (key === "kyc") {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [key]);

  //^^^^^^^^^^^^^^^GET PROFILE DETAIL API CALL^^^^^^^^^^^^^^^
  useEffect(() => {
    async function makeRequest() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.auth.PROFILE_DATA
        );

        const nameSplit = response.data.data.full_name;
        const name = nameSplit.split(" ");
        setFirstName(name[0]);
        setLastName(name[1]);
        setKycFirstName(name[0]);
        setKycLastName(name[1]);
        setBio(response?.data?.data?.bio ? response?.data?.data?.bio : "");
        setUserName(response.data.data.email);
        setWebsite(
          response?.data?.data?.website ? response?.data?.data?.website : ""
        );
        setTwitter(
          response?.data?.data?.twitter ? response?.data?.data?.twitter : ""
        );
        setDiscord(response.data.data.discord);
        setContact(response.data.data.mobile);
        setBet(
          response?.data?.data?.bet_warning
            ? response?.data?.data?.bet_warning
            : false
        );
        setCountryCode(response.data.data.country_code);
        setEmail(response.data.data.email);
        setProfilePicture(response.data.data.profile_picture);
        setComplyCubeMobile(response.data.data.mobile);
        // setComplyCubePhone(response.data.data.mobile);
      } catch (error) {}
    }
    makeRequest();
  }, []);

  // ^^^^^^^^^^^^^^^UPDATE THE USER DATA WHEN CLICKING SUBMIT BUTTON^^^^^^^^^^^^^^^^^^^^^^^^^^
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (
        firstName.length <= 0 ||
        lastName.length <= 0 ||
        userName.length <= 0
      ) {
        setError(true);
      } else {
        const fullName = firstName + " " + lastName;

        const formData = new FormData();

        // Replace `file` with the actual file object
        formData.append("profile_picture", image);
        formData.append("full_name", fullName);
        formData.append("username", userName);
        formData.append("bio", bio);
        formData.append("website", website);
        formData.append("twitter", twitter);
        formData.append("mobile", contact);
        formData.append("bet_warning", bet);
        formData.append("country_code", countryCode);

        try {
          // Make a POST request using Axios
          const response = await axiosInstance.patch(
            ApiEndpoints.auth.PROFILE,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Handle the response (e.g., show a success message)
          const notify = () => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          };
          notify();
          // navigate('/dashboard-profile');
        } catch (error) {
          // Handle the error (e.g., show an error message)
          const notify = () => {
            toast.error(error.response.data.error, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          };
          notify();
        }
      }
    },
    [
      bet,
      bio,
      contact,
      countryCode,
      firstName,
      lastName,
      localToken,
      profilePicture,
      twitter,
      userName,
      website,
    ]
  );

  // ^^^^^^^^^^^^^^^DELETE USER API CALL^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  const handleDeleteUser = async (e) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm Account Removal",
      message: "Are you sure you want to remove your account?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              // const URL = `${baseURL}/api/delete-user`;
              // const response = axios.delete(URL, {
              //     headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Bearer ${localToken}`
              //     },
              // });
              const response = await axiosInstance.delete(
                ApiEndpoints.auth.DeleteUser
              );

              localStorage.removeItem("email");
              localStorage.removeItem("token");
              localStorage.removeItem("loggedin");

              navigate("/");
            } catch (error) {
              const notify = () => {
                toast.error(error.response.data.message, {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              };
              notify();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  // DELETE USER API CALL WITH SIMPLE ALERT POPUP WINDOW (UNCOMMENT BELOW IF ABOVE CODE IS NOT WORKING)
  // const handleDeleteUser = async (e) => {
  //     e.preventDefault();
  //     if (window.confirm('Delete this Account?')) {
  //         try {
  //             const URL = 'http://13.40.94.92:3000/api/delete-user';
  //             const response = await axios.delete(URL, {
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     'Authorization': `Bearer ${localToken}`
  //                 },
  //             });
  //             localStorage.removeItem("email");
  //             localStorage.removeItem("token");
  //             localStorage.removeItem("loggedin");
  //             navigate("/");
  //         } catch (error) {
  //         }
  //     }
  // }

  // ^^^^^^^^^^^^^^^CREATE CLIENT API^^^^^^^^^^^^^^^^^^^
  const handleCreateClient = async (e) => {
    e?.preventDefault();

    const payload = {
      type: "person",
      email: email,
      // mobile: `+${countryCode} ${complyCubeMobile}`,
      // telephone: `+${countryCode} ${complyCubePhone}`,
      personDetails: {
        firstName: kycFirstName,
        lastName: kycLastName,
        dob: moment(selectedDate).format("YYYY-MM-DD"),
        nationality: selectedCountry,
      },
    };

    try {
      const response = await axiosInstance.post(
        ApiEndpoints.auth.CREATE_CLIENT,
        payload
      );

      // Saving the client id in localStorage
      setClientId(response.data.id);
      localStorage.setItem("clientId", response.data.id);

      setCheckClientCreated(response.status);
      setStep(step + 1);
      // if (response.status === 200) {
      //     const notify = () => {
      //         toast.success("Client created successfully", {
      //             position: "top-right",
      //             autoClose: 1000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //             theme: "light",
      //         });
      //     };
      //     notify();
      // }
    } catch (error) {
      // Handle the error (e.g., show an error message)
      const notify = () => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
      notify();
    }
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ^^^^^^^^^^^^^^^CREATE DOCUMENT API^^^^^^^^^^^^^^^^^^^
  const handleCreateDocument = async (e) => {
    e?.preventDefault();

    const payload = {
      clientId: clientId,
      type: selectedDocument,
      classification: classification,
      issuingCountry: issuingCountry,
    };

    try {
      const response = await axiosInstance.post(
        ApiEndpoints.auth.CREATE_DOCUMENT,
        payload
      );

      setCheckDocumentCreated(response.status);

      // Saving the document id in localStorage
      setDocumentId(response.data.id);
      localStorage.setItem("documentId", response.data.id);

      setStep(step + 1);
      // if (response.status === 200) {
      //     const notify = () => {
      //         toast.success("Document created successfully", {
      //             position: "top-right",
      //             autoClose: 1000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //             theme: "light",
      //         });
      //     };
      //     notify();
      // }
    } catch (error) {
      // Handle the error (e.g., show an error message)
      const notify = () => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
      notify();
    }
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ^^^^^^^^^^^^^^^UPLOAD DOCUMENT API^^^^^^^^^^^^^^^^^^^
  const handleUploadDocument = async (e) => {
    e?.preventDefault();

    const payload = {
      documentId: documentId,
      fileName: fileName1,
      docs: [
        {
          documentSide: "front",
          data: base64DocumentForFront,
        },
        {
          documentSide: "back",
          data: base64DocumentForBack,
        },
      ],
    };

    try {
      const response = await axiosInstance.post(
        ApiEndpoints.auth.UPLOAD_IMAGE,
        payload
      );

      setCheckDocumentUploaded(response.status);
      setStep(step + 1);
      // if (response.status === 200) {
      //     const notify = () => {
      //         toast.success("Document uploaded successfully", {
      //             position: "top-right",
      //             autoClose: 1000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //             theme: "light",
      //         });
      //     };
      //     notify();
      // }
    } catch (error) {
      // Handle the error (e.g., show an error message)
      const notify = () => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
      notify();
    }
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ^^^^^^^^^^^^^^^CREATE DOCUMENT CHECK^^^^^^^^^^^^^^^^
  const handleCreateDocumentCheck = async (e) => {
    e?.preventDefault();

    const payload = {
      clientId: clientId,
      documentId: documentId,
      type: "document_check",
    };

    try {
      const URL = `${baseURL}/api/create-check`;
      const response = await axios.post(URL, payload, {
        headers: {
          Authorization: `Bearer ${localToken}`,
          "Content-Type": "application/json",
        },
      });

      setIsCreateClientModalOpen(false);
      window.location.reload();

      // if (response.status === 200) {
      const notify = () => {
        toast.success("Document Check Performed", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
      notify();
      // }
    } catch (error) {
      // Handle the error (e.g., show an error message)
      const notify = () => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
      notify();
    }
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ^^^^^^^^^^^^^^^PERFORM DOCUMENT CHECK^^^^^^^^^^^^^^^^
  useEffect(() => {
    async function getStatus() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.auth.CHECK_OUTCOME
        );
        setKnowKycStatus(response.data.status);
      } catch (error) {}
    }
    getStatus();
  }, []);

  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const onImageChange = (event) => {
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
  };

  useEffect(
    (e) => {
      if (bio === undefined) setCount(0);
      else setCount(bio.length);
    },
    [bio]
  );

  // Create Client Modal box
  const [isCreateClientModalOpen, setIsCreateClientModalOpen] = useState(false);
  const handleOpenCreateClientModal = () => {
    setIsCreateClientModalOpen(true);
    setClientId(localStorage.getItem("clientId"));
  };

  const handleCreateClientModal = () => {
    setIsCreateClientModalOpen(false);
    // localStorage.removeItem('clientId');
    // localStorage.removeItem('documentId');
  };

  // Select Date of Birth
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Stepper
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    step1Data: "",
    step2Data: "",
    step3Data: "",
    step4Data: "",
  });

  // const handleNext = () => {
  //     setStep(step + 1);
  // };

  const handlePrev = () => {
    setStep(step - 1);
  };

  // const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({
  //         ...formData,
  //         [name]: value,
  //     });
  // };

  // Handle Country Change
  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  return (
    <>
      {" "}
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="mt-4">
              <div className="container">
                {/* ---------------------Column 1--------------------- */}

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-7">
                  <nav className="navbar navbar-expand-sm ">
                    <Link to="/dashboard-profile">
                      <button
                        className="mr-4"
                        style={{
                          border: "none",
                          width: 35,
                          height: 35,
                          borderRadius: 30,
                        }}
                      >
                        <img src="../images/icon_back.svg" alt="" />
                      </button>
                    </Link>
                    <div
                      className={`${themeClass} edit-profile-text navbar-brand d-flex align-items-center justify-content-start`}
                    >
                      Edit Profile
                    </div>
                  </nav>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 personal-info">
                        <form
                          onSubmit={handleSubmit}
                          className="form-horizontal"
                        >
                          <div className="col-md-10 mb-3">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                  <div className="image-upload">
                                    <label htmlFor="file-input">
                                      <div
                                        style={{
                                          position: "relative",
                                          minWidth: "130px",
                                          minHeight: "130px",
                                        }}
                                      >
                                        <img
                                          src={
                                            profilePicture ||
                                            "../images/dummy-avatar-png.png"
                                          }
                                          className="avatar img-circle"
                                          alt="avatar"
                                        />
                                        <img
                                          src="../images/edit.svg"
                                          className="edit-pencil"
                                          alt="edit"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </div>
                                    </label>
                                    <input
                                      id="file-input"
                                      type="file"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={(e) => onImageChange(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ paddingInline: "15px" }}
                          >
                            <div className="col-md-5 mb-3">
                              <div className="form-outline mt-3">
                                <img
                                  className="mr-4"
                                  src="../images/Vector(9).svg"
                                  alt=""
                                />
                                <label
                                  className="label-color"
                                  htmlFor="form3Example1m"
                                >
                                  First Name
                                </label>
                                <input
                                  style={{
                                    outline: 0,
                                    borderWidth: "0 0 1px",
                                  }}
                                  type="text"
                                  id="form3Example1m"
                                  className={` ${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                {error && firstName.length <= 0 ? (
                                  <label className="show-error-label">
                                    First name can't be empty
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-5 mb-3">
                              <div className="form-outline mt-3">
                                <img
                                  className="mr-4"
                                  src="../images/Vector(9).svg"
                                  alt=""
                                />
                                <label
                                  className="label-color"
                                  htmlFor="form3Example1m"
                                >
                                  Last Name
                                </label>
                                <input
                                  style={{
                                    outline: 0,
                                    borderWidth: "0 0 1px",
                                  }}
                                  type="text"
                                  id="form3Example1m"
                                  className={` ${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                                {error && lastName.length <= 0 ? (
                                  <label className="show-error-label">
                                    Last name can't be empty
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4"
                                src="../images/Vector(9).svg"
                                alt=""
                              />
                              <label
                                className="label-color"
                                htmlFor="form3Example1m"
                              >
                                User Name
                              </label>
                              <input
                                style={{ outline: 0, borderWidth: "0 0 1px" }}
                                type="text"
                                id="form3Example1m"
                                className={`${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                readOnly
                              />
                              {error && userName.length <= 0 ? (
                                <label className="show-error-label">
                                  User name can't be empty
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4"
                                src="../images/icon_bio.svg"
                                alt=""
                              />
                              <label
                                className="label-color"
                                htmlFor="exampleFormControlTextarea1"
                              >
                                Bio
                              </label>
                              <textarea
                                className={`${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                id="exampleFormControlTextarea1"
                                rows="3"
                                maxLength="1200"
                                style={{ outline: 0, borderWidth: "0 0 1px" }}
                                value={bio == "undefined" ? setBio("") : bio}
                                onChange={(e) => {
                                  setBio(e.target.value);
                                }}
                              ></textarea>
                              <div className="mt-1 d-flex align-items-center justify-content-end">
                                <p>{count}/1200</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4"
                                src="../images/icon_website.svg"
                                alt=""
                              />
                              <label
                                className="label-color"
                                htmlFor="form3Example1m"
                              >
                                Website
                              </label>
                              <input
                                style={{ outline: 0, borderWidth: "0 0 1px" }}
                                type="text"
                                id="form3Example1m"
                                className={`${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                value={
                                  website == "undefined"
                                    ? setWebsite("")
                                    : website
                                }
                                onChange={(e) => setWebsite(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4"
                                src="../images/twitter.svg"
                                alt="twitter"
                                style={{ width: "22px", height: "22px" }}
                                fill="grey"
                              />
                              <label
                                className="label-color"
                                htmlFor="form3Example1m"
                              >
                                Twitter
                              </label>
                              <input
                                style={{ outline: 0, borderWidth: "0 0 1px" }}
                                type="text"
                                id="form3Example1m"
                                className={`${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                value={
                                  twitter == "undefined"
                                    ? setTwitter("")
                                    : twitter
                                }
                                onChange={(e) => setTwitter(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4 contact-icon"
                                src="../images/phone.svg"
                                alt=""
                              />
                              <label
                                className="label-color"
                                htmlFor="form3Example1m"
                              >
                                Contact Number
                              </label>
                              <div className="contact-input-wrapper border-bottom">
                                {/* <div className='country-code-wrapper'>{"+" + countryCode}</div> */}
                                <input
                                  style={{ outline: 0, borderWidth: "0 0 0" }}
                                  type="text"
                                  id="form3Example1m"
                                  className={`${themeInput} form-control form-control-sm shadow-none`}
                                  pattern="[0-9]*"
                                  value={contact}
                                  onChange={(e) =>
                                    setContact((v) =>
                                      e.target.validity.valid
                                        ? e.target.value
                                        : ""
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="mr-4"
                                src="../images/icon_email.svg"
                                alt=""
                              />
                              <label
                                className="label-color"
                                htmlFor="form3Example1m"
                              >
                                Email
                              </label>
                              <input
                                style={{
                                  outline: 0,
                                  borderWidth: "0 0 1px",
                                  backgroundColor: "white",
                                }}
                                type="email"
                                id="form3Example1m"
                                className={`${themeInput} input-spacer form-control form-control-sm shadow-none`}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                readOnly
                                placeholder="johndoe@gmail.com"
                              />
                              {/* {error && email.length <= 0 ?
                                                <label className='show-error-label'>Email can't be empty</label> : ""} */}
                            </div>
                          </div>
                          {/* <div className="col-md-10 mb-3">
                                        <div className="form-outline mt-2">
                                            <img className='mr-4' src='./images/icon_bet_warning.svg' alt='' />
                                            <label className="label-color" htmlFor="form3Example1m">Bet Warning</label>
                                            <div className="bet-spacer custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="darkSwitch"
                                                    checked={bet}
                                                    onChange={(e) => setBet(e.target.checked)}
                                                />
                                                <label className="custom-control-label" htmlFor="darkSwitch" />
                                            </div>
                                        </div>
                                    </div> */}
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2">
                              <img
                                className="document-icon mr-4"
                                src="../images/kyc.png"
                                alt="kyc"
                              />
                              <label
                                className="label-color mb-3"
                                htmlFor="form3Example1m"
                              >
                                Upload Your Document Here
                              </label>
                              <div className="d-flex align-items-center gap-3">
                                <div
                                  className="create-client"
                                  onClick={handleOpenCreateClientModal}
                                  style={{ marginLeft: "50px" }}
                                >
                                  {" "}
                                  KYC{" "}
                                </div>

                                {/* Portfolio Modal start*/}
                                {isCreateClientModalOpen && (
                                  <div className="modal">
                                    <div
                                      className={`${
                                        themeClass === "dark"
                                          ? "text-light modal-content bg-dark"
                                          : "modal-content"
                                      }`}
                                    >
                                      <span
                                        className="modal-close"
                                        onClick={handleCreateClientModal}
                                      >
                                        <img
                                          src="../images/cross.png"
                                          alt="close"
                                          style={{
                                            width: "40px",
                                            margin: "5px",
                                          }}
                                        />
                                      </span>
                                      {/* <div className="d-flex align-items-center justify-content-center flex-column gap-3 mb-4">
                                                            <img className='complycube' src="/images/complycube.svg" alt="complycube" />
                                                        </div> */}

                                      <div>
                                        <Form>
                                          {step === 1 && (
                                            <div>
                                              <div className="d-flex align-items-center justify-content-center flex-column gap-3 mb-4">
                                                <div className="create-client-header">
                                                  Create Client
                                                </div>
                                                <Form.Label className="step-label">
                                                  Step 1/4
                                                </Form.Label>
                                              </div>
                                              <Form.Group>
                                                <div
                                                  className="form-outline mt-3"
                                                  style={{
                                                    paddingInline: "10%",
                                                  }}
                                                >
                                                  {/* <div className="d-flex gap-3">
                                                                                    <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Type<span style={{ color: "red" }}>*</span></label>
                                                                                    <input
                                                                                        style={{ outline: 0, borderWidth: '0 0 1px', width: '100%' }}
                                                                                        type="text" id="form3Example1m"
                                                                                        className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                                                        value="person"
                                                                                        onChange={(e) => setType(e.target.value)}
                                                                                        readOnly
                                                                                    />
                                                                                </div> */}
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Email:
                                                      <span
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      style={{
                                                        outline: 0,
                                                        borderWidth: "0 0 1px",
                                                        width: "100%",
                                                      }}
                                                      type="text"
                                                      id="form3Example1m"
                                                      className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                      value={email}
                                                      // onChange={(e) => setType(e.target.value)}
                                                      readOnly
                                                    />
                                                  </div>
                                                  {/* <div className="d-flex align-items-center gap-3 mt-4">
                                                                                    <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Mobile:</label>
                                                                                    <div className='d-flex align-items-end '>
                                                                                        <div className='country-code-wrapper'>{"(+" + countryCode + ")"}</div>
                                                                                        <input
                                                                                            style={{ outline: 0, borderWidth: '0 0 1px', width: '100%' }}
                                                                                            type="text" id="form3Example1m"
                                                                                            className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                                                            value={complyCubeMobile}
                                                                                            pattern="[0-9]*"
                                                                                            onChange={(e) => setComplyCubeMobile((v) => (e.target.validity.valid ? e.target.value : ""))}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                                  {/* <div className="d-flex gap-3 mt-4">
                                                                                    <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Telephone:</label>
                                                                                    <div className='d-flex align-items-end '>
                                                                                        <div className='country-code-wrapper'>{"(+" + countryCode + ")"}</div>
                                                                                        <input
                                                                                            style={{ outline: 0, borderWidth: '0 0 1px', width: '100%' }}
                                                                                            type="text" id="form3Example1m"
                                                                                            className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                                                            value={complyCubePhone}
                                                                                            pattern="[0-9]*"
                                                                                            onChange={(e) => setComplyCubePhone((v) => (e.target.validity.valid ? e.target.value : ""))}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                                  <div className="d-flex gap-3 mt-4">
                                                    {/* <label className="label-color mb-0" htmlFor="form3Example1m">Personal details:</label> */}
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      First Name:
                                                    </label>
                                                    <input
                                                      style={{
                                                        outline: 0,
                                                        borderWidth: "0 0 1px",
                                                        width: "100%",
                                                      }}
                                                      type="text"
                                                      id="form3Example1m"
                                                      className={` ${themeInput} form-control form-control-sm shadow-none text-capitalize`}
                                                      value={kycFirstName}
                                                      onChange={(e) =>
                                                        setKycFirstName(
                                                          e.target.value
                                                        )
                                                      }
                                                      // readOnly
                                                    />
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Last Name:
                                                    </label>
                                                    <input
                                                      style={{
                                                        outline: 0,
                                                        borderWidth: "0 0 1px",
                                                        width: "100%",
                                                      }}
                                                      type="text"
                                                      id="form3Example1m"
                                                      className={` ${themeInput} form-control form-control-sm shadow-none text-capitalize`}
                                                      value={kycLastName}
                                                      onChange={(e) =>
                                                        setKycLastName(
                                                          e.target.value
                                                        )
                                                      }
                                                      // readOnly
                                                    />
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Date of Birth:
                                                      <span
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    </label>
                                                    <DatePicker
                                                      selected={selectedDate}
                                                      onChange={
                                                        handleDateChange
                                                      }
                                                      dateFormat="yyyy-MM-dd" // Customize date format
                                                      className={`${themeInput} form-control ${
                                                        !selectedDate
                                                          ? "red-outline"
                                                          : ""
                                                      }`}
                                                      // Apply Bootstrap form control class
                                                      showYearDropdown={true} // Enable year dropdown
                                                      yearDropdownItemNumber={
                                                        100
                                                      } // Show 5 years at a time (you can change this number)
                                                      placeholderText="Select a date"
                                                      maxDate={currentDate}
                                                      scrollableYearDropdown
                                                      nextYearButtonLabel="A"
                                                    />
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Nationality:{" "}
                                                    </label>
                                                    <select
                                                      className={`${themeInput} form-select form-select-md mb-3`}
                                                      aria-label=".form-select-md example"
                                                      value={selectedCountry}
                                                      onChange={handleChange}
                                                    >
                                                      <option value="" disabled>
                                                        Select Country
                                                      </option>
                                                      {countryData.map(
                                                        (country, index) => (
                                                          <option
                                                            key={index}
                                                            value={country.code}
                                                          >
                                                            {`${country.name} - ${country.code}`}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                                                    <Button
                                                      variant="primary"
                                                      className="ms-2 mr-4"
                                                      onClick={() => {
                                                        handleCreateClient();
                                                      }}
                                                      style={{
                                                        backgroundColor:
                                                          "#069514",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Submit/Next
                                                    </Button>
                                                    {/* {
                                                                                        checkClientCreated === 200 &&
                                                                                        <Button variant="primary" onClick={handleNext} style={{ backgroundColor: "#069514", border: "none" }}>
                                                                                            Next
                                                                                        </Button>
                                                                                    } */}
                                                  </div>
                                                </div>
                                              </Form.Group>
                                            </div>
                                          )}

                                          {step === 2 && (
                                            <div>
                                              <div className="d-flex align-items-center justify-content-center flex-column gap-3 mb-4">
                                                <div className="create-client-header">
                                                  Create Document
                                                </div>
                                                <Form.Label className="step-label">
                                                  Step 2/4
                                                </Form.Label>
                                              </div>
                                              <Form.Group>
                                                {/* <Form.Control
                                                                                type="text"
                                                                                name="step2Data"
                                                                                value={formData.step2Data}
                                                                                onChange={handleInputChange}
                                                                            /> */}
                                                <div
                                                  className="form-outline mt-3"
                                                  style={{
                                                    paddingInline: "10%",
                                                  }}
                                                >
                                                  {/* <div className="d-flex gap-3">
                                                                                        <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Client Id:<span style={{ color: "red" }}>*</span></label>
                                                                                        <input
                                                                                            style={{ outline: 0, borderWidth: '0 0 0px', width: '100%' }}
                                                                                            type="text" id="form3Example1m"
                                                                                            className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                                                            value={clientId}
                                                                                            // onChange={(e) => setType(e.target.value)}
                                                                                            readOnly
                                                                                        />
                                                                                    </div> */}
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Document Type:
                                                      <span
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    </label>
                                                    <select
                                                      className={`${themeInput} ${
                                                        !selectedDocument
                                                          ? "red-outline"
                                                          : ""
                                                      } form-select form-select-md mb-3`}
                                                      value={selectedDocument}
                                                      onChange={(e) =>
                                                        setSelectedDocument(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="" disabled>
                                                        Select a Document
                                                      </option>
                                                      {documentOptions.map(
                                                        (option) => (
                                                          <option
                                                            key={option.value}
                                                            value={option.value}
                                                          >
                                                            {option.name}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Classification:
                                                    </label>
                                                    <select
                                                      className={`${themeInput} form-select form-select-md mb-3`}
                                                      value={classification}
                                                      onChange={(e) =>
                                                        setClassification(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="" disabled>
                                                        Select a Document
                                                      </option>
                                                      {documentClassification.map(
                                                        (option) => (
                                                          <option
                                                            key={option.value}
                                                            value={option.value}
                                                          >
                                                            {option.name}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="d-flex gap-3 mt-4">
                                                    <label
                                                      className="label-color mb-0 modal-label-width"
                                                      htmlFor="form3Example1m"
                                                    >
                                                      Issuing Country:{" "}
                                                    </label>
                                                    <select
                                                      className={`${themeInput} form-select form-select-md mb-3`}
                                                      aria-label=".form-select-md example"
                                                      value={issuingCountry}
                                                      onChange={(e) =>
                                                        setIssuingCountry(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="" disabled>
                                                        Select Country
                                                      </option>
                                                      {countryData.map(
                                                        (country, index) => (
                                                          <option
                                                            key={index}
                                                            value={country.code}
                                                          >
                                                            {`${country.name} - ${country.code}`}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>

                                                  <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                                                    <Button
                                                      className="mr-4"
                                                      variant="success"
                                                      onClick={handlePrev}
                                                      style={{
                                                        backgroundColor:
                                                          "#069514",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Previous
                                                    </Button>

                                                    <Button
                                                      variant="primary"
                                                      className="mr-4"
                                                      onClick={() => {
                                                        handleCreateDocument();
                                                      }}
                                                      style={{
                                                        backgroundColor:
                                                          "#069514",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Submit/Next
                                                    </Button>

                                                    {/* {
                                                                                        checkDocumentCreated === 200 &&
                                                                                        <Button variant="primary" onClick={handleNext} style={{ backgroundColor: "#069514", border: "none" }}>
                                                                                            Next
                                                                                        </Button>
                                                                                    } */}
                                                  </div>
                                                </div>
                                              </Form.Group>
                                            </div>
                                          )}

                                          {step === 3 && (
                                            <div>
                                              <div className="d-flex align-items-center justify-content-center flex-column gap-3 mb-4">
                                                <div className="create-client-header mt-4">
                                                  Upload Document for
                                                  Verification
                                                </div>
                                                <Form.Label className="step-label">
                                                  Step 3/4
                                                </Form.Label>
                                              </div>
                                              <Form.Group>
                                                <div className="d-flex gap-3 m-4">
                                                  <label
                                                    className="label-color mb-0 modal-label-width"
                                                    htmlFor="form3Example1m"
                                                  >
                                                    Document Id:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      outline: 0,
                                                      borderWidth: "0 0 0px",
                                                      width: "100%",
                                                    }}
                                                    type="text"
                                                    id="form3Example1m"
                                                    className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                    value={documentId}
                                                    // onChange={(e) => setType(e.target.value)}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="d-flex gap-3 m-4 d-none">
                                                  <label
                                                    className="label-color mb-0 modal-label-width"
                                                    htmlFor="form3Example1m"
                                                  >
                                                    File Name:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      outline: 0,
                                                      borderWidth: "0 0 1px",
                                                      width: "100%",
                                                    }}
                                                    type="text"
                                                    id="form3Example1m"
                                                    className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                    value={fileName1}
                                                    // onChange={(e) => setType(e.target.value)}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="d-flex gap-3 m-4">
                                                  {/* <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Document Front Side:<span style={{ color: "red" }}>*</span></label> */}
                                                  {/* <h5>Front</h5> */}
                                                  {/* <select
                                                                                        className={`${themeInput} ${!documentSide ? 'red-outline' : ''} form-select form-select-md mb-3`}
                                                                                        aria-label=".form-select-md example"
                                                                                        value={documentSide} // Update this to documentSide
                                                                                        onChange={(e) => {
                                                                                            setDocumentSide(e.target.value);
                                                                                        }}
                                                                                    >
                                                                                        <option value="" disabled>Select Document Side</option>
                                                                                        <option value="front">Front</option>
                                                                                        <option value="back">Back</option>
                                                                                    </select> */}
                                                </div>
                                                <div className="d-flex gap-3 m-4">
                                                  <label
                                                    className="label-color mb-0  "
                                                    htmlFor="form3Example1m"
                                                  >
                                                    Document Front Side:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <div>
                                                    <input
                                                      className="ml-1"
                                                      type="file"
                                                      accept="application/pdf"
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      // value={kycDocument}
                                                      onChange={(e) => {
                                                        // setKycDocument(e.target.value);

                                                        const selectedFile =
                                                          e.target.files[0]; // Get the first selected file
                                                        if (selectedFile) {
                                                          const fName =
                                                            selectedFile.name; // Extract the file name
                                                          setFileName1(fName); // Store the file name in your state

                                                          const reader =
                                                            new FileReader();

                                                          reader.onload = (
                                                            event
                                                          ) => {
                                                            const base64String =
                                                              event.target
                                                                .result; // Get the base64-encoded content
                                                            setBase64DocumentForFront(
                                                              base64String
                                                            ); // Store the base64 content in your state
                                                          };
                                                          // Read the file as a data URL (base64)
                                                          reader.readAsDataURL(
                                                            selectedFile
                                                          );
                                                        }
                                                      }}
                                                    />
                                                    <div className="ml-4 allowed-file-types">
                                                      The file format must end
                                                      with.pdf and max file size
                                                      3 MB.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex gap-3 m-4">
                                                  {/* <label className="label-color mb-0 modal-label-width" htmlFor="form3Example1m">Document Back Side:<span style={{ color: "red" }}>*</span></label> */}
                                                  {/* <h5>Back</h5> */}
                                                </div>
                                                <div className="d-flex gap-3 m-4 d-none">
                                                  <label
                                                    className="label-color mb-0 modal-label-width"
                                                    htmlFor="form3Example1m"
                                                  >
                                                    File Name:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      outline: 0,
                                                      borderWidth: "0 0 1px",
                                                      width: "100%",
                                                    }}
                                                    type="text"
                                                    id="form3Example1m"
                                                    className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                    value={fileName2}
                                                    // onChange={(e) => setType(e.target.value)}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="d-flex gap-3 m-4">
                                                  <label
                                                    className="label-color mb-0  "
                                                    htmlFor="form3Example1m"
                                                  >
                                                    Document Back Side:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <div>
                                                    <input
                                                      className="ml-1"
                                                      type="file"
                                                      accept="application/pdf"
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      // value={kycDocument}
                                                      onChange={(e) => {
                                                        // setKycDocument(e.target.value);

                                                        const selectedFile =
                                                          e.target.files[0]; // Get the first selected file
                                                        if (selectedFile) {
                                                          const fName =
                                                            selectedFile.name; // Extract the file name
                                                          setFileName2(fName); // Store the file name in your state

                                                          const reader =
                                                            new FileReader();

                                                          reader.onload = (
                                                            event
                                                          ) => {
                                                            const base64String =
                                                              event.target
                                                                .result; // Get the base64-encoded content
                                                            setBase64DocumentForBack(
                                                              base64String
                                                            ); // Store the base64 content in your state
                                                          };
                                                          // Read the file as a data URL (base64)
                                                          reader.readAsDataURL(
                                                            selectedFile
                                                          );
                                                        }
                                                      }}
                                                    />
                                                    <div className="ml-4 allowed-file-types">
                                                      The file format must end
                                                      with.pdf and max file size
                                                      3 MB
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
                                                  <Button
                                                    className="mr-4"
                                                    variant="success"
                                                    onClick={handlePrev}
                                                    style={{
                                                      backgroundColor:
                                                        "#069514",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Previous
                                                  </Button>

                                                  <Button
                                                    variant="primary"
                                                    className="ms-2"
                                                    onClick={() => {
                                                      handleUploadDocument();
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#069514",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Submit/Next
                                                  </Button>
                                                  {/* {
                                                                                    checkDocumentUploaded === 200 &&
                                                                                    <Button
                                                                                        className="ml-4"
                                                                                        variant="primary"
                                                                                        onClick={handleNext}
                                                                                        style={{ backgroundColor: "#069514", border: "none" }}>
                                                                                        Next
                                                                                    </Button>
                                                                                } */}
                                                </div>
                                              </Form.Group>
                                            </div>
                                          )}

                                          {step === 4 && (
                                            <div>
                                              <div className="d-flex align-items-center justify-content-center flex-column gap-3 mb-4">
                                                <div className="create-client-header">
                                                  Create a Document Check
                                                </div>
                                                <Form.Label className="step-label">
                                                  Step 4/4
                                                </Form.Label>
                                              </div>
                                              <Form.Group>
                                                <div className="d-flex gap-3 m-4">
                                                  <label
                                                    className="label-color mb-0 modal-label-width"
                                                    htmlFor="form3Example1m"
                                                  >
                                                    Document Id:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      outline: 0,
                                                      borderWidth: "0 0 0px",
                                                      width: "100%",
                                                    }}
                                                    type="text"
                                                    id="form3Example1m"
                                                    className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                    value={documentId}
                                                    // onChange={(e) => setType(e.target.value)}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="d-flex gap-3 m-4">
                                                  <label
                                                    className="label-color mb-0 modal-label-width"
                                                    htmlFor="form3Example1m"
                                                  >
                                                    Client Id:
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      outline: 0,
                                                      borderWidth: "0 0 0px",
                                                      width: "100%",
                                                    }}
                                                    type="text"
                                                    id="form3Example1m"
                                                    className={` ${themeInput} form-control form-control-sm shadow-none`}
                                                    value={clientId}
                                                    // onChange={(e) => setType(e.target.value)}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
                                                  <Button
                                                    className="mr-4"
                                                    variant="success"
                                                    onClick={handlePrev}
                                                    style={{
                                                      backgroundColor:
                                                        "#069514",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Previous
                                                  </Button>

                                                  <Button
                                                    variant="primary"
                                                    className="ms-2"
                                                    onClick={() => {
                                                      handleCreateDocumentCheck();
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#069514",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Submit
                                                  </Button>
                                                </div>
                                              </Form.Group>
                                            </div>
                                          )}

                                          {/* <div className="mt-4">
                                                                    {step > 1 && (
                                                                        <Button variant="primary" onClick={handlePrev} style={{ backgroundColor: "#069514", border: "none" }}>
                                                                            Previous
                                                                        </Button>
                                                                    )}

                                                                    {step < 3 && (
                                                                        <Button variant="primary"
                                                                            className="ms-2"
                                                                            onClick={() => {
                                                                                if (checkClientCreated === 200) {
                                                                                    handleNext();
                                                                                }
                                                                            }}
                                                                            style={{ backgroundColor: "#069514", border: "none" }}>
                                                                            Next
                                                                        </Button>
                                                                    )}

                                                                    {step === 3 && (
                                                                        <Button variant="success" className="ms-2" onClick={() =>>
                                                                            Submit
                                                                        </Button>
                                                                    )}
                                                                </div> */}
                                        </Form>
                                        <div
                                          className="d-flex align-items-center justify-content-center mt-4"
                                          style={{
                                            fontSize: "14px",
                                            color: "grey",
                                          }}
                                        >
                                          <span>Powered by ComplyCube </span>
                                          <img
                                            className="complycube-img"
                                            src="../images/complycube.svg"
                                            alt="complycube"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* Portfolio Modal end*/}

                                <div className="d-flex align-items-center justify-content-start gap-2 mt-2 ">
                                  {kycStatus == "complete" ? (
                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                      <img
                                        className="status-icon"
                                        src="../images/checked.svg"
                                        alt="approved"
                                      />
                                      <p>Approved</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {kycStatus == "pending" ? (
                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                      <img
                                        className="status-icon"
                                        src="../images/progress.svg"
                                        alt="pending"
                                      />
                                      <p>Pending</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {kycStatus == "failed" ? (
                                    <div className="d-flex align-items-center justify-content-start gap-2">
                                      <img
                                        className="status-icon"
                                        src="../images/reject.svg"
                                        alt="rejected"
                                      />
                                      <p>Rejected</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 mb-3">
                            <div className="form-outline mt-2  d-flex justify-content-start align-items-center">
                              <Link
                                to=""
                                onClick={handleDeleteUser}
                                className="permanent-delete-btn"
                              >
                                <img
                                  src="../images/icon_delete.svg"
                                  alt="delete"
                                />
                                <p>Permanently delete this account</p>
                              </Link>
                            </div>
                          </div>

                          <div className="update-btn-wrapper col-md-10 d-flex align-items-center justify-content-center form-group">
                            <button className="update-btn btn">Update</button>
                          </div>
                          <ToastContainer />
                        </form>
                        <section style={{ paddingBlock: "30px" }}></section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
