import React from "react";
import LeftSidePanel from "../Components/LeftSidePanel";
import RightSidePanel from "../Components/RightSidePanel";
import { Link, useParams } from "react-router-dom";
import EditProfileIcon from "../Components/EditProfileIcon";
import "./styles/ProfilePage.css";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import { ResponsiveContainer, LineChart, Line } from "recharts";
import Select from "react-select";
import axios from "axios";
import { ApiEndpoints } from "../network/config/endpoints";
import { axiosInstance } from "../network/config/adapter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../hooks/useAuth";
import moment from "moment";
import { formatDistanceToNow } from "date-fns";
const options = [
  { value: "active", label: "Active" },
  { value: "limit orders", label: "Limit orders" },
  { value: "sold", label: "Sold" },
  { value: "closed", label: "Closed" },
  { value: "resolved", label: "Resolved" },
  { value: "all", label: "All" },
];

const OtherProfilePage = () => {
  const { themeClass, themeInput } = useContext(ThemeContext);
  const [active, setActive] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const baseURL = "http://13.40.94.92:3000";
  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [followerCount, setFollowerCount] = useState("0");
  const [followingCount, setFollowingCount] = useState("0");
  const { userid, fetchUserdata } = useAuth();
  const [portFolio, setPortFolio] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedGrpOption, setSelectedGrpOption] = useState("");
  const [grpOption, setGrpOption] = useState([]);
  const [allComments, setAllComments] = useState([]);

  const handleSetActive = (e, newValue) => {
    e?.preventDefault();
    setActive(newValue);
  };

  const pdata = [
    {
      name: "Bitcoin",
      high: 13,
      low: 10,
    },
    {
      name: "Tether",
      high: 12,
      low: 9,
    },
    {
      name: "Matic",
      high: 11,
      low: 12,
    },
    {
      name: "Ethereum",
      high: 20,
      low: 9,
    },
    {
      name: "Cardano",
      high: 15,
      low: 14,
    },
  ];

  // -------------------API call for get others profile detail^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  useEffect(() => {
    async function getUserDetails() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.auth.userDetails(_id)
        );
        setUsername(response.data.data.username);
        setFirstName(response.data.data.firstName);
        setLastName(response.data.data.lastName);
        setEmail(response.data.data.email);
        setProfilePicture(response.data.data.profile_picture);
      } catch (error) { }
    }
    getUserDetails();
    fetchUserdata();
    makeRequest();
    getAllComment();
  }, []);

  useEffect(() => {
    async function getFollowers() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.follow.getFollowers(_id)
        );
        setFollowers(response.data.followers);
        setFollowerCount(response.data.followerCount);
      } catch (error) { }
    }
    getFollowers();
  }, []);

  useEffect(() => {
    async function getFollowing() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.follow.getFollowing(_id)
        );
        setFollowingCount(response.data.followerCount);
      } catch (error) { }
    }
    getFollowing();
  }, []);
  useEffect(() => {
    async function getPortfolio() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.betting.Portfolio(_id)
        );
        setPortFolio(response.data.user);
      } catch (error) { }
    }
    getPortfolio();
  }, []);
  useEffect(() => {
    async function getMarketById(
      searchValue,
      selectedOption,
      selectedGrpOption
    ) {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.market.getMarketById(_id),
          {
            params: {
              search: searchValue,
              bet_status: selectedOption,
              group_name: selectedGrpOption,
            },
          }
        );
        setMarketData(response.data.data);
      } catch (error) { }
    }
    getMarketById(searchValue, selectedOption, selectedGrpOption);
  }, [searchValue, selectedOption, selectedGrpOption]);
  // -------------------Custom style for Select-------------------------
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectColor, setSelectColor] = useState("");
  useEffect(() => {
    if (followers.some((item) => item._id === String(userid))) {
      setIsFollowing(true);
    } else {
      setIsFollowing(false);
    }
  }, [followers, userid]);
  useEffect(() => {
    if (themeClass === "dark") {
      setSelectColor("black");
    } else {
      setSelectColor("white");
    }
  }, [themeClass]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectColor,
      width: "100%",
      // Add other styles as needed
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: selectColor,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: themeClass === "dark" ? "black" : "white",
      color: themeClass === "dark" ? "white" : "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "white" : "black", // Change the color of the selected item here
    }),
  };

  // Open first tab
  useEffect(() => {
    // Function to simulate a click event on the "Portfolio" tab link
    const openNotificationsTab = () => {
      const defaultOpenLink = document.getElementById("default-open");
      if (defaultOpenLink) {
        defaultOpenLink.click();
      }
    };

    // Call the function to open the "Portfolio" tab when the component mounts
    openNotificationsTab();
  }, []);
  const handleFollow = async () => {
    const payload = {
      followId: _id,
    };
    setLoading(true);
    axiosInstance
      .post(ApiEndpoints.follow.followed, payload)
      .then((response) => {
        setIsFollowing(true);
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })

      .catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function makeRequest() {
    try {
      const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
      // Assuming the API response contains an array of objects with a 'group_name' property
      const groupNames = response.data.map((item) => ({
        value: item.group_name,
        label: item.group_name,
      }));
      setGrpOption(groupNames);
    } catch (error) {
      return error;
    }
  }

  async function getAllComment() {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.comment.getQuestionByUserId(_id)
      );
      setAllComments(response.data.question);
    } catch (error) { }
  }
  const removeHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="mt-4">
              <div className="container">
                <div className="row">
                  {/* ---------------------Column 1--------------------- */}

                  {/* ---------------------Column 2--------------------- */}
                  <div className="col-lg-8 col-md-12">
                    <nav className="navbar navbar-expand-md mt-3 mb-4">
                      <Link to="/dashboard-home">
                        <button
                          className="mr-4"
                          style={{
                            border: "none",
                            width: 35,
                            height: 35,
                            borderRadius: 30,
                          }}
                        >
                          <img src="/images/icon_back.svg" alt="" />
                        </button>
                      </Link>
                    </nav>
                    <div
                      className="container"
                      style={{
                        padding: "15px 20px",
                        boxShadow: "0px 0px 20px #a59c9c",
                        borderRadius: "20px",
                      }}
                    >
                      <div className="d-flex align-center justify-content-start flex-column flex-md-row">
                        <div className="flex mb-3 mb-md-0 flex-column flex-md-row">
                          <div>
                            {/* <EditProfileIcon /> */}
                            <div className="col-md-3">
                              <div className="text-center">
                                <div className="image-upload">
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={
                                        profilePicture || "/images/Profile.png"
                                      }
                                      className="avatar img-circle"
                                      alt="avatar"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="parent">
                          <div className="achievement-div1">
                            <div className="">
                              <h5 className="profile-name text-capitalize">
                                {firstName + " " + lastName}
                              </h5>
                              <p className="email-address">{email}</p>
                            </div>
                          </div>
                          <div className="achievement-div2 flex flex-md-row-reverse">
                            {userid !== _id ? (
                              <div className="">
                                <button
                                  className="follow-btn btn btn-primary"
                                  onClick={handleFollow}
                                  disabled={loading || isFollowing}
                                >
                                  {loading
                                    ? "Loading..."
                                    : isFollowing
                                      ? "Following"
                                      : "Follow"}
                                </button>

                                {/* <img className='dot-btn' src='/images/btn_more.svg' alt='more-options' /> */}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="achievement-div3">
                            <div className="flex gap-3 md:gap-5">
                              {/* Following Section */}
                              <div
                                className="flex items-center gap-2 md:gap-3 p-1.5"
                                style={{
                                  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  width: "140px", // Slightly wider
                                  height: "60px", // Adjusted height
                                  // backgroundColor: "#fff",
                                }}
                              >
                                <img
                                  src="/images/icon_crown.svg"
                                  className="w-5 h-5 md:w-6 md:h-6"
                                  alt="Crown Icon"
                                />
                                <div className="text-center" style={{ paddingTop: "6px" }}>
                                  {/* Increased padding-top for better alignment */}
                                  <p className={`${themeClass} text-xs md:text-sm mt-1.5`}>Following</p>
                                  <p className={`${themeClass} text-base md:text-lg font-semibold`}>{followingCount}</p>
                                </div>
                              </div>

                              {/* Followers Section */}
                              <div
                                className="flex items-center gap-2 md:gap-3 p-1.5"
                                style={{
                                  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  width: "140px",
                                  height: "60px",
                                  // backgroundColor: "#fff",
                                }}
                              >
                                <img
                                  src="/images/icon_badge.svg"
                                  className="w-5 h-5 md:w-6 md:h-6"
                                  alt="Badge Icon"
                                />
                                <div className="text-center" style={{ paddingTop: "6px" }}>
                                  <p className={`${themeClass} text-xs md:text-sm mt-1.5`}>Followers</p>
                                  <p className={`${themeClass} text-base md:text-lg font-semibold`}>{followerCount}</p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      {/* <hr /> */}
                      {/* <div className='flex flex-wrap flex-md-row justify-content-around'>
                                <div className='blue flex flex-column mb-3 mb-md-0'>
                                    <div>{followingCount}</div>
                                    <div>Following</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='green flex flex-column mb-3 mb-md-0'>
                                    <div>{followerCount}</div>
                                    <div>Followers</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='red flex flex-column mb-3 mb-md-0'>
                                    <div>100+</div>
                                    <div>Calibration</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='orange flex flex-column'>
                                    <div>04</div>
                                    <div>Platinum Rank</div>
                                </div>
                            </div> */}
                      {/* <div className='discord-container flex gap-2'>
                                <img src='/images/icon_discord(1).svg' alt='' />
                                <p className='discord-username'>john#6102</p>
                            </div> */}
                    </div>
                    <div className="mt-5">
                      <div className="container">
                        <ul className="nav nav-pills d-flex gap-3">
                          <li
                            onClick={(e) => handleSetActive(e, "portfolio")}
                            className={
                              active === "portfolio" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#portfolio"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                              id="default-open"
                              aria-expanded="true"
                            >
                              Portfolio
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "market")}
                            className={
                              active === "market" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#market"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Market
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "comments")}
                            className={
                              active === "comments" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#comments"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Comments
                            </a>
                          </li>
                        </ul>
                        <hr />
                        <div className="tab-content mb-4">
                          <div id="portfolio" className="tab-pane fade">
                            {/* <div className='container col-md-12 d-flex justify-content-start align-items-center' style={{ padding: '0px' }}>
                                            <div className='col-md-9' style={{ padding: '0px' }}>
                                                <div className="search-bar form-outline" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }}>
                                                    <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example">
                                                    <option selected value="active">Active</option>
                                                    <option value="limit orders">Limit orders</option>
                                                    <option value="sold">Sold</option>
                                                    <option value="closed">Closed</option>
                                                    <option value="resolved">Resolved</option>
                                                    <option value="all">All</option>
                                                </select>
                                            </div>
                                        </div> */}
                            <div className="mt-4">
                              <div
                                className={`${themeClass === "dark" ? "dark" : ""
                                  }`}
                              >
                                {/* <div className={`${themeClass === 'dark' ? 'portfolio-header my-3 d-flex justify-content-center align-items-center'
                                                    : 'portfolio-header text-white my-3 d-flex justify-content-center align-items-center'}`}>
                                                    <div>
                                                        <div>Bet Result</div>
                                                    </div>

                                                </div> */}
                                {portFolio?.map((data, index) => {
                                  const percentage =
                                    (data.profit / data.amount) * 100;
                                  const profitRatio = percentage - 100;
                                  const displayProfitRatio =
                                    profitRatio < 0
                                      ? -profitRatio
                                      : profitRatio;
                                  const betAnswer =
                                    data && data.answer !== undefined
                                      ? data.answer.toUpperCase()
                                      : "-";
                                  return (
                                    <div key={index}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p className=" justify-content-between">
                                          <a
                                            className={`${themeClass === "dark"
                                              ? "text-white toggle-bets"
                                              : "toggle-bets"
                                              }`}
                                            href="#"
                                          >
                                            {data?.betQuestion}
                                            {/* <img src='./images/Frame.svg' alt='' /> */}
                                          </a>
                                        </p>
                                      </div>
                                      <div
                                        className={`${themeClass === "dark"
                                          ? "dark bg-dark card card-body"
                                          : "card card-body"
                                          }`}
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        {data?.type === "achieve" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div
                                              className="mb-4 d-flex align-items-center justify-content-start gap-4"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div>
                                                <span className="mr-2">
                                                  Payout
                                                </span>
                                                <img
                                                  style={{ width: "15px" }}
                                                  src="/images/Vector(7).svg"
                                                  alt="payout"
                                                />
                                                <div>
                                                  {data?.profit}$ on &nbsp;
                                                  <span
                                                    style={{
                                                      color:
                                                        betAnswer === "YES"
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {betAnswer}
                                                  </span>
                                                  {/* <span style={{ color: 'rgb(204, 29, 0)' }}>NO</span> */}
                                                </div>
                                              </div>
                                              <div>
                                                <span className="mr-2">
                                                  Spent
                                                </span>
                                                <img
                                                  style={{ width: "15px" }}
                                                  src="/images/Vector(7).svg"
                                                  alt="payout"
                                                />
                                                <div>
                                                  {data?.amount}$
                                                  {/* <span style={{ color: 'rgb(13, 148, 136)' }}> {data.answer}</span> */}
                                                  {/* <span style={{ color: 'rgb(204, 29, 0)' }}>NO</span> */}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        <div
                                          className="d-flex align-items-center justify-content-start gap-4"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {/* Details */}
                                        </div>
                                        {/* Other details */}
                                        <div
                                          className="d-flex justify-content-between align-items-center"
                                          style={{
                                            fontSize: "14px",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                          }}
                                        >
                                          <div>
                                            <div>Bet</div>
                                            <div>{betAnswer}</div>
                                          </div>

                                          <div>
                                            <div>Amount</div>
                                            <div>{data.amount}$</div>
                                          </div>
                                          <div>
                                            <div>Date</div>
                                            <div>
                                              {moment(
                                                data.marketCloseDate
                                              ).format("DD/MM/YY")}
                                            </div>
                                          </div>
                                          {data?.type === "winner" ? (
                                            <>
                                              <div>
                                                <div>Profit</div>
                                                <div>{data.profit} $</div>
                                              </div>
                                              <div>
                                                <div>Profit %</div>
                                                <div>
                                                  {Number(
                                                    displayProfitRatio
                                                  )?.toFixed(2)}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {data?.type === "loser" ? (
                                            <>
                                              <div>
                                                <div>Loss</div>
                                                <div>{data.amount} $</div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {data?.type === "achieve" ? (
                                            <>
                                              <div>
                                                <div>Profit</div>
                                                <div>{data.amount} $</div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div></div>
                            </div>
                          </div>
                          <div id="market" className="tab-pane fade">
                            {/* <div className='d-flex justify-content-start align-items-center gap-4 mb-4'>
                                            <div>
                                                <div>Creator rank</div>
                                                <div className='question-stats'>#215</div>
                                            </div>
                                            <div>
                                                <div>Total questions</div>
                                                <div className='question-stats'>10</div>
                                            </div>
                                            <div>
                                                <div>Traders</div>
                                                <div className='question-stats'>387</div>
                                            </div>
                                        </div> */}
                            {/* 
                                        <div className='container mb-4 col-md-12 d-flex justify-content-start align-items-center' style={{ padding: '0px', overflow: 'unset' }}>
                                            <div className='col-md-6' style={{ padding: '0px' }}>
                                                <div className="search-bar form-outline" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }}>
                                                    <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="open">Open</option>
                                                    <option value="closed">Closed</option>
                                                    <option value="resolved">Resolved</option>
                                                    <option value="" selected>All</option>
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <Select
                                                    defaultValue={selectedGrpOption}
                                                    menuIsOpen={isMenuOpen}
                                                    onMenuOpen={handleMenuOpen}
                                                    onMenuClose={handleMenuClose}
                                                    onChange={(selectedOption) => setSelectedGrpOption(selectedOption.value)}
                                                    options={grpOption}
                                                    placeholder='Select'
                                                // styles={customStyles}
                                                />
                                            </div>
                                        </div> */}

                            <div
                              className="container mb-4 col-md-12 d-flex justify-content-start align-items-center"
                              style={{
                                padding: "0px",
                                overflow: "unset",
                                width: "100%",
                              }}
                            >
                              <div class="row gap-1">
                                <div
                                  className="col-xs-12 col-sm-12 col-md-5"
                                  style={{
                                    paddingLeft: "11px",
                                    paddingRight: "11px",
                                  }}
                                >
                                  <div
                                    className="search-bar form-outline"
                                    style={{
                                      border: "1px solid #d2d2d2",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <input
                                      type="search"
                                      id="form1"
                                      className={`${themeInput} search-bar form-control shadow-none`}
                                      placeholder="Search"
                                      aria-label="Search"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col col-sm-6 col-md-3">
                                  <select
                                    className={`${themeClass === "dark"
                                      ? "text-white bg-dark form-select"
                                      : "form-select"
                                      }`}
                                    aria-label="Default select example"
                                    onChange={handleSelectChange}
                                  >
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                    <option value="resolved">Resolved</option>
                                    <option value="all" selected>
                                      All
                                    </option>
                                  </select>
                                </div>
                                <div className="col col-sm-6 col-md-3">
                                  <Select
                                    defaultValue={selectedGrpOption}
                                    menuIsOpen={isMenuOpen}
                                    onMenuOpen={handleMenuOpen}
                                    onMenuClose={handleMenuClose}
                                    onChange={(selectedOption) =>
                                      setSelectedGrpOption(selectedOption.value)
                                    }
                                    options={grpOption}
                                    placeholder="Select"
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="d-flex justify-content-center align-items-center mb-2">
                                <div className="col-md-12">
                                  <div>Question</div>
                                </div>
                                {/* <div className='col-md-2'>
                                                    <div>Prob</div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div>Traders</div>
                                                </div> */}
                              </div>
                              {marketData?.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      className="bet-list d-flex justify-content-between align-items-center mb-3"
                                      key={index}
                                    >
                                      <div className="col-md-10 d-flex justify-content-start align-items-center gap-4">
                                        <img
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                          }}
                                          src={item.marketImage}
                                          alt=""
                                        />
                                        <p style={{ wordBreak: "break-word" }}>
                                          {item.question}
                                        </p>
                                      </div>
                                      {/* <div className='col-md-2'>
                                                            <div>5%</div>
                                                        </div> */}
                                      <div className="col-2 d-flex justify-content-end align-items-center">
                                        {/* <img style={{ width: '14px' }} src='/images/Vector(9).svg' alt='' />24 */}
                                        <Link
                                          to={`/dashboard-market-detail/${item._id}`}
                                        >
                                          <button className="question-bet-btn">
                                            Bet
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                              {/* <div className='bet-list d-flex justify-content-center align-items-center mb-3'>
                                                <div className='col-md-8 d-flex justify-content-start align-items-center gap-4'>
                                                    <img style={{ width: "38px", height: "38px" }} src='/images/photo.png' alt='' />
                                                    <p>Will I win the 2024 POTUS election?</p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div>5%</div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex justify-content-start align-items-center gap-1'>
                                                        <img style={{ width: '14px' }} src='/images/Vector(9).svg' alt='' />24
                                                        <button className='question-bet-btn'>Bet</button>
                                                    </div>
                                                </div>
                                            </div> */}
                              {/* <div className='bet-list d-flex justify-content-center align-items-center mb-3'>
                                                <div className='col-md-8 d-flex justify-content-start align-items-center gap-4'>
                                                    <img style={{ width: "38px", height: "38px" }} src='/images/photo.png' alt='' />
                                                    <p>Will USDC's market cap exceed USDT's market cap by the end of 2024?</p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div>42%</div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex justify-content-start align-items-center gap-1'>
                                                        <img style={{ width: '14px' }} src='/images/Vector(9).svg' alt='' />46
                                                        <button className='question-bet-btn'>Bet</button>
                                                    </div>
                                                </div>
                                            </div> */}
                              {/* <div className='bet-list d-flex justify-content-center align-items-center mb-3'>
                                                <div className='col-md-8 d-flex justify-content-start align-items-center gap-4'>
                                                    <img style={{ width: "38px", height: "38px" }} src='/images/photo.png' alt='' />
                                                    <p>Will Alaska be closer than Virginia in the 2024 POTUS election?</p>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div>52%</div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex justify-content-start align-items-center gap-1'>
                                                        <img style={{ width: '14px' }} src='/images/Vector(9).svg' alt='' />35
                                                        <button className='question-bet-btn'>Bet</button>
                                                    </div>
                                                </div>
                                            </div> */}
                            </div>
                          </div>
                          <div
                            id="comments"
                            className="tab-pane fade"
                            style={{ fontSize: "14px" }}
                          >
                            <div>
                              {/* <div className='mb-3 comment-container'>
                                                Will there be an serious coup attempt in Russia aganist Putin (successful or unsuccessful) by the end of 2023
                                            </div> */}
                              {allComments.map((item, index) => (
                                <>
                                  <div className="d-flex mb-2 row" key={index}>
                                    <div className="d-flex gap-2 col-12">
                                      <div className="d-flex align-items-start">
                                        <img
                                          src="/images/profile_image.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            color: "#808080",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {item.question && (
                                            <span>{item.question}</span>
                                          )}
                                        </div>
                                        <div
                                          style={{ wordBreak: "break-word" }}
                                        >
                                          <span className="comment-username">
                                            @{item.name}{" "}
                                          </span>
                                          {removeHTMLTags(item.text)}
                                        </div>
                                        <div
                                          style={{
                                            color: "#808080",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {item.answer && (
                                            <span> Reply : {item.answer}</span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end col-12 align-items-end mb-3">
                                      <span>
                                        {formatDistanceToNow(
                                          new Date(item.createdAt),
                                          {
                                            addSuffix: true,
                                            includeSeconds: true,
                                          }
                                        ).replace("about ", "")}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ))}
                              {/* <div className='d-flex justify-content-start align-items-center gap-3 mb-4'>
                                                <div>
                                                    <img style={{ width: "38px", height: "38px" }} src='/images/photo.png' alt='' />
                                                </div>
                                                <div>
                                                    <div style={{ color: '#808080' }}>Gavin McCarthy-Bui <span>9 days ago</span></div>
                                                    <div><span className='comment-username'>@NickAllen</span> Damn I’m saving this. This made my day thank you. </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center gap-3 mb-4'>
                                                <div>
                                                    <img style={{ width: "38px", height: "38px" }} src='/images/photo.png' alt='' />
                                                </div>
                                                <div>
                                                    <div style={{ color: '#808080' }}>Gavin McCarthy-Bui <span>9 days ago</span></div>
                                                    <div><span className='comment-username'>@jack</span> Is that post real? It says it only has one view on it. I doubt the Wagner group would have such a little following  </div>
                                                </div>
                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                            <h1 className="chart-heading">Line Chart</h1>
                            <ResponsiveContainer width="100%" aspect={3}>
                                <LineChart data={pdata}>
                                        <Line dataKey="student" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div> */}

                    {/* ------------------------------------------------------------ */}
                  </div>

                  {/* ---------------------Column 3--------------------- */}
                  {/* <div className="col-lg-2 col-md-4 col-sm-12 mt-5" style={{ height: '100%' }}>
                        <RightSidePanel />
                    </div> */}
                  {/* spacer */}
                  {/* ------------------------------------------- */}
                  <section style={{ paddingBlock: "80px" }}></section>
                  {/* ------------------------------------------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default OtherProfilePage;

// < div className = " " >
//     <div className='flex justify-content-between'>
//         <div className="div1 flex justify-content-center gap-3">
//             <img src='./images/icon_crown.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass} `}>Portfolio</p>
//                 <p className={`${themeClass} `}>M500</p>
//             </div>
//         </div>
//         <div className="div2 flex justify-content-center gap-3">
//             <img src='./images/icon_badge.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass}`}>Bronze</p>
//                 <p className={`${themeClass}`}>Rank 21</p>
//             </div>
//         </div>
//         <div className="div3 flex justify-content-center gap-3">
//             <img src='./images/icon_fire.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass}`}>Streak</p>
//                 <p className={`${themeClass}`}>0</p>
//             </div>
//         </div>

//     </div>
//                         </div >
