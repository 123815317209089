import React from "react";
import LeftSidePanel from "../Components/LeftSidePanel";
import RightSidePanel from "../Components/RightSidePanel";
import { Link, useNavigate } from "react-router-dom";
import EditProfileIcon from "../Components/EditProfileIcon";
import "./styles/ProfilePage.css";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import Select from "react-select";
import axios from "axios";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { FaReply } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { useAuth } from "../hooks/useAuth";
import { formatDistanceToNow } from "date-fns";
import { Avatar } from "@mui/material";

// import moment from 'moment';
const options = [
  { value: "active", label: "Active" },
  { value: "limit orders", label: "Limit orders" },
  { value: "sold", label: "Sold" },
  { value: "closed", label: "Closed" },
  { value: "resolved", label: "Resolved" },
  { value: "all", label: "All" },
];

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%ss",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    M: "a month",
    MM: "%dM",
    y: "a year",
    yy: "%dY",
  },
});

const ProfilePage = () => {
  const navigate = useNavigate();

  const { themeClass, themeInput } = useContext(ThemeContext);
  const [active, setActive] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [profilePicture, setProfilePicture] = useState(
    "/public/images/dummy-avatar-png.png"
  );
  const [localToken, setLocalToken] = useState(
    localStorage.getItem("Oddplus_Token")
  );
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [wallet, setWallet] = useState("");
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRequest, setIsModalRequestOpen] = useState(false);
  const [modalTheme, setModalTheme] = useState("green-theme");
  const [modalBtnColor, setModalBtnColor] = useState("modalBtnGreen");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("yes");
  const [questionId, setQuestionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [comments, setComments] = useState("");
  const [commentStates, setCommentStates] = useState(
    Array(allComments.length).fill(false)
  );
  const [selectedGrpOption, setSelectedGrpOption] = useState("");
  const [followerCount, setFollowerCount] = useState("0");
  const [followingCount, setFollowingCount] = useState("0");
  const [portFolio, setPortFolio] = useState([]);
  const [tradedata, setTradeData] = useState([]);
  const { userid, fetchUserdata } = useAuth();
  const { availableBalance, holdBalance } = useAuth();
  const handleSetActive = (e, newValue) => {
    e?.preventDefault();
    setActive(newValue);
  };

  const handleOpenModal = (e, item) => {
    if (item.bet_result == "pending") {
      setQuestion(item.question);
      setQuestionId(item._id);
      setIsModalOpen(true);
    } else {
      showToast("Result already added for this market");
      setIsModalOpen(false);
    }
  };
  const showToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRequestOpen = (event) => {
    setIsModalRequestOpen(true);
  };
  const handleRequestClose = () => {
    setIsModalRequestOpen(false);
  };
  const [formData, setFormData] = useState({ name: "", validationError: "" });

  const handleInputAmountChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*$/.test(inputValue)) {
      setFormData({ ...formData, validationError: "Only digits are allowed" });
    } else {
      setFormData({ ...formData, name: inputValue, validationError: "" });
    }
  };

  const onSubmit = () => {
    if (!formData.name || formData.validationError) {
      setFormData({
        ...formData,
        validationError: "Field is required and must contain only digits",
      });
      return;
    }
    const payload = {
      amount: formData.name,
    };
    axiosInstance
      .post(ApiEndpoints.withdraw.withdrawRequest, payload)
      .then((response) => {
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setIsModalRequestOpen(false);
        getRequest();
      })
      .catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const pdata = [
    {
      name: "Bitcoin",
      high: 13,
      low: 10,
    },
    {
      name: "Tether",
      high: 12,
      low: 9,
    },
    {
      name: "Matic",
      high: 11,
      low: 12,
    },
    {
      name: "Ethereum",
      high: 20,
      low: 9,
    },
    {
      name: "Cardano",
      high: 15,
      low: 14,
    },
  ];
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectColor,
      width: "100%",
      height: "300px",
      // Add other styles as needed
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: selectColor,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: themeClass === "dark" ? "black" : "white",
      color: themeClass === "dark" ? "white" : "black",

      // backgroundColor: state.isSelected ? '#069514' : state.isFocused ? 'lightblue' : 'white',
      // color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'black',
      // Add other styles as needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "white" : "black", // Change the color of the selected item here
      // Add other styles as needed
    }),
  };

  // Get Profile detail API call
  useEffect(() => {
    async function makeRequest() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.auth.PROFILE_DATA
        );
        setFullName(response.data.data.full_name);
        setEmail(response.data.data.email);
        setProfilePicture(response.data.data.profile_picture);
        fetchUserdata();
      } catch (error) {
        return error;
      }
    }
    makeRequest();
  }, []);

  // useEffect(()=>{
  //     fetchUserdata();
  // })

  const [searchValue, setSearchValue] = useState("");
  const [selectedOptionType, setSelectedOptionType] = useState("");
  const [grpOption, setGrpOption] = useState([]);
  const [chartdata, setChartData] = useState([]);
  const [request, setRequest] = useState([]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  async function fetchMarketByUser(
    searchValue,
    selectedOptionType,
    selectedGrpOption
  ) {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.market.fetchMarketByUser,
        {
          params: {
            search: searchValue,
            bet_status: selectedOptionType,
            group_name: selectedGrpOption,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    fetchMarketByUser(searchValue, selectedOptionType, selectedGrpOption);
    groupList();
  }, [searchValue, selectedOptionType, selectedGrpOption]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSelectChangeType = (event) => {
    setSelectedOptionType(event.target.value);
  };

  async function groupList() {
    try {
      const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
      // Assuming the API response contains an array of objects with a 'group_name' property
      const groupNames = response.data.map((item) => ({
        value: item.group_name,
        label: item.group_name,
      }));

      setGrpOption(groupNames);
    } catch (error) {
      return error;
    }
  }

  // -------------------Custom style for Select-------------------------
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectColor, setSelectColor] = useState("");

  useEffect(() => {
    if (themeClass === "dark") {
      setSelectColor("black");
    } else {
      setSelectColor("white");
    }
  }, [themeClass]);

  // Show truncated string
  const maxLength = 15; // Maximum length of the truncated string

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substr(0, maxLength) + "...";
    }
    return str;
  }

  // Open first tab
  useEffect(() => {
    // Function to simulate a click event on the "Portfolio" tab link
    const openNotificationsTab = () => {
      const defaultOpenLink = document.getElementById("default-open");
      if (defaultOpenLink) {
        defaultOpenLink.click();
      }
    };

    // Call the function to open the "Portfolio" tab when the component mounts
    openNotificationsTab();
  }, []);

  const handleSelectChange = (event) => {
    setAnswer(event.target.value);
  };
  const betAnswer = async () => {
    const payload = {
      question: questionId,
      bet_result: answer,
    };
    setLoading(true);
    axiosInstance
      .post(ApiEndpoints.betting.Answers, payload)
      .then((response) => {
        fetchMarketByUser();
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
        setIsModalOpen(false);
      })

      .catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //Get all comment API call^^^^^^ API Call ^^^^^^^
  useEffect(() => {
    getAllComment();
    chartData();
    getRequest();
  }, []);

  async function chartData() {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.market.profileChart
      );
      setChartData(response.data.chart);
    } catch (error) {}
  }
  async function getAllComment() {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.comment.getQuestion
      );
      setAllComments(response.data.question);
    } catch (error) {}
  }
  async function getRequest() {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.withdraw.getRequest
      );
      setRequest(response.data.data);
    } catch (error) {}
  }
  const toggleCommenting = (index) => {
    const newCommentStates = [...commentStates];
    newCommentStates[index] = !newCommentStates[index];
    setCommentStates(newCommentStates);
  };
  const removeHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  };
  const commentReply = async (id, index) => {
    const payload = {
      id: id,
      answer: comments,
    };
    setLoading(true);
    axiosInstance
      .post(ApiEndpoints.comment.questionAnswer, payload)
      .then((response) => {
        const notify = () => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
        toggleCommenting(index);
        getAllComment();
        setIsModalOpen(false);
      })

      .catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        };
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKYCNavigation = () => {
    navigate(`/dashboard-edit-profile/${"kyc"}`);
  };

  useEffect(() => {
    async function getFollowing() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.follow.getFollowing(userid)
        );
        setFollowingCount(response.data.followerCount);
      } catch (error) {}
    }
    getFollowing();
  }, []);
  useEffect(() => {
    async function getFollowers() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.follow.getFollowers(userid)
        );
        setFollowerCount(response.data.followerCount);
      } catch (error) {}
    }
    getFollowers();
  }, []);
  useEffect(() => {
    async function getPortfolio() {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.betting.Portfolio(userid)
        );
        setPortFolio(response.data.user);
      } catch (error) {}
    }
    getPortfolio();
  }, []);
  useEffect(() => {
    async function tardeList() {
      try {
        const response = await axiosInstance.get(ApiEndpoints.betting.All_Bets);
        setTradeData(response.data.bets);
      } catch (error) {}
    }
    tardeList();
  }, []);
  const capitalizeLabel = (label) => {
    return label.charAt(0).toUpperCase() + label.slice(1);
  };
  return (
    <>
      <div className={` ${themeClass} `}>
        <div className="row d-flex">
          <div className="col-12">
            <div className="mt-4">
              <div className="container">
                <div className="row">
                  {/* ---------------------Column 1--------------------- */}

                  {/* ---------------------Column 2--------------------- */}
                  <div className="col-lg-8 col-md-12">
                    <nav className="navbar navbar-expand-md mt-3 mb-4">
                      <Link to="/dashboard-home">
                        <button
                          className="mr-4"
                          style={{
                            border: "none",
                            width: 35,
                            height: 35,
                            borderRadius: 30,
                          }}
                        >
                          <img src="./images/icon_back.svg" alt="" />
                        </button>
                      </Link>
                    </nav>
                    <div
                      className="container"
                      style={{
                        padding: "15px 20px",
                        boxShadow: "0px 0px 20px #a59c9c",
                        borderRadius: "20px",
                      }}
                    >
                      <div className="d-flex align-center justify-content-start flex-column flex-md-row">
                        <div className="flex mb-3 mb-md-0 flex-column flex-md-row">
                          <div>
                            {/* <EditProfileIcon /> */}
                            <div className="col-md-3">
                              <div className="text-center">
                                <div className="image-upload">
                                  <div style={{ position: "relative" }}>
                                    <Link to="/dashboard-edit-profile">
                                      <img
                                        src={
                                          profilePicture ||
                                          "./images/dummy-avatar-png.png"
                                        }
                                        className="avatar img-circle"
                                        alt="avatar"
                                      />
                                      <img
                                        src="./images/icon_edit_pro.svg"
                                        className="edit-icon"
                                        alt="edit"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="parent">
                          <div className="achievement-div1">
                            <div className="">
                              <h5 className="profile-name text-capitalize">
                                {truncateString(fullName, maxLength)}
                              </h5>
                              <p className="email-address">{email}</p>
                            </div>
                          </div>
                          <div className="achievement-div2 flex flex-md-row-reverse">
                            <div className="">
                              <button
                                className="follow-btn btn btn-primary"
                                onClick={handleKYCNavigation}
                              >
                                KYC
                              </button>
                              {/* <img className='dot-btn' src='./images/btn_more.svg' alt='more-options' /> */}
                            </div>
                          </div>
                          <div className="achievement-div3 d-none d-md-block">
                            <div className="">
                              <div className="col-md-12  flex justify-content-between gap-2">
                                <div className="div1 flex justify-content-center ">
                                  <img
                                    src="./images/icon_crown.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p className={`${themeClass} `}>
                                      Wallet Amount
                                    </p>
                                    <p className={`${themeClass} `}>
                                      ${availableBalance}
                                    </p>
                                  </div>
                                </div>

                                <div className="div2 flex justify-content-center">
                                  <img
                                    src="./images/icon_badge.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p className={`${themeClass}`}>
                                      Hold Balance
                                    </p>
                                    <p className={`${themeClass}`}>
                                      ${holdBalance}
                                    </p>
                                  </div>
                                </div>
                                <div className="div2 flex justify-content-center">
                                  <img
                                    src="./images/icon_fire.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p className={`${themeClass}`}>Following</p>
                                    <p className={`${themeClass}`}>
                                      {followingCount}
                                    </p>
                                  </div>
                                </div>

                                <div className="div3 flex justify-content-center ">
                                  <img
                                    src="./images/icon_fire.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p className={`${themeClass}`}>Followers</p>
                                    <p className={`${themeClass}`}>
                                      {followerCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="achievement-div3">
                            <div className="">
                              <div className="col-sm-12 d-sm-flex d-md-none flex justify-content-between">
                                <div
                                  className="div1 col-sm-6 flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src="./images/icon_crown.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p
                                      className={`${themeClass} `}
                                      style={{ width: "100%" }}
                                    >
                                      Wallet Amount
                                    </p>
                                    <p className={`${themeClass} `}>
                                      ${availableBalance}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="div2 col-sm-6 flex justify-content-center"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src="./images/icon_badge.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p
                                      className={`${themeClass}`}
                                      style={{ width: "100%" }}
                                    >
                                      Hold Balance
                                    </p>
                                    <p className={`${themeClass}`}>
                                      ${holdBalance}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 d-sm-flex d-md-none flex justify-content-between gap-4">
                                <div
                                  className="div1 col-sm-6 flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src="./images/icon_fire.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p className={`${themeClass}`}>Following</p>
                                    <p className={`${themeClass}`}>
                                      {followingCount}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="div2 col-sm-6 flex justify-content-center"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src="./images/icon_fire.svg"
                                    style={{ width: 30, height: 30 }}
                                    alt=""
                                  />
                                  <div>
                                    <p
                                      className={`${themeClass}`}
                                      style={{ width: "100%" }}
                                    >
                                      Followers
                                    </p>
                                    <p className={`${themeClass}`}>
                                      {followerCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div class="container text-center">
                                        <div class="row row-cols-2">
                                            <div class="col flex justify-content-start align-start">
                                                <img src='./images/icon_crown.svg' style={{ width: 30, height: 30 }} alt='' />
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <p className={`${themeClass} `} >Wallet Amount</p>
                                                    <p className={`${themeClass} `}>${availableBalance}</p>
                                                </div>
                                            </div>
                                            <div class="col flex justify-content-end"> <img src='./images/icon_crown.svg' style={{ width: 30, height: 30 }} alt='' /></div>
                                            <div class="col flex justify-content-start"> <img src='./images/icon_crown.svg' style={{ width: 30, height: 30 }} alt='' /></div>
                                            <div class="col flex justify-content-end"> <img src='./images/icon_crown.svg' style={{ width: 30, height: 30 }} alt='' /></div>
                                        </div>
                                    </div> */}
                        </div>
                      </div>
                      {/* <hr /> */}
                      {/* <div className='flex flex-wrap flex-md-row justify-content-around'>
                                <div className='blue flex flex-column mb-3 mb-md-0'>
                                    <div>{followingCount}</div>
                                    <div>Following</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='green flex flex-column mb-3 mb-md-0'>
                                    <div>{followerCount}</div>
                                    <div>Followers</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='red flex flex-column mb-3 mb-md-0'>
                                    <div>100+</div>
                                    <div>Calibration</div>
                                </div>
                                <p className='right-border'></p>
                                <div className='orange flex flex-column'>
                                    <div>04</div>
                                    <div>Platinum Rank</div>
                                </div>
                            </div>
                            <div className='discord-container flex gap-2'>
                                <img src='./images/icon_discord(1).svg' alt='' />
                                <p className='discord-username'>john#6102</p>
                            </div> */}
                    </div>
                    <div className="mt-5">
                      <div className="container">
                        <ul className="nav nav-pills d-flex gap-3">
                          <li
                            onClick={(e) => handleSetActive(e, "portfolio")}
                            className={
                              active === "portfolio" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#portfolio"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                              id="default-open"
                              aria-expanded="true"
                            >
                              Portfolio
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "market")}
                            className={
                              active === "market" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#market"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Market
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "comments")}
                            className={
                              active === "comments" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#comments"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Comments
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "trades")}
                            className={
                              active === "trades" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#trades"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Trades
                            </a>
                          </li>
                          <li
                            onClick={(e) => handleSetActive(e, "withdraw")}
                            className={
                              active === "withdraw" ? "custom-tab" : "InActive"
                            }
                          >
                            <a
                              className="m-2"
                              data-toggle="pill"
                              href="#withdraw"
                              style={{
                                color:
                                  themeClass === "dark" ? "white" : "black",
                              }}
                            >
                              Withdraw Request
                            </a>
                          </li>
                        </ul>
                        <hr />
                        {/* Chart  */}
                        <div className="tab-content mb-4">
                          <div id="portfolio" className="tab-pane fade">
                            <div>
                              <ResponsiveContainer width="100%" aspect={3}>
                                <LineChart
                                  data={chartdata}
                                  width={500}
                                  height={300}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 0,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray={"3 3"} />
                                  <XAxis
                                    dataKey="name"
                                    interval={"preserveStartEnd"}
                                    tickFormatter={capitalizeLabel}
                                  />
                                  <YAxis />
                                  <Tooltip
                                    contentStyle={{
                                      backgroundColor: "yellow",
                                      borderRadius: "10px",
                                      textTransform: "capitalize",
                                    }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="Amount"
                                    stroke="red"
                                    activeDot={{ r: 8 }}
                                    style={{ textTransform: "capitalize" }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="Answer"
                                    stroke="green"
                                    activeDot={{ r: 8 }}
                                    style={{ textTransform: "capitalize" }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="status"
                                    stroke="green"
                                    activeDot={{ r: 8 }}
                                    style={{ textTransform: "capitalize" }}
                                  />
                                </LineChart>
                              </ResponsiveContainer>
                            </div>
                            {/* <div className='container col-md-12 d-flex justify-content-start align-items-center' style={{ padding: '0px' }}>
                                            <div className='col-md-9' style={{ padding: '0px' }}>
                                                <div className="search-bar form-outline" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }}>
                                                    <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example">
                                                    <option selected value="active">Active</option>
                                                    <option value="limit orders">Limit orders</option>
                                                    <option value="sold">Sold</option>
                                                    <option value="closed">Closed</option>
                                                    <option value="resolved">Resolved</option>
                                                    <option value="all">All</option>
                                                </select>
                                            </div>
                                        </div> */}
                            <div className="mt-4">
                              <div
                                className={`${
                                  themeClass === "dark" ? "dark" : ""
                                }`}
                              >
                                {/* <div className={`${themeClass === 'dark' ? 'portfolio-header my-3 d-flex justify-content-center align-items-center'
                                                    : 'portfolio-header text-white my-3 d-flex justify-content-center align-items-center'}`}>
                                                    <div>
                                                        <div>Bet Result</div>
                                                    </div>

                                                </div> */}
                                {portFolio?.map((data, index) => {
                                  const percentage =
                                    (data.profit / data.amount) * 100;
                                  const profitRatio = percentage - 100;
                                  const displayProfitRatio =
                                    profitRatio < 0
                                      ? -profitRatio
                                      : profitRatio;
                                  const betAnswer =
                                    data && data.answer !== undefined
                                      ? data.answer.toUpperCase()
                                      : "-";
                                  return (
                                    <div key={index}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p
                                          className="justify-content-between"
                                          style={{ wordBreak: "break-word" }}
                                          maxLength={50}
                                        >
                                          <a
                                            className={`${
                                              themeClass === "dark"
                                                ? "text-white toggle-bets"
                                                : "toggle-bets"
                                            }`}
                                            href="#"
                                          >
                                            {data?.betQuestion}
                                            {/* <img src='./images/Frame.svg' alt='' /> */}
                                          </a>
                                        </p>
                                      </div>
                                      <div
                                        className={`${
                                          themeClass === "dark"
                                            ? "dark bg-dark card card-body"
                                            : "card card-body"
                                        }`}
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        {data?.type === "achieve" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div
                                              className="mb-4 d-flex align-items-center justify-content-start gap-4"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {data?.type === "winner" ? (
                                                <>
                                                  <div>
                                                    <span className="mr-2">
                                                      Payout
                                                    </span>
                                                    <img
                                                      style={{ width: "15px" }}
                                                      src="/images/Vector(7).svg"
                                                      alt="payout"
                                                    />
                                                    <div>
                                                      {data?.profit}$
                                                      {/* <span style={{ color: 'rgb(204, 29, 0)' }}>NO</span> */}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                "-"
                                              )}

                                              <div>
                                                <span className="mr-2">
                                                  Spent
                                                </span>
                                                <img
                                                  style={{ width: "15px" }}
                                                  src="/images/Vector(7).svg"
                                                  alt="payout"
                                                />
                                                <div>
                                                  {data?.amount}$ &nbsp;
                                                  <span
                                                    style={{
                                                      color:
                                                        betAnswer === "YES"
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {betAnswer}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        <div
                                          className="d-flex align-items-center justify-content-start gap-4"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {/* Details */}
                                        </div>
                                        {/* Other details */}
                                        <div
                                          className="d-flex justify-content-between align-items-center"
                                          style={{
                                            fontSize: "14px",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                          }}
                                        >
                                          <div>
                                            <div>Bet</div>
                                            <div>{betAnswer}</div>
                                          </div>

                                          <div>
                                            <div>Amount</div>
                                            <div>{data.amount}$</div>
                                          </div>
                                          <div>
                                            <div>Date</div>
                                            <div>
                                              {moment(
                                                data.marketCloseDate
                                              ).format("DD/MM/YY")}
                                            </div>
                                          </div>
                                          {data?.type === "winner" ? (
                                            <>
                                              <div>
                                                <div>Profit</div>
                                                <div>{data.profit} $</div>
                                              </div>
                                              <div>
                                                <div>Profit %</div>
                                                <div>
                                                  {Number(
                                                    displayProfitRatio
                                                  )?.toFixed(2)}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {data?.type === "loser" ? (
                                            <>
                                              <div>
                                                <div>Loss</div>
                                                <div>{data.amount} $</div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {data?.type === "achieve" ? (
                                            <>
                                              <div>
                                                <div>Profit</div>
                                                <div>{data.amount} $</div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div></div>
                            </div>
                          </div>
                          <div
                            id="market"
                            className="tab-pane fade"
                            style={{ minHeight: "600px" }}
                          >
                            {/* <div className='d-flex justify-content-start align-items-center gap-4 mb-4'>
                                            <div>
                                                <div>Creator rank</div>
                                                <div className='question-stats'>#215</div>
                                            </div>
                                            <div>
                                                <div>Total questions</div>
                                                <div className='question-stats'>10</div>
                                            </div>
                                            <div>
                                                <div>Traders</div>
                                                <div className='question-stats'>387</div>
                                            </div>
                                        </div> */}

                            <div
                              className="container mb-4 col-md-12 d-flex justify-content-start align-items-center"
                              style={{
                                padding: "0px",
                                overflow: "unset",
                                width: "100%",
                              }}
                            >
                              <div class="row gap-1">
                                <div
                                  className="col-xs-12 col-sm-12 col-md-5"
                                  style={{
                                    paddingLeft: "11px",
                                    paddingRight: "11px",
                                  }}
                                >
                                  <div
                                    className="search-bar form-outline"
                                    style={{
                                      border: "1px solid #d2d2d2",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <input
                                      type="search"
                                      id="form1"
                                      className={`${themeInput} search-bar form-control shadow-none`}
                                      placeholder="Search"
                                      aria-label="Search"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col col-sm-6 col-md-3">
                                  <select
                                    className={`${
                                      themeClass === "dark"
                                        ? "text-white bg-dark form-select"
                                        : "form-select"
                                    }`}
                                    aria-label="Default select example"
                                    onChange={handleSelectChangeType}
                                  >
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                    <option value="resolved">Resolved</option>
                                    <option value="all" selected>
                                      All
                                    </option>
                                  </select>
                                </div>
                                <div className="col col-sm-6 col-md-3">
                                  <Select
                                    defaultValue={selectedGrpOption}
                                    menuIsOpen={isMenuOpen}
                                    onMenuOpen={handleMenuOpen}
                                    onMenuClose={handleMenuClose}
                                    onChange={(selectedOption) =>
                                      setSelectedGrpOption(selectedOption.value)
                                    }
                                    options={grpOption}
                                    placeholder="Select"
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              {data.map((item) => (
                                <div className="bet-list d-flex justify-content-between align-items-center mb-3">
                                  <div className="col-md-10 d-flex justify-content-start align-items-center gap-4">
                                    <img
                                      src={item.marketImage}
                                      alt=""
                                      style={{ width: "40px", height: "40px" }}
                                    />
                                    <p style={{ wordBreak: "break-word" }}>
                                      {item.question}
                                    </p>
                                  </div>
                                  <div className="col-2">
                                    <div className="d-flex justify-content-end align-items-start gap-1">
                                      <FaEye
                                        fontSize={18}
                                        onClick={(e) => {
                                          handleOpenModal(e, item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* Modal start*/}
                          {isModalOpen && (
                            <div className="modal">
                              <div
                                className={`${
                                  themeClass === "dark"
                                    ? "text-white modal-content bg-dark"
                                    : "modal-content"
                                }`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    className="modal-close"
                                    onClick={handleCloseModal}
                                  >
                                    <img
                                      src="./images/cross.png"
                                      alt="close"
                                      style={{ width: "40px" }}
                                    />
                                  </span>
                                  <h4
                                    style={{
                                      width: "90%",
                                      wordBreak: "break-word",
                                    }}
                                    maxLength={50}
                                  >
                                    {question}
                                  </h4>
                                </div>
                                <div
                                  className={`${
                                    themeClass === "dark"
                                      ? `${modalTheme} bet-amount-section d-flex rounded col-md-12 text-white bg-dark`
                                      : `${modalTheme} bet-amount-section d-flex rounded col-md-12`
                                  }`}
                                >
                                  <div className="col-md-4">
                                    <div className="mb-2">Market Answer</div>
                                    <select
                                      className="form-control"
                                      id="marketAnswer"
                                      onChange={handleSelectChange}
                                    >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex justify-content-center mt-4">
                                  <button
                                    className={`${modalBtnColor} modal-btn`}
                                    style={{ width: "40%" }}
                                    onClick={betAnswer}
                                    loading={loading}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Modal end*/}
                          <div
                            id="comments"
                            className="tab-pane fade"
                            style={{ fontSize: "14px" }}
                          >
                            <div>
                              {/* <div className='mb-3 comment-container'>
                                                Will there be an serious coup attempt in Russia against Putin (successful or unsuccessful) by the end of 2023
                                            </div> */}
                              {allComments.map((item, index) => (
                                <>
                                  {/* <div className='d-flex justify-content-between align-items-start gap-2 mb-2' key={index}>
                                                        <div className='d-flex gap-2'>
                                                            <Avatar src={item.profile_picture || "./images/profile_image.svg"} alt="" style={{ width: "30px", height: "30px" }} />
                                                            <div>
                                                                <div style={{ color: '#808080' }}>
                                                                    {item.question && <span>{item.question}</span>}
                                                                </div>
                                                                <div style={{ wordBreak: "break-word" }}>
                                                                    <span className='comment-username'>@{item.name}</span> &nbsp;
                                                                    <span>{removeHTMLTags(item.text)}</span>
                                                                </div>
                                                                <div style={{ color: '#808080' }}>
                                                                    {item.answer && <span> Reply : {item.answer}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                                            {!item.answer ? (
                                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <button className='btn' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", width: "50px" }} onClick={() => toggleCommenting(index)}>
                                                                        <FaReply />
                                                                    </button>
                                                                </div>
                                                            ) : (<></>)}
                                                            <span style={{ display: "flex", justifyContent: "flex-end" }}>{formatDistanceToNow(new Date(item.createdAt), { addSuffix: true, includeSeconds: true })}</span>
                                                        </div>
                                                    </div> */}

                                  <div
                                    className="d-flex justify-content-start align-items-start mb-2 row"
                                    key={index}
                                  >
                                    <div className="d-flex gap-2 col-12">
                                      <Avatar
                                        src={
                                          item.profile_picture ||
                                          "./images/profile_image.svg"
                                        }
                                        alt=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                      <div>
                                        <div style={{ color: "#808080" }}>
                                          {item.question && (
                                            <span
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {item.question}
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <span className="comment-username">
                                            @{item.name}&nbsp;
                                          </span>
                                          <span
                                            style={{ wordBreak: "break-word" }}
                                          >
                                            {removeHTMLTags(item.text)}
                                          </span>
                                        </div>
                                        <div style={{ color: "#808080" }}>
                                          {item.answer && (
                                            <span
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {" "}
                                              Reply : {item.answer}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex gap-2 col-12 mt-1"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {!item.answer ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <button
                                            className="btn"
                                            style={{
                                              boxShadow:
                                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                              width: "50px",
                                            }}
                                            onClick={() =>
                                              toggleCommenting(index)
                                            }
                                          >
                                            <FaReply />
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {formatDistanceToNow(
                                          new Date(item.createdAt),
                                          {
                                            addSuffix: true,
                                            includeSeconds: true,
                                          }
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  {commentStates[index] && (
                                    <div className="mb-2">
                                      <div>
                                        <textarea
                                          maxLength={500}
                                          className="form-control"
                                          onChange={(e) => {
                                            setComments(e.target.value);
                                          }}
                                          rows="2"
                                        ></textarea>
                                      </div>
                                      <div className="d-flex justify-content-end mt-2">
                                        <button
                                          className="btn btn-primary"
                                          style={{ fontSize: "12px" }}
                                          onClick={() => {
                                            commentReply(item?._id, index);
                                          }}
                                        >
                                          {" "}
                                          Reply
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}

                              {/* <div className='d-flex justify-content-start align-items-center gap-3 mb-4'>
                                                        <div>
                                                            <img src='./images/profile_image.svg' alt='' />
                                                        </div>
                                                        <div>
                                                            <div style={{ color: '#808080' }}>Gavin McCarthy-Bui <span>9 days ago</span></div>
                                                            <div><span className='comment-username'>@NickAllen</span> Damn I’m saving this. This made my day thank you. </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-start align-items-center gap-3 mb-4'>
                                                        <div>
                                                            <img src='./images/profile_image.svg' alt='' />
                                                        </div>
                                                        <div>
                                                            <div style={{ color: '#808080' }}>Gavin McCarthy-Bui <span>9 days ago</span></div>
                                                            <div><span className='comment-username'>@jack</span> Is that post real? It says it only has one view on it. I doubt the Wagner group would have such a little following  </div>
                                                        </div>
                                                    </div> */}
                            </div>
                          </div>
                          <div
                            id="trades"
                            className="tab-pane fade"
                            style={{ fontSize: "14px", overflow: "auto" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Question</th>
                                  <th scope="col">Bet</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tradedata?.map((trade, index) => (
                                  <tr key={trade._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{trade.questionName}</td>
                                    <td>{trade.answer.toUpperCase()}</td>
                                    <td>{trade.amount}</td>
                                    <td>{trade.quantity}</td>
                                    <td>
                                      {new Date(
                                        trade.createdAt
                                      ).toLocaleDateString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div
                            id="withdraw"
                            className="tab-pane fade"
                            style={{ fontSize: "14px", overflow: "auto" }}
                          >
                            <div className="flex justify-content-end mt-1">
                              <div
                                className="new-group-btn"
                                onClick={(e) => {
                                  handleRequestOpen();
                                }}
                              >
                                Add Request
                              </div>
                            </div>
                            <table
                              className="table"
                              style={{ marginTop: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Wallet Address</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {request?.map((request, index) => (
                                  <tr key={request._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{request.user.wallet}</td>
                                    <td>{request.amount}</td>
                                    <td>{request.status.toUpperCase()}</td>
                                    <td>
                                      {new Date(
                                        request.createdAt
                                      ).toLocaleDateString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {isModalOpenRequest && (
                            <div className="modal">
                              <div
                                className={`${
                                  themeClass === "dark"
                                    ? "text-white modal-content bg-dark"
                                    : "modal-content"
                                }`}
                              >
                                <span
                                  className="modal-close"
                                  onClick={handleRequestClose}
                                >
                                  <img
                                    src="./images/cross.png"
                                    alt="close"
                                    style={{ width: "40px" }}
                                  />
                                </span>
                                <h4 style={{ width: "90%" }}>
                                  Withdraw Request
                                </h4>

                                <div
                                  className={`${
                                    themeClass === "dark"
                                      ? `${modalTheme} bet-amount-section d-flex rounded col-md-12 text-white bg-dark`
                                      : `${modalTheme} bet-amount-section d-flex rounded col-md-12`
                                  }`}
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="mb-4">
                                    <div>
                                      <div className="form-outline">
                                        <label
                                          className="mb-2"
                                          htmlFor="form3Example1m"
                                        >
                                          Withdraw Amount
                                        </label>
                                        <input
                                          style={{
                                            outline: 0,
                                            borderWidth: "0 0 1px",
                                          }}
                                          type="text"
                                          name="name"
                                          id="form3Example1m"
                                          className={`${themeInput} form-control form-control-sm shadow-none`}
                                          value={formData.name}
                                          onChange={handleInputAmountChange}
                                          required
                                        />
                                      </div>
                                      {formData.validationError && (
                                        <div className="text-danger">
                                          {formData.validationError}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-content-center mt-4">
                                  <button
                                    className={`${modalBtnColor} modal-btn`}
                                    style={{ width: "40%" }}
                                    onClick={onSubmit}
                                    loading={loading}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* ------------------------------------------------------------ */}
                  </div>

                  {/* ---------------------Column 3--------------------- */}
                  {/* <div className="col-lg-2 col-md-4 col-sm-12 mt-5" style={{ height: '100%' }}>
                        <RightSidePanel />
                    </div> */}
                  {/* spacer */}
                  {/* ------------------------------------------- */}
                  <section style={{ paddingBlock: "80px" }}></section>
                  {/* ------------------------------------------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ProfilePage;

// < div className = " " >
//     <div className='flex justify-content-between'>
//         <div className="div1 flex justify-content-center gap-3">
//             <img src='./images/icon_crown.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass} `}>Portfolio</p>
//                 <p className={`${themeClass} `}>M500</p>
//             </div>
//         </div>
//         <div className="div2 flex justify-content-center gap-3">
//             <img src='./images/icon_badge.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass}`}>Bronze</p>
//                 <p className={`${themeClass}`}>Rank 21</p>
//             </div>
//         </div>
//         <div className="div3 flex justify-content-center gap-3">
//             <img src='./images/icon_fire.svg' style={{ width: 28, height: 28 }} alt='' />
//             <div>
//                 <p className={`${themeClass}`}>Streak</p>
//                 <p className={`${themeClass}`}>0</p>
//             </div>
//         </div>

//     </div>
//                         </div >
