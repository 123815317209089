import React, { useState, useEffect } from 'react'
import './styles/Signin.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import showPwdImg from './show-password.svg'
import hidePwdImg from './hide-password.svg'
import Navbar from './Components/Navbar'
import { useContext } from 'react'
import { LoginContext } from './Context/LoginContext'
import { useAuth } from './hooks/useAuth'
import { axiosInstance } from './network/config/adapter'
import { ApiEndpoints } from './network/config/endpoints'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Log } from 'ethers'
import ResponsiveNavBar from './Components/ResponsiveNavBar'
import { Container } from 'react-bootstrap'
// import {  } from '@react-oauth/google';
// import jwt_decode from "jwt-decode";
const Signin = () => {
    const auth = useAuth()
    const { fetchUserdata } = useAuth()
    const [isRevealPwd, setIsRevealPwd] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [signupSuccess, setSignupSuccess] = useState('');
    const [token,setToken] = useState('');
   
    // const { status, setStatus } = useContext(LoginContext);

    useEffect(() => {
        // Access the localStorage value when the component mounts
        setSignupSuccess(localStorage.getItem('signupSuccess'));

        // Delete the localStorage value
        setTimeout(() => {
            localStorage.removeItem('signupSuccess');
        }, 2000);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a data object with the form values
        const data = {
            email: email,
            password: password,
        };



        try {
            // Make a POST request using Axios
            const response = await axiosInstance.post(ApiEndpoints.auth.SIGN_IN, data);

            // Handle the response (e.g., show a success message)
            auth.login(response.data)

            navigate('/dashboard-home');
            fetchUserdata();
        } catch (error) {
            // Handle the error (e.g., show an error message)

            const notify = () => {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();
        }
    };
    // const handleGoogleLogin = async () => {
    //     try {
    //       const response = await axios.get('http://localhost:3000/api/google-signin');
    //       window.location.href = response.data.redirectUrl;
    //     } catch (error) {
    //       console.error('Google login error:', error);
    //     }
    //   };

    const googleLogin = async (token) => {   
        try {
            const response = await axiosInstance.post(ApiEndpoints.auth.Google_Login, {credential:token});
            auth.login(response.data)
        } catch (error) {
        }
    }
    return (

        <>
            <Container style={{ height: "100vh" }}>
                {/* <Navbar /> */}
                <ResponsiveNavBar />
                <section className="d-flex align-items-center justify-content-center main-container overflow-hidden">
                    <div className="py-2">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col">
                                <div className="card card-registration border-0">
                                    <div className="row g-0 d-flex justify-content-center align-items-center">
                                        {signupSuccess === 'true' ?
                                            <div className='col-xl-6 px-5 d-flex justify-content-center align-items-center flex-column'>
                                                <img src="./images/password_success.svg"
                                                    alt="signin" className="img-fluid center-block"
                                                />
                                                <h5 style={{ color: '#25CF16', fontSize: '28px' }}>SUCCESS!</h5>
                                                <p style={{ fontSize: '24px', marginBottom: '10px' }}>Registration done successfully</p>
                                            </div>
                                            :
                                            <div className="col-xl-6 px-5 d-flex justify-content-center align-items-center">
                                                <img src="./images/Slotmachine.svg"
                                                    alt="signin" className="img-fluid center-block"
                                                />
                                            </div>

                                        }
                                        <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="card-body mx-5 text-black">
                                                    <h3 className="mb-2 label signin-label">Sign In</h3>
                                                    <p className="mb-3 text-dark">Please enter your credential to login.</p>

                                                    <div className="form-outline mb-3">
                                                        <label className="label-color" for="form3Example97">Email</label>
                                                        <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                            type="email"
                                                            id="form3Example97"
                                                            className="form-control form-control-sm shadow-none"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="form-outline mb-2">
                                                        <label className="label-color" for="form3Example97">Password</label>
                                                        <div className='d-flex'>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px', paddingRight: '30px' }}
                                                                type={isRevealPwd ? "password" : "text"}
                                                                id="form3Example97"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required
                                                            />
                                                            <img className='pwd-container-img'
                                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                                alt=''
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-outline mb-3 d-flex justify-content-end align-items-end">
                                                        <Link to='/forgot-password'><p><a href='#' className='forgot-text'>Forgot Password?</a></p></Link>
                                                    </div>

                                                    <div className='d-flex flex-column'>
                                                        <button type="submit" className="col-xl-12 btn btn-lg" style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}>Login</button>

                                                        <h5 className='py-2' style={{ fontSize: '16px', textAlign: 'center', margin: '8px' }}>Or</h5>

                                                        {/* <button type="submit" className="col-xl-12 btn btn-lg d-flex justify-content-center align-items-center gap-2" style={{ border: '1px solid black', borderRadius: 30 }} onClick={handleGoogleLogin}> */}
                                                            {/* <img src='./images/google.svg' style={{ position: 'relative' }} alt='' />
                                                            <div>Login with Google</div> */}
                                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                                <GoogleOAuthProvider    clientId="487672296567-1d7lde7pobknb9g4m092ai52v0698dcs.apps.googleusercontent.com">
                                                                    <GoogleLogin
                                                                        onSuccess={credentialResponse => {
                                                                         setToken(credentialResponse.credential);
                                                                         googleLogin(credentialResponse.credential)
                                                                            // var decoded = jwt_decode(credentialResponse.credential);
                                                                        }}
                                                                        onError={() => {
                                                                        }}
                                                                    />
                                                                </GoogleOAuthProvider>
                                                            </div>
                                                        {/* </button> */}
                                                    </div>

                                                    <div className="d-flex justify-content-center align-items-center mt-3 ">
                                                        <Link to='/signup'><p className='already'>Don't have an account?<a href='#' style={{ color: '#069514', fontWeight: 700, textDecoration: 'none', fontFamily: 'Ubuntu' }}> Sign Up </a></p></Link>
                                                    </div>
                                                    <ToastContainer />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}

export default Signin