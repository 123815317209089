import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./HomeVersion2";
import Signup from "./Signup";
import Signin from "./Signin";
import ForgotPassword from "./ForgotPassword";
import Verify from "./Verify";
import ResetPassword from "./ResetPassword";
import PasswordResetSuccess from "./PasswordResetSuccess";
import VerifyReset from "./VerifyReset";
import DashboardHome from "./Dashboard/DashboardHomeVersion2";
import CreateMarket from "./Dashboard/CreateMarket";
import EditProfile from "./Dashboard/EditProfile";
import ProfilePage from "./Dashboard/ProfilePage";
import ModeContext from "./Context/ModeContext";
import { useState } from "react";
import "./App.css";
import { ThemeProvider } from "./Context/ThemeContext";
import RightSidePanel from "./Components/RightSidePanel";
import OtherProfilePage from "./Dashboard/OtherProfile";
import NotFound from "./Components/NotFound";
import Private from "./Components/Private";
import Market from "./Dashboard/MarketVersion2";
import MarketGridView from "./Dashboard/MarketGridView";
import MarketDetail from "./Dashboard/MarketDetailVersion2";
import Notifications from "./Dashboard/Notifications";
import Leagues from "./Dashboard/Leagues";
import Group from "./Dashboard/Group";
import Sitemap from "./Dashboard/Sitemap";
import About from "./Dashboard/About";
import Referrals from "./Dashboard/Referrals";
import Newsletter from "./Dashboard/Newsletter";
import LeaderBoard from "./Dashboard/LeaderBoard";
import notificationContext from "./Context/NotificationContext";
import { LoginContext } from "./Context/LoginContext";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./Dashboard/PrivacyPolicy";
import Faq from "./Dashboard/Faq";
import Contactus from "./Dashboard/Contactus";
import Metamask from "./Dashboard/Metamask";
import AboutUs from "./AboutUs";
import TermsConditions from "./TermsConditions";
import HowToCreateWallet from "./Dashboard/howToCreateWallet";
import HowToCreateMarket from "./Dashboard/howToCreateMarket";
import HowToCompleteKYC from "./Dashboard/howToCompleteKYC";
import HowToPlaceBet from "./Dashboard/howToPlaceBet";
import Test from "./Dashboard/Test";
import Layout from "./Components/Layout";

const AddSEO = ({ title, description, keywords, Component }) => {
  return (
    <>
      <Helmet>
        <title>{title ? `${title} - Smarter Bet` : "Smarter Bet"}</title>
        <meta
          name="description"
          content={description || "Default Description"}
        />
        <meta
          name="keywords"
          content={keywords?.length > 0 ? keywords.join(", ") : ""}
        />
      </Helmet>
      {Component}
    </>
  );
};

function App() {
  const theme = localStorage.getItem("theme");
  const [localTheme, setLocalTheme] = useState(theme);
  const [showNotification, setShowNotification] = useState(2);
  const [status, setStatus] = useState(false);

  return (
    <div>
      <notificationContext.Provider
        value={{ showNotification: showNotification }}
      >
        <ThemeProvider>
          {/* <ModeContext.Provider value={{ localTheme, setLocalTheme }}> */}
          {/* <LoginContext.Provider value={{ status, setStatus }} > */}
          <Routes>
            <Route
              path="/"
              element={<Home />}
            // <AddSEO
            //   title={'Home'}
            //   description={"Welcome to home page!!"}
            //   Component={<Home />}
            // />
            />
            <Route
              path="/signup"
              element={<Signup />}
            // <AddSEO
            //   title={'Sign Up'}
            //   description={"Sign Up!!"}
            //   Component={<Signup />}
            // />
            />
            <Route
              path="/signin"
              element={<Signin />}
            // <AddSEO
            //   title={'Signin'}
            //   description={"Sign Up!!"}
            //   Component={<Signin />}
            // />
            />
            <Route
              path="/forgot-password"
              element={<ForgotPassword />}
            // <AddSEO
            //   title={'Forgot Password'}
            //   description={"Forgot Password!!"}
            //   Component={<ForgotPassword />}
            // />
            />
            <Route
              path="/verify-account"
              exact
              element={<Verify />}
            // <AddSEO
            //   title={'Verify Account'}
            //   description={"Verify Account!!"}
            //   Component={<Verify />}
            // />
            />
            <Route
              path="/reset-password"
              exact
              element={<ResetPassword />}
            // <AddSEO
            //   title={'Reset Password'}
            //   description={"Reset Password!!"}
            //   Component={<ResetPassword />}
            // />
            />
            <Route
              path="/verify-reset"
              element={<VerifyReset />}
            // <AddSEO
            //   title={'Verify Reset'}
            //   description={"Verify Password!!"}
            //   Component={<VerifyReset />}
            // />
            />
            <Route
              path="/password-reset-successfully"
              element={<PasswordResetSuccess />}
            // <AddSEO
            //   title={'Password Reset Successfully'}
            //   description={"Password Reset!!"}
            //   Component={<PasswordResetSuccess />}
            // />
            />
            <Route
              path="/dashboard-edit-profile/:key"
              element={<EditProfile />}
            />
            <Route
              path="/how-to-create-wallet"
              element={<HowToCreateWallet />}
            />
            <Route
              path="/how-to-create-market"
              element={<HowToCreateMarket />}
            />
            <Route path="/how-to-complete-kyc" element={<HowToCompleteKYC />} />
            <Route path="/how-to-place-bet" element={<HowToPlaceBet />} />

            <Route
              path="/dashboard-market-grid"
              element={<MarketGridView />}
            // <AddSEO
            //   title={'Market'}
            //   description={"Market!!"}
            //   Component={<Private Component={MarketGridView} />}
            // />
            />

            <Route
              path="/dashboard-leagues"
              element={<Leagues />}
            // <AddSEO
            //   title={'Leagues'}
            //   description={"Leagues!!"}
            //   Component={<Private Component={Leagues} />}
            // />
            />

            <Route
              path="/dashboard-about"
              element={<About />}
            // <AddSEO
            //   title={'About'}
            //   description={"About!!"}
            //   Component={<Private Component={About} />}
            // />
            />

            <Route
              path="/dashboard-leaderboard"
              element={<LeaderBoard />}
            // <AddSEO
            //   title={'Leader Board'}
            //   description={"Leader Board!!"}
            //   Component={<Private Component={LeaderBoard} />}
            // />
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            // <AddSEO
            //   title={'Privacy Policy'}
            //   description={"Privacy Policy!!"}
            //   Component={< PrivacyPolicy />}
            // />
            />

            <Route
              path="/about-us"
              element={<AboutUs />}
            // <AddSEO
            //   title={'About Us'}
            //   description={"About Us!!"}
            //   Component={<AboutUs />}
            // />}
            />
            <Route
              path="/contactus"
              element={<Contactus />}
            />
            <Route path="/faqs" element={<Faq />} />
            <Route
              path="/terms-conditions"
              element={<TermsConditions />}
            // <AddSEO
            //   title={'Terms and Conditions'}
            //   description={"Terms and Conditions!!"}
            //   Component={<TermsConditions />}
            // />}
            />
            <Route path="/metamask" element={<Metamask />} />
            <Route path="/version" element={"V1.0.1"} />
            <Route
              path="*"
              element={<NotFound />}
            // <AddSEO
            //   title={'404 Page Not Found'}
            //   description={"404!!"}
            //   Component={<NotFound />}
            // />
            />

            <Route element={<Layout />}>
              {/* <Route path="/home" element={<Private Component={Home} />} /> */}
              <Route path="/test" element={<Private Component={Test} />} />
              <Route
                path="/dashboard-home"
                element={<Private Component={DashboardHome} />}
              />
              <Route
                path="/dashboard-market"
                element={<Private Component={Market} />}
              />
              <Route
                path="/dashboard-market-detail/:_id"
                element={<Private Component={MarketDetail} />}
              />
              <Route
                path="/dashboard-notifications"
                element={<Private Component={Notifications} />}
              />
              <Route
                path="/contact-us"
                element={<Private Component={Contactus} />}
              />
              <Route
                path="/dashboard-group"
                element={<Private Component={Group} />}
              />
              <Route
                path="/dashboard-sitemap"
                element={<Private Component={Sitemap} />}
              />
              <Route
                path="/dashboard-newletter"
                element={<Private Component={Newsletter} />}
              />
              <Route
                path="/dashboard-referal"
                element={<Private Component={Referrals} />}
              />
              <Route
                path="/dashboard-create-market"
                element={<Private Component={CreateMarket} />}
              />
              <Route
                path="/dashboard-profile"
                element={<Private Component={ProfilePage} />}
              />
              <Route path="/faq" element={<Private Component={Faq} />} />
              <Route
                path="/dashboard-edit-profile"
                element={<Private Component={EditProfile} />}
              />

              <Route
                path="/dashboard-other-profile/:_id"
                element={<Private Component={OtherProfilePage} />}
              />
            </Route>
          </Routes>
          {/* </LoginContext.Provider> */}
          {/* </ModeContext.Provider> */}
          {/* </BrowserRouter> */}
        </ThemeProvider>
      </notificationContext.Provider>
    </div>
  );
}

export default App;
