import React, { useState } from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../Context/ThemeContext'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useContext } from 'react';
import "./styles/Referrals.css"

const Referrals = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const [value, setValue] = React.useState("https://www.smarter-bet.com");
    const [copied, setCopied] = React.useState(false);

    const onChange = React.useCallback(({ target: { value } }) => {
        setValue(value);
        setCopied(true);
    }, [])
    const onClick = React.useCallback(({ target: { innerText } }) => {
    }, [])
    const onCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <>
            <div className={` ${themeClass} `}>
                <div className="row d-flex">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mt-5 mb-4">
                                        <nav className="navbar navbar-expand-md mt-3 mb-5 flex justify-content-start">
                                            <Link to='/dashboard-sitemap'>
                                                <button className='mr-4' style={{ border: 'none', width: 35, height: 35, borderRadius: 30 }}>
                                                    <img src='./images/icon_back.svg' alt="back" />
                                                </button>
                                            </Link>
                                            <h3>Refer a friend</h3>
                                        </nav>
                                        <div className="flex flex-column pb-5">
                                            <img className="referal-img" src="./images/img_refer.svg" alt="referal" />
                                            {/* <div className="my-4">Invite new users and get ₮250 if they sign up and place a trade!</div> */}

                                            <section className="section-copy flex shadow-none py-2 px-3 my-4">
                                                <input type="text" className="form-control"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)} readOnly />

                                                <CopyToClipboard onCopy={onCopy} text={value}>
                                                    <img className="ml-3" id="copy-img" src="./images/copy.svg" alt="copy" />
                                                </CopyToClipboard>
                                                {copied && <span className="ml-2">Copied!</span>}
                                            </section>
                                            <img className="qr-img" src="./images/QR_CODE.svg" alt="QR CODE" />
                                            <div className={`${themeClass === "dark"
                                                ? "text-white bg-dark"
                                                : "text-dark"
                                                }`} style={{
                                                    padding: "15px 10px",
                                                    backgroundColor: "#F8F8F8",
                                                    borderRadius: "10px",
                                                    marginTop: "35px",
                                                    textAlign: "justify",
                                                }}>You can also earn the referral bonus using the share link to any market or group!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Referrals


