import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import FallbackSpinner from "./spinner";
import authConfig from "../configs/auth"

const Private = ({ Component }) => {
    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);   
    const storedToken = localStorage.getItem(authConfig.storageTokenKeyName); 
    console.log("DEBUG USER",user);
    console.log("DEBUG token",loading);
    console.log("DEBUG storedToken",storedToken);
    
    useEffect(() => {
        if (!loading && (!user || !storedToken)) {
            navigate("/");
        }
    }, [user, storedToken, loading, navigate]);

    if (loading) return <FallbackSpinner />;

    return <>{user && storedToken ? <Component /> : null}</>;
};

export default Private;
