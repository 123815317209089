import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './styles/Signup.css'
import { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './styles/Custom.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import showPwdImg from './show-password.svg'
import hidePwdImg from './hide-password.svg'
import Navbar from './Components/Navbar'
import { axiosInstance } from './network/config/adapter'
import { ApiEndpoints } from './network/config/endpoints'
import { styled } from '@mui/material'
import { Container } from 'react-bootstrap'
import ResponsiveNavBar from './Components/ResponsiveNavBar'
const StyledNumberInput = styled('input')({
    outline: 0,
    borderWidth: '0 0 1px',
    paddingTop: 8,
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&': {
      MozAppearance: 'textfield', // For Firefox
    }
  });

const Signup = () => {

    const [isRevealPwd, setIsRevealPwd] = useState(true);
    const [isConfirmPwd, setIsConfirmPwd] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState('1');
    const [password, setPassword] = useState('');
    const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

    const navigate = useNavigate();
    localStorage.setItem('email', email);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError(true);
        } else {
            // Create a data object with the form values
            const data = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email: email,
                country_code: country,
                mobile: mobile,
                password: password,
                isPolicyAccepted: isPolicyAccepted
            };


            try {
                // Make a POST request using Axios
                const response = await axiosInstance.post(ApiEndpoints.auth.SIGN_UP, data);

                // Handle the response (e.g., show a success message)
                navigate('/verify-account');
            } catch (error) {
                // Handle the error (e.g., show an error message)

                const notify = () => {
                    toast.error(error.response.data.error, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
            }
        }

    };


    return (
        <Container>
             <ResponsiveNavBar />
            <section className="d-flex align-items-center justify-content-center main-container">
                <div className="py-2">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col">
                            <div className="card card-registration border-0">
                                <div className="row g-0 d-flex justify-content-center align-items-center">
                                    <div className=" col-xl-6 px-5">
                                        <img src="images/Image(1).svg"
                                            alt="signin" className="img-fluid center-block"
                                        />
                                    </div>
                                    <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-body mx-3 text-black">
                                                <h3 className="mb-2 label signup-label">Sign Up</h3>
                                                <p className="mb-3 text-dark">Please enter your details to sign up.</p>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="form-outline">
                                                            <label className="label-color" for="form3Example1m">First Name</label>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                                type="text" id="form3Example1m"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={firstName}
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="form-outline">
                                                            <label className="label-color" for="form3Example1n">Last Name</label>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                                type="text" id="form3Example1n"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={lastName}
                                                                onChange={(e) => setLastName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-3">
                                                    <label className="label-color" for="form3Example97">Email</label>
                                                    <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                        type="email" id="form3Example97"
                                                        className="form-control form-control-sm shadow-none"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="form-outline mb-3 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <label className="label-color d-block" for="">Country</label>
                                                        <PhoneInput
                                                            inputProps={{ readOnly: true }}
                                                            value={country}
                                                            onChange={setCountry}
                                                            placeholder='Select'
                                                            country={'us'}
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: '15px' }}>
                                                        <label className="label-color" for="form3Example97">Mobile Number</label>
                                                        <StyledNumberInput style={{ outline: 0, borderWidth: '0 0 1px', paddingTop: 8 }}
                                                            type="number" id="form3Example97"
                                                            className="form-control form-control-sm shadow-none"
                                                            value={mobile}
                                                            onChange={(e) => setMobile(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-3">
                                                    <label className="label-color" for="form3Example97">Password</label>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <input style={{ outline: 0, borderWidth: '0 0 1px', paddingRight: '30px' }}
                                                            type={isRevealPwd ? "password" : "text"}
                                                            id="form3Example97"
                                                            className="form-control form-control-sm shadow-none"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />
                                                        <img className='pwd-container-img'
                                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                            alt=''
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-3">
                                                    <label className="label-color" for="form3Example97">Confirm Password</label>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <input style={{ outline: 0, borderWidth: '0 0 1px', paddingRight: '30px' }}
                                                            type={isConfirmPwd ? "password" : "text"}
                                                            id="form3Example97"
                                                            className="form-control form-control-sm shadow-none"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            required
                                                        />

                                                        <img className='pwd-container-img'
                                                            title={isConfirmPwd ? "Hide password" : "Show password"}
                                                            src={isConfirmPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsConfirmPwd(prevState => !prevState)}
                                                            alt=''
                                                        />
                                                    </div>
                                                    {error && password !== confirmPassword ?
                                                        <label className='show-error-label'>Password and Confirm Password must be same</label> : ""}
                                                </div>


                                                <div className="form-outline mb-3 d-flex flex-row justify-content-start align-items-center bd-highlight">
                                                    <input className="form-check-input mx-3" type="checkbox" value="" id="flexCheckDefault"
                                                        checked={isPolicyAccepted}
                                                        onChange={(e) => setIsPolicyAccepted(e.target.checked)}
                                                        required
                                                    />
                                                    <p className='agree-text'>I agree to the 
                                                     <Link to='/privacy-policy'><span className='text-dark'> Smarterbet Privacy Policy. </span></Link></p>
                                                </div>

                                                <div className='d-flex flex-column'>
                                                    <button type="submit"
                                                        className="col-xl-12 btn btn-lg"
                                                        style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}
                                                    // onClick={handleShowData}
                                                    >Create an Account</button>

                                                    {/* <h5 className='py-2' style={{ fontSize: '16px', textAlign: 'center', margin: '8px' }}>Or</h5> */}

                                                    {/* <button type="submit" className="col-xl-12 btn btn-lg" style={{ border: '1px solid black', borderRadius: 30 }}> <img src='./images/google.svg' style={{ position: 'relative', left: '-20px' }} alt='' />Sign Up with Google</button> */}

                                                </div>
                                                <div className="d-flex justify-content-center align-items-center mt-3 ">
                                                    <Link to='/signin'><p className='already'>Already have an account?<a href='#' style={{ color: '#069514', fontWeight: 700, textDecoration: 'none', fontFamily: 'Ubuntu' }}> Login </a></p></Link>
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </Container>
    )
}

export default Signup