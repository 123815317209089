import React, { useState, useEffect } from "react";
import { FaBars, FaSignOutAlt, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { BiGrid, BiStore, BiCog, BiHelpCircle } from "react-icons/bi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./styles/LeftSidePanelVersion2.css";
import { confirmAlert } from "react-confirm-alert";
import { useAuth } from "../hooks/useAuth";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // Sidebar toggle state
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Settings collapse state
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem("theme") === "dark"; // Load theme from localStorage
  });

  const { logout } = useAuth();
  console.log("isDarkMode", isDarkMode);

  // Apply dark mode class on mount
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  // Logout confirmation
  const handleLogout = () => {
    confirmAlert({
      title: "Confirm to sign out",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => logout(),
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "",
      afterClose: () => (document.body.style.overflow = "auto"),
    });

    setTimeout(() => {
      document.querySelector(".react-confirm-alert").style.zIndex = "1100";
      document.querySelector(".react-confirm-alert-overlay").style.zIndex =
        "1099";
    }, 0);
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light"); // Save preference
      return newMode;
    });
  };

  return (
    <>
      {/* Sidebar Toggle Button (Mobile) */}
      {!isOpen && (
        <button className="sidebar-toggle" onClick={() => setIsOpen(true)}>
          <FaBars />
        </button>
      )}

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        {/* Close Button (Mobile) */}
        {isOpen && (
          <button className="close-btn" onClick={() => setIsOpen(false)}>
            <FaTimes />
          </button>
        )}

        {/* Logo */}
        <div className="logo">
          <img
            src="/images/smarter-bet-logo.png"
            alt="SmarterBet Logo"
            className="logo-img"
          />
        </div>

        {/* Navigation Links */}
        <ul className="nav flex-column">
          <li style={{ marginTop: 0 }} className="dashboard-item">
            <style>
              {`
        @media (max-width: 768px) {
            .dashboard-item {
                margin-top: 20px !important;
            }
        }
        `}
            </style>
            <NavLink to="/dashboard-home" className="nav-link">
              <BiGrid className="nav-icon" /> Dashboard
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard-market" className="nav-link">
              <BiStore className="nav-icon" /> Markets
            </NavLink>
          </li>

          {/* Collapsible Settings Menu */}
          <li>
            <div
              className="nav-link d-flex justify-content-between align-items-center"
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <BiCog className="nav-icon" /> Settings
              </div>
              {isSettingsOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </div>
          </li>

          {/* Submenu for Settings */}
          {isSettingsOpen && (
            <ul className="nav flex-column submenu">
              <li>
                <NavLink
                  to="/dashboard-group"
                  className="nav-link submenu-link"
                >
                  Group
                </NavLink>
              </li>
              {/* <li>
                <button
                  className="nav-link submenu-link btn btn-link text-start"
                  onClick={toggleDarkMode}
                >
                  {isDarkMode ? "Light Mode" : "Dark Mode"}
                </button>
              </li> */}
            </ul>
          )}

          <li>
            <NavLink to="/dashboard-sitemap" className="nav-link">
              <BiHelpCircle className="nav-icon" /> Help
            </NavLink>
          </li>
        </ul>

        {/* Logout Button */}
        <div className="logout">
          <button className="btn-logout" onClick={handleLogout}>
            <FaSignOutAlt className="nav-icon" /> Logout
          </button>
        </div>
      </div>

      {/* Background Overlay (Mobile) */}
      {/* Background Overlay (Mobile) */}
      {isOpen && (
        <div
          className="overlay"
          onClick={() => setIsOpen(false)}
          style={{ zIndex: 999 }} // Keep overlay below modal
        ></div>
      )}
    </>
  );
};

export default Sidebar;
