// export const API_BASE_URL = "http://13.40.94.92:3000"
export const API_BASE_URL = "https://api.smarter-bet.com"
// export const API_BASE_URL = "http://localhost:3000"

export const ApiEndpoints = {
  auth: {
    LOGIN: `${API_BASE_URL}/api/signin`,
    PROFILE_DATA: `${API_BASE_URL}/api/profile-details`,
    FORGOT_PASSWORD: `${API_BASE_URL}/api/forgot-password`,
    SIGN_IN: `${API_BASE_URL}/api/signin`,
    RESET_PASSWORD: `${API_BASE_URL}/api/reset-password`,
    SIGN_UP: `${API_BASE_URL}/api/signup`,
    VERIFY_ACCOUNT: `${API_BASE_URL}/api/verify-account`,
    PROFILE: `${API_BASE_URL}/api/profile`,
    DELETE_USER: `${API_BASE_URL}/api/delete-user`,
    CREATE_CLIENT: `${API_BASE_URL}/api/create-client`,
    CREATE_DOCUMENT: `${API_BASE_URL}/api/Create-document`,
    UPLOAD_IMAGE: `${API_BASE_URL}/api/upload-image`,
    CHECK_OUTCOME: `${API_BASE_URL}/api/get-check-outcome`,
    userDetails: (id) => `${API_BASE_URL}/api/user-details/${id}`,
    Email: `${API_BASE_URL}/api/email`,
    Google_Login: `${API_BASE_URL}/api/google-login`,
    DeleteUser: `${API_BASE_URL}/api/delete-user`,
    First_Market: `${API_BASE_URL}/api/first-market`,
    First_Bet: `${API_BASE_URL}/api/first-bet`

  },
  users: {
    ME: `${API_BASE_URL}/api/profile-details`,
    contact: `${API_BASE_URL}/api/get-in-touch`
  },
  group: {
    GROUP_DETAIL: `${API_BASE_URL}/api/all-groups`,
    CREATE_GROUP: `${API_BASE_URL}/api/create-group`,
    GET_GROUP: `${API_BASE_URL}/api/groups`,
  },
  wallet: {
    WalletCONNECT: `${API_BASE_URL}/api/udatewallet`,
  },
  betting: {
    BET: `${API_BASE_URL}/api/betting`,
    Answers: `${API_BASE_URL}/api/bet/result`,
    Portfolio: (id) => `${API_BASE_URL}/api/portfolio/${id}`,
    userBet: (id) => `${API_BASE_URL}/api/user/betting/${id}`,
    All_Bets: `${API_BASE_URL}/api/all-bets`,
    TradeList: (id) => `${API_BASE_URL}/api/user/trade/${id}`,
    userTrade: (id) => `${API_BASE_URL}/api/trade/${id}`,
    winningAmount: `${API_BASE_URL}/api/user/market/calculate-winnings`
  },
  market: {
    GET_ALL_MARKET: `${API_BASE_URL}/api/get-all-market`,
    getMarketDetails: (id) => `${API_BASE_URL}/api/markets/${id}`,
    CREATE_MARKET: `${API_BASE_URL}/api/markets`,
    fetchMarketByUser: `${API_BASE_URL}/api/user/market`,
    question: `${API_BASE_URL}/api/question`,
    getMarketById: (id) => `${API_BASE_URL}/api/user/market/${id}`,
    marketDetailChart: (id) => `${API_BASE_URL}/api/user/market-details-chart/${id}`,
    profileChart: `${API_BASE_URL}/api/user/market-chart`,
    marketLikeUnLike: (id) => `${API_BASE_URL}/api/user/market/like/${id}`
  },
  transaction: {
    TRANSACTION: `${API_BASE_URL}/api/transection`,
  },
  follow: {
    followed: `${API_BASE_URL}/api/follow`,
    getFollowers: (id) => `${API_BASE_URL}/api/followers/${id}`,
    getFollowing: (id) => `${API_BASE_URL}/api/following/${id}`,
  },
  comment: {
    getQuestion: `${API_BASE_URL}/api/question`,
    questionAnswer: `${API_BASE_URL}/api/answer`,
    getQuestionById: (id) => `${API_BASE_URL}/api/get-question/${id}`,
    getQuestionByUserId: (id) => `${API_BASE_URL}/api/user/get-question/${id}`,
  },
  notification: {
    notificationStatus: `${API_BASE_URL}/api/users/notification-setting`,
    updateSetting: `${API_BASE_URL}/api/users/notification`,
    getAllNotifications: `${API_BASE_URL}/api/users/get-notification`,
  },
  withdraw: {
    withdrawRequest: `${API_BASE_URL}/api/withdraw-request`,
    getRequest: `${API_BASE_URL}/api/get-request`
  }

}
