import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { arbitrum, mainnet } from "wagmi/chains";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

// 1. Project ID for Web3Modal
const projectId = "afeed2ea7e8d4924bf554d95e2d0ef72";

// 2. Wagmi Configuration
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Initialize Web3Modal
createWeb3Modal({ wagmiConfig, projectId, chains });

export default function Metamask() {
  const { open } = useWeb3Modal();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateWalletAddress = (address) => {
    axiosInstance
      .post(ApiEndpoints.wallet.WalletCONNECT, { wallet: address })
      .then((response) => {
        toast.success("Wallet connected successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error connecting wallet", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const connect = async () => {
    try {
      if (!window.ethereum) {
        toast.error("MetaMask is not installed!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await window.ethereum.request({ method: "eth_accounts" });

      if (accounts.length > 0) {
        updateWalletAddress(accounts[0]);
      }
    } catch (error) {
      toast.error("Failed to connect wallet!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <WagmiConfig config={wagmiConfig}>
      <button
        onClick={open} // Opens Web3Modal when clicked
        className="btn btn-dark w-100"
        style={{
          height: "43px",
          borderRadius: "100px",
          width: windowWidth <= 600 ? "200px" : "100%",
        }}
      >
        Connect Wallet
      </button>
      <ToastContainer />
    </WagmiConfig>
  );
}
