import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import { FaSearch } from "react-icons/fa";
import "./styles/DashboardHomeVersion2.css";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import FallbackSpinner from "../Components/spinner";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import moment from "moment";
import BettingModal from "../Components/PollModal";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Metamask from "../Components/Metamask";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DashBoardHomeVersion2 = () => {
  const { themeClass } = useContext(ThemeContext);
  const { availableBalance, holdBalance, userid } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [allMarketData, setAllMarketData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketData, setMarketData] = useState({});

  const navigate = useNavigate();
  const handleOpenModal = (data) => {
    setMarketData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // useEffect(() => {
  //     setSelectColor(themeClass === "dark" ? "#1e1e1e" : "#fff");
  // }, [themeClass]);

  const fetchMarketData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.market.GET_ALL_MARKET,
        { params: { search: searchValue } }
      );
      setAllMarketData(
        response.data.markets.filter(
          (market) =>
            market.bet_status !== "close" &&
            market.bet_status !== "distribution started" &&
            market.bet_status !== "distributed" &&
            market.bet_status !== "resolved"
        )
      );
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMarketData();
  }, [fetchMarketData, searchValue]);

  const likeUnlike = async (id) => {
    axiosInstance
      .post(ApiEndpoints.market.marketLikeUnLike(id))
      .then((response) => {
        fetchMarketData();
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "An error occurred", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const PollList = ({ themeClass, allMarketData }) => {
    return (
      <div style={{ width: "100%", margin: "0 auto" }}>
        <div className="column g-4" style={{ marginTop: "60px" }}>
          {allMarketData.length > 0 ? (
            allMarketData.map((poll, index) => (
              <div key={index}>
                <PollCard themeClass={themeClass} poll={poll} />
              </div>
            ))
          ) : (
            <p style={{ textAlign: "center", fontSize: "16px" }}>
              No polls available
            </p>
          )}
        </div>
      </div>
    );
  };

  const PollCard = ({ themeClass, poll }) => {
    const isDarkMode = themeClass === "dark";
    console.log("DEBUGPOLL", poll);
    const marketImage = poll?.marketImage
      ? `${poll?.marketImage}`
      : "images/cardimage.png";
    const marketCreateUserImage = poll?.createdBy?.profile_picture
      ? `${poll?.createdBy?.profile_picture}`
      : "images/phase2avatar.png";
    const userName =
      poll?.createdBy?.firstName + " " + poll?.createdBy?.lastName;
    const isLiked = !!poll?.likes?.some(
      (like) => like?.userId === userid && like?.status === "like"
    );
    return (
      <div className="container-fluid p-0" style={{ maxWidth: "100%" }}>
        <div
          className={`row mx-auto shadow-sm ${
            isDarkMode ? "bg-dark text-white" : "bg-white"
          }`}
          style={{
            borderRadius: "12px",
            margin: "20px auto",
            width: "100%",
            boxShadow: isDarkMode
              ? "0 1px 3px rgba(255,255,255,0.1)"
              : "0 1px 3px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "stretch",
          }}
        >
          {/* Image Section */}
          <div className="col-lg-5 col-md-6 col-12 p-0">
            <div style={{ height: "100%", minHeight: "300px" }}>
              <img
                src={marketImage}
                alt="Manchester United Team"
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
                onClick={() =>
                  navigate(`/dashboard-market-detail/${poll?._id}`)
                }
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-7 col-md-6 col-12 p-4">
            {/* User Info and Post Details */}
            <div className="d-flex align-items-center mb-3">
              <img
                src={marketCreateUserImage}
                alt="User"
                className="rounded-circle me-2"
                style={{ width: "32px", height: "32px" }}
                onClick={() =>
                  navigate(`/dashboard-other-profile/${poll?.createdBy?._id}`)
                }
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.2)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              />
              <div>
                <strong
                  style={{ fontSize: "14px" }}
                  onMouseEnter={(e) => (e.currentTarget.style.color = "blue")}
                  onMouseLeave={(e) => (e.currentTarget.style.color = "black")}
                  onClick={() =>
                    navigate(`/dashboard-other-profile/${poll?.createdBy?._id}`)
                  }
                >
                  {userName}
                </strong>
              </div>
            </div>

            {/* Poll Question */}
            <h2
              className="de-flex mb-3"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                padding: "4px 0",
              }}
            >
              {poll?.question || "Default Title"}
            </h2>

            <p
              className="d-flex align-items-center mb-3"
              style={{ fontSize: "12px" }}
            >
              Posted at{" "}
              {moment(poll?.createdAt).format("MMMM Do YYYY, h:mm:ss A")}
            </p>

            {/* Voting Buttons */}
            <div className="d-flex flex-wrap gap-3 mb-5">
              <button
                className="btn"
                style={{
                  borderRadius: "50px",
                  padding: "8px 24px",
                  fontSize: "13px",
                  border: "1px solid #4CAF50",
                  backgroundColor: isDarkMode ? "#2E7D32" : "#E8F5E9",
                  color: isDarkMode ? "#fff" : "#2E7D32",
                  minWidth: "120px",
                  fontWeight: "500",
                }}
                onClick={() =>
                  handleOpenModal({
                    pollId: poll?._id,
                    question: poll?.question,
                    yesAnswerProbability: poll?.yesAnswerProbability,
                    noAnswerProbability: poll?.noAnswerProbability,
                  })
                }
              >
                YES {poll?.yesAnswerProbability}
              </button>
              <button
                className="btn"
                style={{
                  borderRadius: "50px",
                  padding: "8px 24px",
                  fontSize: "13px",
                  border: "1px solid #F44336",
                  backgroundColor: isDarkMode ? "#C62828" : "#FFEBEE",
                  color: isDarkMode ? "#fff" : "#C62828",
                  minWidth: "120px",
                  fontWeight: "500",
                }}
                onClick={() =>
                  handleOpenModal({
                    pollId: poll?._id,
                    question: poll?.question,
                    yesAnswerProbability: poll?.yesAnswerProbability,
                    noAnswerProbability: poll?.noAnswerProbability,
                  })
                }
              >
                NO {poll?.noAnswerProbability}
              </button>
            </div>

            {/* Engagement Icons */}
            <div className="d-flex align-items-center flex-wrap custom-gap w-100">
              <div
                className="d-flex align-items-center gap-2"
                onClick={() => likeUnlike(poll?._id)}
                style={{ cursor: "pointer" }}
              >
                {isLiked ? (
                  <AiFillLike className="icon-style" style={{ color: "red" }} />
                ) : (
                  <AiOutlineLike className="icon-style" />
                )}
                <span className="count-style">{poll?.likes?.length || 0}</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                <FaRegComment className="icon-style" />
                <span className="count-style">{poll?.commentCount ?? "0"}</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                {/* <BiRepost className="icon-style" />
                <span className="count-style">53</span> */}
              </div>

              <div className="d-flex align-items-center gap-2">
                {/* <FaRegHeart className="icon-style" />
                <span className="count-style">23</span> */}
              </div>
            </div>
          </div>
        </div>
        {/* Poll Modal */}
      </div>
    );
  };

  const [copied, setCopied] = useState(false);
  const address = "0x9DC0c073f1De57187822D8590B2E2c9bcf1F1Da0";
  const handleCopy = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setCopied(true);
        toast.success("Copied Deposit Address", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2s
      })
      .catch(() => {
        toast.error("Failed to copy!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      {loading ? (
        <FallbackSpinner />
      ) : (
        <div className={` ${themeClass} `}>
          <div className="row d-flex">
            <div className="col-12">
              <div className="mt-4">
                <div
                  className={`card p-4 border-0 ${themeClass}`}
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {/* Search Bar and Filters */}
                  <div className="row mb-3 align-items-center">
                    <div className="col-lg-6 col-md-12 mb-2 mb-lg-0">
                      <div
                        className="input-group"
                        style={{
                          borderRadius: "100px",
                          overflow: "hidden",
                          border: "1px solid #ced4da",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <span
                          className={`input-group-text border-0 ${
                            themeClass === "dark"
                              ? "bg-dark text-white"
                              : "bg-white text-dark"
                          }`}
                        >
                          <FaSearch
                            style={{
                              color: themeClass === "dark" ? "#fff" : "#bbb",
                            }}
                          />
                        </span>

                        <input
                          type="text"
                          className={`form-control border-0 ${
                            themeClass === "dark" ? "bg-dark text-white" : ""
                          }`}
                          placeholder="Search..."
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          style={{
                            height: "50px",
                            borderRadius: "100px",
                            outline: "none",
                            boxShadow: "none",
                            border:
                              themeClass === "dark"
                                ? "1px solid #555"
                                : "1px solid #ced4da",
                          }}
                        />
                      </div>
                    </div>

                    {/* Buttons Section */}
                    <div className="col-lg-3 col-md-6 col-12 text-center mb-2 mb-md-0">
                      <Metamask />
                      {/* <button
                        className="btn btn-dark w-100"
                        style={{ height: "43px", borderRadius: "100px" }}
                      >
                        Connect Wallet
                      </button> */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 text-center">
                      <button
                        className="btn btn-success w-100"
                        style={{ height: "43px", borderRadius: "100px" }}
                        onClick={() => navigate("/dashboard-create-market")}
                      >
                        Create a Market
                      </button>
                    </div>
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row g-3 justify-content-center">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                        <div
                          className={`card p-3 shadow-sm border-0 ${
                            themeClass === "dark"
                              ? "bg-dark text-white"
                              : "bg-white"
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <div className={`icon rounded-circle p-3 bg-light`}>
                              <img
                                src="images/WalletVersion2.png"
                                alt="Wallet"
                                width="24"
                              />
                            </div>
                            <div className="ms-3">
                              <h6
                                className={`mb-1 ${
                                  themeClass === "dark"
                                    ? "text-light"
                                    : "text-muted"
                                }`}
                              >
                                Wallet Amount
                              </h6>
                              <h5 className="mb-0">${availableBalance}</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                        <div
                          className={`card p-3 shadow-sm border-0 ${
                            themeClass === "dark"
                              ? "bg-dark text-white"
                              : "bg-white"
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <div className={`icon rounded-circle p-3 bg-light`}>
                              <img
                                src="images/WalletVersion2.png"
                                alt="Wallet"
                                width="24"
                              />
                            </div>
                            <div className="ms-3">
                              <h6
                                className={`mb-1 ${
                                  themeClass === "dark"
                                    ? "text-light"
                                    : "text-muted"
                                }`}
                              >
                                Hold Amount
                              </h6>
                              <h5 className="mb-0">${holdBalance}</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                        <div
                          className={`card p-3 shadow-sm border-0 ${
                            themeClass === "dark"
                              ? "bg-dark text-white"
                              : "bg-white"
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <CopyToClipboard onCopy={handleCopy} text={address}>
                              <div
                              className={`icon rounded-circle p-3 bg-light`}
                              >
                                <img
                                  // id="copy-img"
                                  src="images/DepositVersion2.png"
                                  alt="copy"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </div>
                            </CopyToClipboard>

                            {/* Deposit Address Section */}
                            <div className="ms-3 w-100">
                              <h6
                                className={`mb-1 ${
                                  themeClass === "dark"
                                    ? "text-light"
                                    : "text-muted"
                                }`}
                              >
                                Deposit Address
                              </h6>
                              <div className="deposit-address-container">
                                <h5 className="mb-0 deposit-address">
                                  {address}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <PollList
                        themeClass={themeClass}
                        allMarketData={allMarketData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      )}

      {isModalOpen && (
        <BettingModal
          market={marketData}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          fetchMarketData={fetchMarketData}
        />
      )}
    </>
  );
};

export default DashBoardHomeVersion2;
